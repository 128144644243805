import React from "react";
import cx from "classnames";
import { Formik } from "formik";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";

import styles from "./FilterBar.module.css";

const initialValues = {
  name: "",
  affiliateLeaderId: "",
  tqmTeamName: "",
  orderBy: "",
  order: "desc",
  page: 1,
  perPage: 10,
};

function FilterBar({
  isOpen,
  options = { affiliateSearchOptions: [] },
  onChange = () => {},
}) {
  return (
    <Formik initialValues={initialValues}>
      {(props) => {
        const { resetForm } = props;
        return (
          <form className={cx(styles.filterWrapper, !isOpen ? "hidden" : null)}>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="name">ชื่อทีม</label>
              <Input
                name="name"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ name: value })}
              />
            </div>
            <div
              className={cx(
                styles.filterInputWrapper,
                styles.filterInputSecond
              )}
            >
              <label htmlFor="affiliateLeaderId">หัวหน้าทีม</label>
              <Selector
                width="100%"
                name="affiliateLeaderId"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ affiliateLeaderId: value })}
                options={options.affiliateSearchOptions}
                isSearchable
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="tqmTeamName">ทีมหลัก</label>
              <Input
                name="tqmTeamName"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ tqmTeamName: value })}
              />
            </div>
            <div
              className={cx(
                styles.filterInputWrapper,
                styles.filterInputSecond
              )}
            >
              <div
                className={cx(styles.filterInput, styles.reset)}
                onClick={() => {
                  resetForm();
                  onChange(initialValues);
                }}
              >
                รีเซ็ตค่าตัวเลือก
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FilterBar;
