import { createSlice } from "@reduxjs/toolkit";

const appInitialState = {
  initialized: false,
  isLogin: false,
  isMenuOpen: false,
};

const appReducers = {
  initializeApp(state) {
    state.initialized = true;
  },
  initializeLogin(state, action) {
    state.isLogin = action.payload;
  },
  toggleMenu(state) {
    state.isMenuOpen = !state.isMenuOpen;
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState: appInitialState,
  reducers: appReducers,
});

export const { initializeApp, initializeLogin, toggleMenu } = appSlice.actions;

export default appSlice.reducer;
