import { useMemo } from "react";
import { Link } from "react-router-dom";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import containerStyles from "components/Layout/Container/Container.module.css";
import Table from "components/Table";
import { dateFormatterFromPattern } from "components/Form/Element/DatePicker/DatePicker.utils";
import DisplayValue from "components/DisplayValue";

import styles from "./MarketingId.module.css";
import enhancer from "./MarketingId.compose";
import authorize, { UnauthorizedAction } from "utils/authorize";

function MarketingId(props) {
  const { data = { table: [] }, disabled } = props;
  const columns = useMemo(
    () => [
      {
        Header: "ระดับ",
        accessor: "positionLevel", // accessor is the "key" in the data
        styles: {
          header: {
            width: "90px",
          },
          cell: {
            textAlign: "center",
          },
        },
      },
      {
        Header: "ตำแหน่ง",
        accessor: "positionName",
        styles: {
          header: {
            width: "358px",
          },
        },
      },
      {
        Header: "ค่าแนะนำ",
        accessor: "commissionPercent",
        styles: {
          header: {
            width: "160px",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return `${cell?.value}%` || "ไม่มี";
        },
      },
      {
        Header: "ค่าบริหาร",
        accessor: "managementPercent",
        styles: {
          header: {
            width: "160px",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return `${cell?.value}%` || "ไม่มี";
        },
      },
      {
        Header: "ตำแหน่งว่างให้",
        accessor: "reserveForLevel",
        styles: {
          header: {
            width: "160px",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return cell?.value || "ไม่มี";
        },
      },
    ],
    []
  );
  return (
    <Container>
      <h4 className="topic">แผนการตลาด</h4>
      <div className={containerStyles.breadcrumbWithButton}>
        <Breadcrumb
          links={[
            { label: "จัดการแผนการตลาดทีมผู้แนะนำ", link: "/marketing_plan" },
            { label: data?.name || "แผนการตลาด", current: true },
          ]}
        />
        <Link to={`/marketing_plan/${data.id}/edit`}>
          <button className={styles.btn} disabled={disabled}>
            แก้ไข
          </button>
        </Link>
      </div>
      <div className={styles.detail}>
        <div className={styles.important}>
          <div className={styles.row}>
            <DisplayValue
              className={styles.col}
              label="ชื่อแผน"
              value={data.name}
            />
            <DisplayValue
              className={styles.col}
              label="จำนวนระดับ"
              value={data.numberOfLevel}
            />
          </div>
        </div>
      </div>
      {data?.mkCommissionStructures?.length > 0 ? (
        data.mkCommissionStructures.map((mkStructure) => (
          <>
            <div className={styles.detail}>
              <div className={styles.important}>
                {/* <div className={styles.row}>
                  <DisplayValue
                    className={styles.col}
                    label="ชื่อแผน"
                    value={data.name}
                  />
                  <DisplayValue
                    className={styles.col}
                    label="จำนวนระดับ"
                    value={data.numberOfLevel}
                  />
                </div> */}
                <div className={styles.row}>
                  <div className={styles.colFull}>
                    <b>โครงสร้างค่าแนะนำ</b>
                    <small>
                      {data?.commissionName || "โครงสร้างสำหรับใช้ในการคำนวณ"}
                    </small>
                  </div>
                </div>
              </div>
              <div className={styles.subDetail}>
                <div className={styles.col}>
                  <label>ค่าแนะนำ</label>
                  <small>
                    {data?.commissionPaid || "ระดับต่ำสุด คือ ระดับ 1"}
                  </small>
                </div>
                <div>
                  วันที่มีผลบังคับใช้&nbsp;
                  {dateFormatterFromPattern(mkStructure.beginDate)}
                </div>
              </div>
            </div>
            <Table
              data={mkStructure ? mkStructure.mkCommissionRates : []}
              columns={columns}
              className={styles.table}
              pagination={false}
            />
          </>
        ))
      ) : (
        <p className={styles.note}>ยังไม่มีโครงสร้างข้อมูล กรุณากรอก</p>
      )}
    </Container>
  );
}
export default authorize(enhancer(MarketingId), {
  roles: "mkPlan",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["crud"],
});
