import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AdminForm from "components/Form/AdminForm";

import enhancer from "./NewAdmin.compose";
import styles from "./NewAdmin.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

function NewAdmin({ onSubmit }) {
  return (
    <Container>
      <h4 className="topic">จัดการผู้ใช้แอดมิน</h4>
      <div className={containerStyles.breadcrumbWithButton}>
        <Breadcrumb
          links={[
            { label: "จัดการผู้ใช้แอดมิน", link: "/admin" },
            { label: "สร้างแอดมิน", current: true },
          ]}
        />
        <button className={styles.btn} form="newAdmin" type="submit">
          บันทึก
        </button>
      </div>
      <AdminForm id="newAdmin" onSubmit={onSubmit} />
    </Container>
  );
}

export default authorize(enhancer(NewAdmin), {
  roles: "admin",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["add"],
});
