import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import useToggle from "hooks/useToggle";
import {
  changePriorityStatus,
  getAnnouncementsOptions,
  getAnnouncements,
  setAnnouncement,
} from "store/announcementsSlice";

const enhancer = compose(
  withHooks(() => {
    const [isShowFilterBar, toggle] = useToggle();
    const [searchValue, setSearchValue] = useState({
      name: "",
      status: "",
      updateById: "",
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10,
    });
    const dispatch = useDispatch();
    const {
      announcements = [],
      paging = {},
      isLoading = false,
      options = [],
      pins = [],
    } = useSelector((state) => state.announcements);

    useEffect(() => {
      dispatch(getAnnouncementsOptions());
      dispatch(setAnnouncement({}));
    }, [dispatch]);

    useEffect(() => {
      dispatch(getAnnouncements(searchValue));
    }, [dispatch, searchValue]);

    const onChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    const changePriority = useCallback(
      (id, pin) => {
        dispatch(
          changePriorityStatus(id, pin, () => dispatch(getAnnouncements()))
        );
      },
      [dispatch]
    );
    return {
      data: announcements,
      isLoading,
      paging,
      options,
      isShowFilterBar,
      toggle,
      onChange,
      searchValue,
      changePriority,
      pins,
    };
  }),
  mapProps((props) => {
    return {
      data: props.data,
      isLoading: props.isLoading,
      paging: props.paging,
      options: props.options,
      isShowFilterBar: props.isShowFilterBar,
      toggle: props.toggle,
      onChange: props.onChange,
      searchValue: props.searchValue,
      changePriority: props.changePriority,
      pins: props.pins,
      disabled: props?.disabled || false,
    };
  })
);

export default enhancer;
