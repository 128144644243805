import { Link } from "react-router-dom";
import cx from "classnames";
import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";

import enhancer from "./CommissionReport.compose";
import styles from "./CommissionReport.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import FilterBar from "./FilterBar";
import SearchTable from "./SearchTable";
import authorize, { UnauthorizedAction } from "utils/authorize";

function CommissionReport({
  data,
  options,
  toggle,
  onChange,
  searchValue,
  paging,
  setArrayExcel,
  arrayExcel,
  getReport,
}) {
  return (
    <Container className={styles.container}>
      <section className={styles.header}>
        <h4 className="topic">รายการค่าตอบแทน</h4>
        <div
          className={cx(
            containerStyles.breadcrumbWithButton,
            styles.breadcrumb
          )}
        >
          <Breadcrumb
            links={[
              { label: "รายการค่าตอบแทน", link: "/commission" },
              {
                label: "ออกรายงาน",
                current: true,
              },
            ]}
          />
          <div className={styles.groupBtn}>
            <Link to={`/commission`}>
              <button
                type="button"
                className={`${styles.selectBtn} secondary`}
                onClick={toggle}
              >
                ยกเลิก
              </button>
            </Link>
            <button className={styles.btn} type="button" onClick={getReport}>
              ยืนยัน
            </button>
          </div>
        </div>
      </section>
      <FilterBar
        isOpen={true}
        options={options}
        onChange={onChange}
        searchValue={searchValue}
      />
      <SearchTable
        data={data}
        options={options}
        onChange={onChange}
        paging={paging}
        setArrayExcel={setArrayExcel}
        arrayExcel={arrayExcel}
      />
    </Container>
  );
}

export default authorize(enhancer(CommissionReport), {
  roles: "salesCommission",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["read"],
});
