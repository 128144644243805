import { useMemo } from "react";
import cx from "classnames";

import DisplayValue from "components/DisplayValue";
import Upline from "components/Upline";

import { dateFormatterFromPattern } from "utils/formatter";
import styles from "./Plan.module.css";

const PlanRenderer = ({
  data = {},
  nameThInfo = {},
  index,
  options = {
    marketPlanOptions: [],
    uplineOptions: [],
    affiliateStatusOptions: [],
  },
}) => {
  return (
    <li className={styles.plan}>
      <div className={styles.info}>
        <DisplayValue
          className={styles.name}
          label="ชื่อแผน"
          value={
            options?.marketPlanOptions.find(
              (marketPlan) => marketPlan.value === data.mkPlanId
            )?.label || "-"
          }
        />
        <DisplayValue
          className={styles.effectiveDate}
          label="วันที่มีผลบังคับ"
          value={dateFormatterFromPattern(data.beginDate) || "-"}
        />
        <DisplayValue
          className={styles.position}
          label="ตำแหน่ง"
          value={
            options?.marketPlanOptions
              .find(
                (marketPlanOption) => marketPlanOption.value === data.mkPlanId
              )
              ?.positionLevel?.options.find(
                (e) => e.value === data.positionLevel
              )?.label || "-"
          }
        />
        <DisplayValue
          className={styles.uplineName}
          label="อัปไลน์"
          value={
            options?.uplineOptions.find(
              (upline) => upline.value === data.uplineId
            )?.label || "-"
          }
        />
      </div>
      <Upline
        data={data?.uplineTreePlan || []}
        nameThInfo={nameThInfo}
        initialOpen={index === 0}
      />
    </li>
  );
};

function Plan({ data = [], nameThInfo = {}, options = {} }) {
  const plan = useMemo(() => {
    if (data.length > 0 && nameThInfo) {
      return data.map((p, index) => (
        <PlanRenderer
          key={index}
          data={p}
          nameThInfo={nameThInfo}
          index={index}
          options={options}
        />
      ));
    }
    return <PlanRenderer />;
  }, [data, options, nameThInfo]);
  return (
    <ul className={styles.container}>
      <p className={cx("topic", styles.topic)}>
        <b>แผนการตลาดทีมผู้แนะนำ</b>
      </p>
      {plan}
    </ul>
  );
}

export default Plan;
