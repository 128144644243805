import { Fragment } from "react";
import { Link } from "react-router-dom";
import Icon from "components/Icon";
import cx from "classnames";

import Modal from "components/Modal";
import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import DisplayValue from "components/DisplayValue";
import DEFAULT_AVATAR from "assets/images/avatar_agent.png";

import enhancer from "./AgentId.compose";
import {
  dateFormatterFromPattern,
  phoneNumberFormat,
  addressFormatter,
} from "utils/formatter";
import styles from "./AgentId.module.css";
import useToggle from "hooks/useToggle";
import authorize, { UnauthorizedAction } from "utils/authorize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ResendEmail = ({ sendEmail = () => {}, data = {} }) => {
  const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

  return (
    <>
      <button
        className={cx("secondary", styles.btn, styles.resendBtn)}
        onClick={handleOpenModal}
      >
        <div>
          <Icon name="mail" className={styles.icon} />
          ส่งอีเมลอีกครั้ง
        </div>
      </button>
      <Modal
        isOpen={showModal}
        onSubmit={() => {
          handleCloseModal();
          let values = {
            params:
              data.agentStatus === "ปฏิเสธถาวร (Blacklist)" ||
              data.agentStatus === "ปฏิเสธชั่วคราว (1 ปี)"
                ? "rejectd"
                : "updated",
          };
          sendEmail(values);
        }}
        onCancel={handleCloseModal}
        submitText="ส่งอีเมล"
        cancelText="ไม่ส่งอีเมล"
        contentLabel="SendEmail Modal"
      >
        {data.agentStatus === "ปฏิเสธถาวร (Blacklist)" ||
        data.agentStatus === "ปฏิเสธชั่วคราว (1 ปี)" ? (
          <p>
            ต้องการส่งอีเมลแจ้ง <b>ไม่อนุมัติการสมัคร</b>
            <br />
            ไปยังผู้สมัครด้วยหรือไม่ ?
          </p>
        ) : (
          <p>
            ต้องการส่งอีเมลแจ้ง <b>แก้ไขเอกสาร</b>
            <br />
            ไปยังผู้สมัครด้วยหรือไม่ ?
          </p>
        )}
      </Modal>
    </>
  );
};

const DisplayInterview = ({
  rowLeft = null,
  rowRight = null,
  styleColumnLeft = null,
  styleColumnRight = null,
}) => {
  return (
    <div className={styles.rowInterview}>
      <div
        className={styleColumnLeft ? styleColumnLeft : styles.columnInterview}
      >
        {rowLeft}
      </div>
      {rowRight && (
        <div
          className={
            styleColumnRight ? styleColumnRight : styles.columnInterview
          }
        >
          {rowRight}
        </div>
      )}
    </div>
  );
};

function AgentId({
  id = "",
  data = {},
  isLoading,
  sendEmail = () => {},
  getFileDocument = () => {},
  options = [],
  disabled = false,
}) {
  return (
    <Container className={styles.mainContainer}>
      <header className={styles.breadcrumb}>
        <h4 className="topic">จัดการผู้สมัคร</h4>
        <Breadcrumb
          links={[
            { link: "/agents", label: "จัดการผู้สมัคร" },
            {
              label: data.firstName
                ? `${data.firstName} ${data.lastName}`
                : "รายชื่อผู้สมัคร",
              current: true,
            },
          ]}
        />
      </header>
      <div className={styles.editbtn}>
        <ResendEmail
          sendEmail={(values) => {
            sendEmail(values);
          }}
          data={data}
        />
        <Link to={`/agents/${id}/history`}>
          <button className={cx("secondary", styles.historyBtn)} type="button">
            <div>
              <Icon name="history" className={styles.icon} />
              ประวัติการแก้ไข
            </div>
          </button>
        </Link>
        <Link to={`/agents/${id}/edit`}>
          <button type="button" disabled={disabled}>
            แก้ไข
          </button>
        </Link>
      </div>
      {isLoading ? null : (
        <section className={styles.container}>
          <div className={styles.display}>
            <img
              src={data.avatar || DEFAULT_AVATAR}
              alt="Agent avatar"
              className={styles.logo}
            />
            <div className={styles.newInterview}>
              <DisplayInterview
                rowLeft={<div>สถานะ : {data?.agentStatus || "-"}</div>}
                rowRight={
                  <>
                    ผู้อัปเดตล่าสุด : {data?.lastUpdatedBy || "-"}
                    <br />
                    วันที่อัปเดต :&nbsp;
                    {data?.updatedAt
                      ? dateFormatterFromPattern(data.updatedAt)
                      : "-"}
                  </>
                }
                styleColumnRight={styles.lastUpdatedBy}
              />
            </div>
            {data?.recruits?.affiliate ? (
              <div className={styles.newInterview}>
                <DisplayInterview
                  rowLeft={
                    <div>
                      ผลการสัมภาษณ์ : <b>ผู้แนะนำ</b>
                    </div>
                  }
                  rowRight={
                    <>
                      วันที่สมัคร :&nbsp;
                      {data?.recruits?.affiliate?.recruitedAt
                        ? dateFormatterFromPattern(
                            data?.recruits?.affiliate?.recruitedAt
                          )
                        : "-"}
                    </>
                  }
                  styleColumnRight={styles.lastUpdatedBy}
                />
                <DisplayInterview
                  rowLeft={
                    <>
                      {data?.recruits?.affiliate?.interviewStatus === true
                        ? "ผ่าน"
                        : data?.recruits?.affiliate?.interviewStatus === false
                        ? "ไม่ผ่าน"
                        : "ยังไม่ระบุ"}
                    </>
                  }
                  rowRight={
                    <div className={styles.registerNote}>
                      {data?.recruits?.affiliate?.note || "-"}
                    </div>
                  }
                  styleColumnRight={styles.columnRegisterNote}
                />
                {data?.recruits?.affiliate?.recruitStatus !== "failed" && (
                  <>
                    <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="ชื่อทีม"
                          value={data?.recruits?.affiliate?.teamName || "-"}
                        />
                      }
                    />
                    {/* <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="Staff Code"
                          value={data?.recruits?.affiliate?.staffCode || "-"}
                        />
                      }
                      rowRight={
                        <DisplayValue
                          label="ชื่อทีม"
                          value={data?.recruits?.affiliate?.teamName || "-"}
                        />
                      }
                    /> */}
                    <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="แผนการตลาด"
                          value={
                            (
                              options?.recruitmentOptions?.affiliateOptions
                                ?.marketPlanOptions || []
                            ).find(
                              (marketPlan) =>
                                marketPlan.value ===
                                data?.recruits?.affiliate?.mkPlanId
                            )?.label || "-"
                          }
                        />
                      }
                      rowRight={
                        <DisplayValue
                          label="ระดับสายงาน"
                          value={
                            (
                              options?.recruitmentOptions?.affiliateOptions
                                ?.marketPlanOptions || []
                            )
                              .find(
                                (marketPlan) =>
                                  marketPlan.value ===
                                  data?.recruits?.affiliate?.mkPlanId
                              )
                              ?.positionLevel?.options.find(
                                (positionLevel) =>
                                  positionLevel.value ===
                                  data?.recruits?.affiliate?.positionLevel
                              )?.label || "-"
                          }
                        />
                      }
                    />
                    <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="อัปไลน์"
                          value={
                            (
                              options?.recruitmentOptions?.affiliateOptions
                                ?.marketPlanOptions || []
                            )
                              .find(
                                (marketPlanOption) =>
                                  marketPlanOption.value ===
                                  data?.recruits?.affiliate?.mkPlanId
                              )
                              ?.positionLevel?.options.find(
                                (positionLevel) =>
                                  positionLevel.value ===
                                  data?.recruits?.affiliate?.positionLevel
                              )
                              ?.uplineOptions.find(
                                (upline) =>
                                  upline.value ===
                                  data?.recruits?.affiliate?.uplineId
                              )?.label || "-"
                          }
                        />
                      }
                      rowRight={
                        <DisplayValue
                          label="ผู้ชวน"
                          value={data?.recruits?.affiliate?.inviteBy || "-"}
                        />
                      }
                    />
                  </>
                )}
                <DisplayInterview
                  rowLeft={
                    <>
                      <b>หมายเหตุ</b>
                      <div className={styles.explain}>
                        {data?.recruits?.affiliate?.description || "-"}
                      </div>
                    </>
                  }
                  styleColumnLeft={styles.columnExplain}
                />
                <DisplayInterview
                  rowLeft={
                    <>
                      <div className={styles.authorityLife}>
                        <div className={styles.mockupChecked}>
                          {data?.recruits?.affiliate?.recommendLife && (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={styles.faCheck}
                            />
                          )}
                        </div>
                        <div>สิทธิ์แนะนำหมวดประกันชีวิต</div>
                      </div>
                      <div className={styles.authorityAccident}>
                        <div className={styles.mockupChecked}>
                          {data?.recruits?.affiliate?.recommendNonLife && (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={styles.faCheck}
                            />
                          )}
                        </div>
                        <div>สิทธิ์แนะนำหมวดประกันวินาศภัย</div>
                      </div>
                    </>
                  }
                  styleColumnLeft={
                    data?.recruits?.affiliate?.recruitStatus === "failed"
                      ? cx(styles.authority, styles.authorityHidden)
                      : styles.authority
                  }
                  rowRight={
                    <b
                      className={
                        data?.recruits?.affiliate?.recruitStatus === "success"
                          ? styles.approved
                          : data?.recruits?.affiliate?.recruitStatus ===
                            "processing"
                          ? styles.waiting
                          : data?.recruits?.affiliate?.recruitStatus ===
                            "expired"
                          ? {}
                          : styles.reject
                      }
                    >
                      {data?.recruits?.affiliate?.recruitStatus === "success"
                        ? "อนุมัติ"
                        : data?.recruits?.affiliate?.recruitStatus ===
                          "processing"
                        ? "รออนุมัติ"
                        : data?.recruits?.affiliate?.recruitStatus === "expired"
                        ? "หมดอายุ"
                        : "ไม่อนุมัติ"}
                    </b>
                  }
                  styleColumnRight={{}}
                />
              </div>
            ) : (
              <div className={styles.newInterview}>
                <DisplayInterview
                  rowLeft={
                    <>
                      ผลการสัมภาษณ์ : <b>ผู้แนะนำ</b> จะแสดงเมื่อมีการสมัคร
                    </>
                  }
                  styleColumnLeft={{}}
                />
              </div>
            )}
            {data?.recruits?.lifeBroker ? (
              <div className={styles.newInterview}>
                <DisplayInterview
                  rowLeft={
                    <div>
                      ผลการสัมภาษณ์ : <b>นายหน้าประกันชีวิต</b>
                    </div>
                  }
                  rowRight={
                    <>
                      วันที่สมัคร :&nbsp;
                      {data?.recruits?.lifeBroker?.recruitedAt
                        ? dateFormatterFromPattern(
                            data?.recruits?.lifeBroker?.recruitedAt
                          )
                        : "-"}
                    </>
                  }
                  styleColumnRight={styles.lastUpdatedBy}
                />
                <DisplayInterview
                  rowLeft={
                    <>
                      {data?.recruits?.lifeBroker?.interviewStatus === true
                        ? "ผ่าน"
                        : data?.recruits?.lifeBroker?.interviewStatus === false
                        ? "ไม่ผ่าน"
                        : "ยังไม่ระบุ"}
                    </>
                  }
                  rowRight={
                    <div className={styles.registerNote}>
                      {data?.recruits?.lifeBroker?.note || "-"}
                    </div>
                  }
                  styleColumnRight={styles.columnRegisterNote}
                />
                {data?.recruits?.lifeBroker?.recruitStatus !== "failed" && (
                  <>
                    <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="Staff Code"
                          value={data?.recruits?.lifeBroker?.staffCode || "-"}
                        />
                      }
                      rowRight={
                        <>
                          <div className={styles.authorityAccident}>
                            <div className={styles.mockupChecked}>
                              {data?.recruits?.lifeBroker?.isLeader && (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className={styles.faCheck}
                                />
                              )}
                            </div>
                            <div>หัวหน้านายหน้า</div>
                          </div>
                        </>
                      }
                      styleColumnRight={
                        data?.recruits?.lifeBroker?.recruitStatus === "failed"
                          ? cx(
                              styles.authority,
                              styles.addRole,
                              styles.authorityHidden
                            )
                          : cx(styles.authority, styles.addRole)
                      }
                    />
                    <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="ชื่อทีม"
                          value={data?.recruits?.lifeBroker?.teamName || "-"}
                        />
                      }
                    />
                    <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="แผนการตลาด"
                          value={
                            (
                              options?.recruitmentOptions?.lifeBrokerOptions
                                ?.marketPlanOptions || []
                            ).find(
                              (marketPlan) =>
                                marketPlan.value ===
                                data?.recruits?.lifeBroker?.mkPlanId
                            )?.label || "-"
                          }
                        />
                      }
                      rowRight={
                        <DisplayValue
                          label="ระดับสายงาน"
                          value={
                            (
                              options?.recruitmentOptions?.lifeBrokerOptions
                                ?.marketPlanOptions || []
                            )
                              .find(
                                (marketPlan) =>
                                  marketPlan.value ===
                                  data?.recruits?.lifeBroker?.mkPlanId
                              )
                              ?.positionLevelOptions.find(
                                (positionLevel) =>
                                  positionLevel.value ===
                                  data?.recruits?.lifeBroker?.positionLevel
                              )?.label || "-"
                          }
                        />
                      }
                    />
                    <DisplayInterview
                      rowLeft={
                        <DisplayValue
                          label="อัปไลน์"
                          // value={
                          //   (
                          //     options?.recruitmentOptions?.lifeBrokerOptions
                          //       ?.upline?.options || []
                          //   ).find(
                          //     (upline) =>
                          //       upline.value ===
                          //       data?.recruits?.lifeBroker?.uplineId
                          //   )?.label || "-"
                          // }
                          value={
                            (
                              options?.recruitmentOptions?.lifeBrokerOptions
                                ?.marketPlanOptions || []
                            )
                              .find(
                                (marketPlanOption) =>
                                  marketPlanOption.value ===
                                  data?.recruits?.lifeBroker?.mkPlanId
                              )
                              ?.positionLevelOptions.find(
                                (positionLevel) =>
                                  positionLevel.value ===
                                  data?.recruits?.lifeBroker?.positionLevel
                              )
                              ?.uplineOptions.find(
                                (upline) =>
                                  upline.value ===
                                  data?.recruits?.lifeBroker?.uplineId
                              )?.label || "-"
                          }
                        />
                      }
                      rowRight={
                        <DisplayValue
                          label="ผู้ชวน"
                          value={data?.recruits?.lifeBroker?.inviteBy || "-"}
                        />
                      }
                    />
                  </>
                )}
                <DisplayInterview
                  rowLeft={
                    <>
                      <b>หมายเหตุ</b>
                      <div className={styles.explain}>
                        {data?.recruits?.lifeBroker?.description || "-"}
                      </div>
                    </>
                  }
                  styleColumnLeft={styles.columnExplain}
                />
                <DisplayInterview
                  rowLeft={
                    <>
                      <div className={styles.authorityAccident}>
                        <div className={styles.mockupChecked}>
                          {data?.recruits?.lifeBroker?.recommendNonLife && (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={styles.faCheck}
                            />
                          )}
                        </div>
                        <div>สิทธิ์แนะนำหมวดประกันวินาศภัย</div>
                      </div>
                    </>
                  }
                  styleColumnLeft={
                    data?.recruits?.lifeBroker?.recruitStatus === "failed"
                      ? cx(styles.authority, styles.authorityHidden)
                      : styles.authority
                  }
                  rowRight={
                    <b
                      className={
                        data?.recruits?.lifeBroker?.recruitStatus === "success"
                          ? styles.approved
                          : data?.recruits?.lifeBroker?.recruitStatus ===
                            "processing"
                          ? styles.waiting
                          : data?.recruits?.lifeBroker?.recruitStatus ===
                            "expired"
                          ? {}
                          : styles.reject
                      }
                    >
                      {data?.recruits?.lifeBroker?.recruitStatus === "success"
                        ? "อนุมัติ"
                        : data?.recruits?.lifeBroker?.recruitStatus ===
                          "processing"
                        ? "รออนุมัติ"
                        : data?.recruits?.lifeBroker?.recruitStatus ===
                          "expired"
                        ? "หมดอายุ"
                        : "ไม่อนุมัติ"}
                    </b>
                  }
                  styleColumnRight={{}}
                />
              </div>
            ) : (
              <div className={styles.newInterview}>
                <DisplayInterview
                  rowLeft={
                    <>
                      ผลการสัมภาษณ์ : <b>นายหน้าประกันชีวิต</b>{" "}
                      จะแสดงเมื่อมีการสมัคร
                    </>
                  }
                  styleColumnLeft={{}}
                />
              </div>
            )}
            {data?.recruits?.nonLifeBroker ? null : (
              <div className={styles.newInterview}>
                <DisplayInterview
                  rowLeft={
                    <div style={{ color: "var(--agt-secondary-color-1)" }}>
                      เร็วๆนี้ : <b>นายหน้าประกันวินาศภัย</b>
                    </div>
                  }
                  styleColumnLeft={{}}
                />
              </div>
            )}
            <div className={`${styles.info}`}>
              <DisplayValue
                className={styles.name}
                label="ชื่อ"
                value={`${data?.prefixName || ""} ${data.firstName} ${
                  data.lastName
                }`}
              />
              <DisplayValue
                className={styles.gender}
                label="เพศ"
                value={
                  data.gender ? (data.gender === "M" ? "ชาย" : "หญิง") : "-"
                }
              />
              <DisplayValue
                className={styles.birthdate}
                label="วันเกิด"
                value={dateFormatterFromPattern(data.birthdate)}
              />
              <DisplayValue
                className={styles.idCard}
                label="เลขประจำตัวประชาชน"
                value={data.idCard}
              />
              <DisplayValue
                className={styles.phoneNumber}
                label="เบอร์โทรศัพท์"
                value={phoneNumberFormat(data.phoneNumber)}
              />
              <DisplayValue
                className={styles.email}
                label="อีเมล"
                value={data.email}
              />
              <DisplayValue
                className={styles.homeAddress}
                label="ที่อยู่ตามทะเบียนบ้าน"
                value={addressFormatter(data.homeAddress) || "-"}
              />
              <DisplayValue
                className={styles.idCardAddress}
                label="ที่อยู่ตามบัตรประชาชน"
                value={addressFormatter(data.idCardAddress) || "-"}
              />
              <DisplayValue
                className={styles.currentAddress}
                label="ที่อยู่ในการส่งเอกสาร"
                value={addressFormatter(data.currentAddress) || "-"}
              />
              <DisplayValue
                className={styles.insuranceCompanyName}
                label="บริษัทประกันชีวิตต้นสังกัด"
                value={
                  options.insuranceCompanyNameOptions.find(
                    (insuranceCompanyName) =>
                      insuranceCompanyName.value === data.insuranceCompanyName
                  )?.label || "-"
                }
              />
            </div>
          </div>
          <div className={styles.documents}>
            <div className={styles.agentDocuments}>
              {data?.explain && (
                <div className={styles.notification}>
                  <div className={styles.notificationLabel}>
                    <label>ชี้แจงสำคัญ</label>
                    <Icon name="info" className={styles.iconInfo} />
                  </div>
                  <div className={styles.notificationInput}>{data.explain}</div>
                </div>
              )}
              <b style={{ paddingLeft: 16 }}>เอกสารการสมัคร</b>
              <div className={styles.header}>
                <div style={{ paddingLeft: 16 }}>เอกสาร</div>
                <div>สถานะ</div>
                <div>วันอัปเดต</div>
                <div>คำแนะนำ</div>
              </div>
              {data.agentDocuments.map((agentDocument, index) => {
                return (
                  <div key={index} className={styles.row}>
                    <div
                      style={{ paddingLeft: 16, paddingRight: 8 }}
                      className={styles.name}
                    >
                      <Icon
                        name="upload"
                        className={
                          agentDocument?.documentImageId
                            ? styles.iconUpload
                            : styles.iconUploadDisabled
                        }
                      />
                      <label
                        style={
                          agentDocument?.documentImageId
                            ? { color: "var(--agt-primary-color)" }
                            : {
                                color: "var(--agt-secondary-color-1)",
                                cursor: "not-allowed",
                              }
                        }
                        onClick={() => {
                          if (agentDocument?.documentImageId)
                            getFileDocument(
                              {
                                type: "document",
                                downloadId: agentDocument?.id,
                              },
                              agentDocument?.name
                            );
                        }}
                      >
                        {agentDocument?.name}
                      </label>
                    </div>
                    <div>{agentDocument?.statusTh || "-"}</div>
                    <div>
                      {agentDocument?.updatedAt
                        ? dateFormatterFromPattern(agentDocument.updatedAt)
                        : "-"}
                    </div>
                    <div>{agentDocument?.description || "-"}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.agentImportant}>
              <b style={{ paddingLeft: 16, paddingRight: 8 }}>
                สำเนาเอกสารสำคัญ
              </b>
              {data.agentImportantDocuments.map(
                (agentImportantDocument, index) => {
                  return (
                    <div key={index} className={styles.row}>
                      <div style={{ paddingLeft: 16 }} className={styles.name}>
                        <Icon
                          name="upload"
                          className={
                            agentImportantDocument?.importantDocumentImageId
                              ? styles.iconUpload
                              : styles.iconUploadDisabled
                          }
                        />
                        <label
                          style={
                            agentImportantDocument?.importantDocumentImageId
                              ? { color: "var(--agt-primary-color)" }
                              : {
                                  color: "var(--agt-secondary-color-1)",
                                  cursor: "not-allowed",
                                }
                          }
                          onClick={() => {
                            if (
                              agentImportantDocument?.importantDocumentImageId
                            )
                              getFileDocument(
                                {
                                  type: "important_document",
                                  downloadId: agentImportantDocument?.id,
                                },
                                agentImportantDocument?.name
                              );
                            else return;
                          }}
                        >
                          {agentImportantDocument?.name}
                        </label>
                      </div>
                      <div>{agentImportantDocument?.statusTh || "-"}</div>
                      <div>
                        {agentImportantDocument?.updatedAt
                          ? dateFormatterFromPattern(
                              agentImportantDocument.updatedAt
                            )
                          : "-"}
                      </div>
                      <div>{agentImportantDocument?.description || "-"}</div>
                    </div>
                  );
                }
              )}
            </div>
            <div className={styles.agentLicense}>
              <b style={{ paddingLeft: 16, paddingRight: 8 }}>เลขที่ใบอนุญาต</b>
              <div className={styles.header}>
                <div style={{ paddingLeft: 16 }}>เลขที่</div>
                <div>ประเภทใบอนุญาต</div>
                <div>สถานะ</div>
                <div>วันหมดอายุ</div>
                <div>วันที่อัปเดต</div>
              </div>
              {data.agentLicenses.map((agentLicense, index) => {
                return (
                  <Fragment key={index}>
                    <div className={styles.row}>
                      <div style={{ paddingLeft: 16 }} className={styles.name}>
                        <Icon
                          name="upload"
                          className={
                            agentLicense.licenseCode
                              ? styles.iconUpload
                              : styles.iconUploadDisabled
                          }
                        />
                        <label
                          style={
                            agentLicense?.licenseCode
                              ? { color: "var(--agt-primary-color)" }
                              : {
                                  color: "var(--agt-secondary-color-1)",
                                  cursor: "not-allowed",
                                }
                          }
                          onClick={() => {
                            if (agentLicense?.licenseCode)
                              getFileDocument(
                                {
                                  type: "agent_license",
                                  downloadId: agentLicense?.id,
                                },
                                agentLicense?.licenseCode
                              );
                            else return;
                          }}
                        >
                          {agentLicense?.licenseCode || "ยังไม่ระบุ"}
                        </label>
                      </div>
                      <div>{agentLicense?.licenseType}</div>
                      <div>{agentLicense?.statusTh || "-"}</div>
                      <div>
                        {agentLicense?.licenseExpiredAt
                          ? dateFormatterFromPattern(
                              agentLicense.licenseExpiredAt
                            ) || "-"
                          : "ยังไม่ระบุ"}
                      </div>
                      <div>
                        {agentLicense?.updatedAt
                          ? dateFormatterFromPattern(agentLicense.updatedAt)
                          : "-"}
                      </div>
                    </div>
                    <div className={styles.subrow}>
                      <div />
                      <div>
                        <label className={styles.description}>คำแนะนำ</label>
                        <label className={styles.nowrap}>
                          {agentLicense?.description || "-"}
                        </label>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </Container>
  );
}

export default authorize(enhancer(AgentId), {
  roles: "agent",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["modify"],
});
