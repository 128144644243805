import { useField } from "formik";
import cx from "classnames";

import styles from "./Textarea.module.css";
import inputStyles from "components/Form/Element/Input/Input.module.css";

function Textarea({
  className = "",
  label = "",
  name = "",
  placeholder = "",
  type = "text",
  onChange = () => {},
  required = false,
  rows = "4",
}) {
  const [field, meta, helpers] = useField(name);
  const { onBlur } = field;
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    onChange(value);
  };

  return (
    <div className={cx(inputStyles.container, className)}>
      {label && (
        <label className={`label ${inputStyles.label}`} htmlFor={name}>
          {label}
          {required && <span className="error">*</span>}
        </label>
      )}
      <textarea
        id={name}
        className={styles.input}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        data-state={touched && error ? "error" : ""}
        rows={rows}
      />
      {touched && error && (
        <span className={`error small ${inputStyles.error}`}>{error}</span>
      )}
    </div>
  );
}

export default Textarea;
