import format from "date-fns/format";
import setYear from "date-fns/setYear";
import th from "date-fns/locale/th";

const dateFormatterFromPattern = (date = "", pattern = "dd/MM/yyyy") => {
  if (!date) return "";

  const modifiedDate = new Date(date);
  const modifiedYear = setYear(modifiedDate, modifiedDate.getFullYear() + 543);

  switch (pattern) {
    case "dd/MM/yyyy":
    case "DD/MM/YYYY":
      return format(modifiedYear, "dd/MM/yyyy", { locale: th });
    case "MMMM yyyy":
      return format(modifiedYear, "MMMM yyyy", { locale: th });
    default:
      return date;
  }
};

const MONTH_TH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const MONTH_OPTIONS = MONTH_TH.map((m, index) => ({ label: m, value: index }));

const getYearsOptions = (startYear, order = "desc", offset = 0) => {
  let currentYear = new Date().getFullYear() + offset;
  let years = [];
  startYear = startYear || currentYear - 100;
  while (startYear <= currentYear) {
    const year = order === "desc" ? currentYear-- : startYear++;
    years.push({ label: year + 543, value: year });
  }
  return years;
};

const YEAR_OPTIONS = getYearsOptions(null, "desc", 1);

export {
  MONTH_TH,
  MONTH_OPTIONS,
  YEAR_OPTIONS,
  getYearsOptions,
  dateFormatterFromPattern,
};
