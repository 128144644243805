import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getConsentById,
  editConsent,
  // sendEmailAgain,
} from "store/consentSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { agreement } = useSelector((state) => state.consent);

    useEffect(() => {
      dispatch(getConsentById(id));
    }, [dispatch, id]);

    const onSubmit = (values) => {
      dispatch(
        editConsent(id, values, () => {
          history.push(`/consent/${id}`);
        })
      );
    };

    const sendEmail = (values) => {
      console.log(values);
      // dispatch(sendEmailAgain(id, values));
    };

    return { id, data: agreement, onSubmit, sendEmail };
  }),
  mapProps(({ id, data, onSubmit, sendEmail }) => ({
    id,
    data,
    onSubmit,
    sendEmail,
  }))
);

export default enhancer;
