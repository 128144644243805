import { useField } from "formik";
import cx from "classnames";

import styles from "./Permission.module.css";

function Checkbox({ index, name, label, value, disabled }) {
  const checkboxStyle = index % 2 === 1 ? styles.isOdd : null;
  const [field, , helpers] = useField({
    name: `permission.${name}`,
    type: "checkbox",
  });

  return (
    <>
      <div className={cx(styles.checkbox, checkboxStyle)}>
        <input
          {...field}
          type="checkbox"
          checked={field.value?.includes(value) || false}
          onChange={() => {
            const set = new Set(field.value);
            if (set.has(value)) {
              set.delete(value);
            } else {
              set.add(value);
            }
            helpers.setValue(Array.from(set));
            helpers.setTouched(true);
          }}
          disabled={disabled}
        />
      </div>
      <div className={cx(styles.text, checkboxStyle)}>
        <label>{label}</label>
      </div>
    </>
  );
}

function PermissionField({ name, field, disabled }) {
  return (
    <div className={styles.permission}>
      <div className={cx(styles.header, styles.checkbox)}>สิทธิ์ใช้งาน</div>
      <div className={cx(styles.header, styles.text)}>{field.name}</div>
      {field.permissions.map((p, i) => {
        return (
          <Checkbox key={i} index={i} name={name} {...p} disabled={disabled} />
        );
      })}
    </div>
  );
}

function Permission({ className = "", disabled = false, options = [] }) {
  return (
    <section className={cx(styles.container, className)}>
      <div className={styles.topic}>
        <b>สิทธิ์การใช้งานในระบบ</b>
      </div>
      {Object.entries(options).map(([name, value], index) => {
        if (name === "dashboard") return null;
        else
          return (
            <PermissionField
              key={index}
              name={name}
              field={value}
              disabled={disabled}
            />
          );
      })}
    </section>
  );
}

export default Permission;
