import { useField } from "formik";
import cx from "classnames";

import styles from "./Gender.module.css";
import inputStyles from "components/Form/Element/Input/Input.module.css";

function Gender({
  name = "",
  label = "",
  className = "",
  required = false,
  onChange = () => {},
}) {
  const [, meta, helpers] = useField(name);
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = (value) => {
    setValue(value);
    if (onChange) onChange(value);
  };

  return (
    <div className={cx(inputStyles.container, className)}>
      {label && (
        <label className={cx("label", inputStyles.label)} htmlFor={name}>
          {label}
          {required && <span className="error">*</span>}
        </label>
      )}
      <div
        className={styles.buttonContainer}
        data-state={touched && error ? "error" : ""}
      >
        <button
          type="button"
          className={cx("secondary", styles.genderButton, styles.male)}
          data-state={value === "M" ? "selected" : ""}
          onClick={() => handleChange("M")}
        >
          ชาย
        </button>
        <button
          type="button"
          className={cx("secondary", styles.genderButton, styles.female)}
          data-state={value === "F" ? "selected" : ""}
          onClick={() => handleChange("F")}
        >
          หญิง
        </button>
      </div>
      {touched && error && (
        <span className={cx("error", "small", inputStyles.error)}>{error}</span>
      )}
    </div>
  );
}

export default Gender;
