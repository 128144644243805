import { useMemo } from "react";
import { Link } from "react-router-dom";
import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import containerStyles from "components/Layout/Container/Container.module.css";
import styles from "./Marketing.module.css";
import enhancer from "./Marketing.compose";
import Table from "components/Table";
import { dateFormatterFromPattern } from "utils/formatter";
import authorize, { UnauthorizedAction } from "utils/authorize";

function Marketing(props) {
  const { data = [], paging = {}, onChange, disabled } = props;
  const columns = useMemo(
    () => [
      {
        Header: "ชื่อแผน",
        accessor: "name",
        styles: {
          header: {
            width: "418px",
          },
        },
        Cell: (cell) => {
          return (
            <Link to={`/marketing_plan/${cell.row.original.id}`}>
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "จำนวนระดับ",
        accessor: "numberOfLevel",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "วันที่แก้ไขล่าสุด",
        accessor: "updatedAt",
        styles: {
          header: {
            width: "160px",
          },
        },
        Cell: (cell) => dateFormatterFromPattern(cell.value) || "-",
      },
    ],
    []
  );

  return (
    <Container>
      <h4 className="topic">จัดการแผนการตลาดทีมผู้แนะนำ</h4>
      <div className={containerStyles.breadcrumbWithButton}>
        <Breadcrumb
          links={[{ label: "จัดการแผนการตลาดทีมผู้แนะนำ", current: true }]}
        />
        <Link to="/marketing_plan/new">
          <button className={styles.btn} disabled={disabled}>
            + สร้าง
          </button>
        </Link>
      </div>
      <Table
        data={data}
        columns={columns}
        className={styles.table}
        onChange={onChange}
        paging={paging}
      />
    </Container>
  );
}

export default authorize(enhancer(Marketing), {
  roles: "mkPlan",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["crud"],
});
