import moment from "moment";
import { isEmail } from "utils/validator";
import format from "date-fns/format";
import setYear from "date-fns/setYear";
import th from "date-fns/locale/th";

export const dateFormat = (dateStr) => {
  const dateFormat = moment(dateStr);
  const day = dateFormat.format("DD");
  const month = dateFormat.format("MM");
  const year = parseInt(dateFormat.format("YYYY"), 10) + 543;
  const newDate = day + "/" + month + "/" + year;
  return newDate;
};

export const dateFormatterFromPattern = (date = "", pattern = "dd/MM/yyyy") => {
  if (!date) return "";

  const modifiedDate = new Date(date);
  const modifiedYear = setYear(modifiedDate, modifiedDate.getFullYear() + 543);

  switch (pattern) {
    case "dd/MM/yyyy":
    case "DD/MM/YYYY":
      return format(modifiedYear, "dd/MM/yyyy", { locale: th });
    case "MMMM yyyy":
      return format(modifiedYear, "MMMM yyyy", { locale: th });
    default:
      return date;
  }
};

export const phoneNumberFormat = (phoneNumber) => {
  let newPhoneNumber = phoneNumber
    ? phoneNumber?.slice(0, 3) +
      "-" +
      phoneNumber?.slice(3, 6) +
      "-" +
      phoneNumber?.slice(6, 10)
    : "";

  return newPhoneNumber;
};

export function safeParseFloat(number) {
  number = parseFloat(number);
  if (isNaN(number)) {
    number = 0.0;
  }
  return number;
}

export function currency(number, precision = 2, prefix = "฿") {
  if (number === null || number === undefined) {
    return "";
  }

  number = safeParseFloat(number);
  precision = parseInt(precision);

  return `${prefix}${number.toLocaleString("th", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })}`;
}

export function emailCensorer(email, startIndex = 1) {
  if (!email || !isEmail(email)) return null;
  const splitedEmail = email.split("@");
  const censored = splitedEmail[0]
    .split("")
    .map((char, index) => (index < startIndex ? char : "*"))
    .join("");
  return `${censored}@${splitedEmail[1]}`;
}

export function addressFormatter(address) {
  if (!address) return null;
  return `${address.housingNo ? `${address.housingNo} ` : ""}${
    address.villageNo ? `หมู่ ${address.villageNo} ` : ""
  }${address.building ? `หมู่บ้าน/อาคาร ${address.building} ` : ""}${
    address.lane ? `ซอย ${address.lane} ` : ""
  }${address.road ? `ถนน ${address.road} ` : ""}${
    address.subDistrict ? `ตำบล/แขวง ${address.subDistrict} ` : ""
  }${address.district ? `อำเภอ/เขต ${address.district} ` : ""}${
    address.province ? `${address.province} ` : ""
  }${address.postcode ? `${address.postcode} ` : ""}
  `;
}
