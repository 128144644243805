import React from "react";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AdminForm from "components/Form/AdminForm";

import enhancer from "./EditAdmin.compose";
import styles from "./EditAdmin.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

function EditAdmin({ id = "", data = {}, onSubmit = () => {} }) {
  return (
    <Container>
      <h4 className="topic">จัดการผู้ใช้แอดมิน</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "จัดการผู้ใช้แอดมิน", link: "/admin" },
            {
              label: data?.adminCode || 999999,
              link: `/admin/${id}`,
            },
            { label: `แก้ไข`, current: true },
          ]}
        />
        <button className={cx(styles.editBtn)} form="editAdmin" type="submit">
          บันทึก
        </button>
      </div>
      <AdminForm id="editAdmin" initialValues={data} onSubmit={onSubmit} />
    </Container>
  );
}

export default authorize(enhancer(EditAdmin), {
  roles: "admin",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["modify"],
});
