import cx from "classnames";
import Icon from "components/Icon";
import styles from "./Checkbox.module.css";

function Checkbox({ className = "" }) {
  return (
    <div className={cx(styles.checkbox, className)}>
      <Icon name="check" className={styles.checkIcon} />
    </div>
  );
}

export default Checkbox;
