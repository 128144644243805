import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AgentForm from "components/Form/AgentForm";

import enhancer from "./EditAgent.compose";
import styles from "./EditAgent.module.css";

function EditAgent({
  id,
  data,
  onSubmit,
  sendEmail,
  updateAgentType,
  errorRecruit,
  handleChangeRecruit,
  showModal,
  handleCloseModal,
}) {
  if (data) {
    return (
      <Container className={styles.mainContainer}>
        <header className={styles.header}>
          <h4 className="topic">จัดการผู้สมัคร</h4>
          <Breadcrumb
            links={[
              { link: "/agents", label: "จัดการผู้สมัคร" },
              {
                link: `/agents/${id}`,
                label:
                  `${data.firstName} ${data.lastName}` || "รายชื่อผู้สมัคร",
              },
              { label: "แก้ไขข้อมูลผู้สมัคร", current: true },
            ]}
          />
        </header>
        <section className={styles.container}>
          <AgentForm
            initialValues={data}
            onSubmit={onSubmit}
            sendEmail={sendEmail}
            updateAgentType={updateAgentType}
            errorRecruit={errorRecruit}
            handleChangeRecruit={handleChangeRecruit}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
        </section>
      </Container>
    );
  }
  return <div />;
}

export default enhancer(EditAgent);
