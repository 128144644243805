import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import Container from "components/Layout/Container";
import Typography from "./Typography";
import Components from "./Components";
import Form from "./Form";
import Hooks from "./Hooks";

import enhancer from "./Example.compose";
import styles from "./Example.module.css";
import Icon from "components/Icon";

function Example({ data, onLogout }) {
  return (
    <Container>
      <div className={styles.iconContainer}>
        <label>โปรดตัวสอบเอกสาร</label>
        <Icon className={styles.iconDocument} name="document" />
      </div>
      <div className={styles.container}>
        <Typography />
        <hr />
        <Components data={data} />
        <hr />
        <Form />
        <hr />
        <Hooks />
        <hr />
        <button onClick={onLogout}>
          <FontAwesomeIcon className={styles.logoutBtn} icon={faSignOutAlt} />
          LOGOUT
        </button>
      </div>
    </Container>
  );
}

export default enhancer(Example);
