import { Route, Switch } from "react-router-dom";
import Marketing from "./root";
import MarketingId from "./_id";
import NewMarketing from "./new";
import EditMarketing from "./edit";

const Pages = (props) => (
  <Switch>
    <Route exact path="/marketing_plan/new" component={NewMarketing} />
    <Route exact path="/marketing_plan/:id/edit" component={EditMarketing} />
    <Route exact path="/marketing_plan/:id" component={MarketingId} />
    <Route exact path="/marketing_plan" component={Marketing} />
  </Switch>
);

export default Pages;
