import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import useToggle from "hooks/useToggle";
import {
  exportCommission,
  getCommissionOptions,
  getCommissions,
} from "store/commissionSlice";
import { useHistory } from "react-router-dom";

const enhancer = compose(
  withHooks(() => {
    const [isShowFilterBar, toggle] = useToggle();
    const [searchValue, setSearchValue] = useState({
      opportunityKey: "",
      orderNo: "",
      agentName: "",
      teamName: "",
      receivedDate: "",
      policyYear: "",
      installmentNo: "",
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10,
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const {
      commissions = [],
      paging = {},
      isLoading = false,
      options = [],
    } = useSelector((state) => state.commission);

    useEffect(() => {
      dispatch(getCommissionOptions());
    }, [dispatch]);

    useEffect(() => {
      dispatch(getCommissions(searchValue));
    }, [dispatch, searchValue]);

    const onChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    const goToReport = useCallback(
      (query) => {
        history.push(`/commission/report?${query}`);
      },
      [history]
    );

    const getReport = useCallback(
      (values) => {
        dispatch(exportCommission(values, () => {}));
      },
      [dispatch]
    );

    return {
      data: commissions,
      isLoading,
      paging,
      options,
      isShowFilterBar,
      toggle,
      onChange,
      searchValue,
      goToReport,
      getReport,
    };
  }),
  mapProps((props) => {
    return {
      data: props.data,
      isLoading: props.isLoading,
      paging: props.paging,
      options: props.options,
      isShowFilterBar: props.isShowFilterBar,
      toggle: props.toggle,
      onChange: props.onChange,
      searchValue: props.searchValue,
      goToReport: props.goToReport,
      getReport: props.getReport,
      disabled: props?.disabled || false,
    };
  })
);

export default enhancer;
