import { useMemo } from "react";
import cx from "classnames";

import Table from "components/Table";

import { dateFormatterFromPattern } from "utils/formatter";
import styles from "./AffiliateHistoryTable.module.css";

function AffiliateHistoryTable({ className = "", data = [] }) {
  const columns = useMemo(() => {
    return [
      {
        Header: "ชื่อ",
        accessor: "name",
        styles: {
          header: {
            width: "260px",
          },
        },
      },
      {
        Header: "ประเภท",
        accessor: "agreementType",
        styles: {
          header: {
            width: "220px",
          },
        },
      },
      {
        Header: "หน้า",
        accessor: "contentDisplay",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "เวอร์ชั่น",
        accessor: "version",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "วันที่ยินยอม",
        accessor: "consentedAt",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
        },
        Cell: (cell) => dateFormatterFromPattern(cell.value) || "-",
      },
    ];
  }, []);
  return (
    <section className={cx(styles.container, className)}>
      <Table columns={columns} data={data} pagination={false} />
    </section>
  );
}

export default AffiliateHistoryTable;
