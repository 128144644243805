import Modal from "components/Modal";
import CheckboxMockup from "components/Mockup/Checkbox";

import useToggle from "hooks/useToggle";
import styles from "./Preview.module.css";
import Logo from "assets/images/agent_portal_backoffice.png";

function Preview({ title = "", text = "", consents = [] }) {
  const [showModal, , handleOpenModal, handleCloseModal] = useToggle();
  return (
    <>
      <button
        type="button"
        className={styles.previewBtn}
        onClick={handleOpenModal}
        disabled={text.length <= 0}
      >
        ดูตัวอย่าง
      </button>
      <Modal
        className={styles.previewModal}
        isOpen={showModal}
        onSubmit={handleCloseModal}
        onCancel={handleCloseModal}
        submitText={"ส่งอีเมล"}
        cancelText={"ไม่ส่งอีเมล"}
        contentLabel="Preview consent modal"
      >
        <div className={styles.children}>
          <img className={styles.logo} src={Logo} alt="TQM agent portal logo" />
          <div className={styles.topic}>
            <b>{title || "ข้อตกลงและเงื่อนไขการลงทะเบียน"}</b>
          </div>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {consents.length > 0 && (
            <div className={styles.consents}>
              {consents.map((c, index) => {
                return (
                  <div key={index} className={styles.consentContainer}>
                    <CheckboxMockup className={styles.checkbox} />
                    <div className={styles.consentText}>{c.name}</div>
                  </div>
                );
              })}
            </div>
          )}
          <button
            type="button"
            onClick={handleCloseModal}
            className={styles.btn}
          >
            ยืนยันข้อตกลง
          </button>
          {/* <small className={styles.footer}>
          Copyright© 2020 TQM สงวนสิทธิ์ทุกประการ
        </small>
        <small className={styles.footer}>นโยบายความเป็นส่วนตัว</small> */}
        </div>
      </Modal>
    </>
  );
}

export default Preview;
