import React from "react";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import TeamForm from "components/Form/TeamForm";

import newTeamStyles from "./NewTeam.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";

import enhancer from "./NewTeam.compose";

function NewTeam({ onSubmit, options }) {
  return (
    <Container>
      <h4 className="topic">สร้างทีม</h4>
      <div className={containerStyles.breadcrumbWithButton}>
        <Breadcrumb
          links={[
            { label: "จัดการทีมผู้แนะนำ", link: "/team" },
            { label: "สร้างทีม", current: true },
          ]}
        />
        <button className={newTeamStyles.btn} form="newTeam" type="submit">
          บันทึก
        </button>
      </div>
      <TeamForm id="newTeam" onSubmit={onSubmit} options={options} />
    </Container>
  );
}

export default enhancer(NewTeam);
