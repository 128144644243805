import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getAdminById,
  getAdminOptions,
  sendConfirmEmailById,
  sendUpdateEmailById,
} from "store/adminSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const dispatch = useDispatch();
    const { admin, options } = useSelector((state) => state.admin);

    useEffect(() => {
      dispatch(getAdminById(id));
      dispatch(getAdminOptions());
    }, [dispatch, id]);

    const sendConfirmEmail = useCallback(() => {
      dispatch(sendConfirmEmailById(id));
    }, [dispatch, id]);

    const sendUpdateEmail = useCallback(() => {
      dispatch(sendUpdateEmailById(id));
    }, [dispatch, id]);

    return {
      id,
      data: { ...admin, picked: "One" },
      options,
      sendConfirmEmail,
      sendUpdateEmail,
    };
  }),
  mapProps(
    ({
      id,
      data,
      options,
      sendConfirmEmail,
      sendUpdateEmail,
      disabled = false,
    }) => ({
      id,
      data,
      options,
      sendConfirmEmail,
      sendUpdateEmail,
      disabled,
    })
  )
);

export default enhancer;
