import { useState } from "react";
import { useField } from "formik";
import cx from "classnames";
import Select from "react-select";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isObjectLike from "lodash/isObjectLike";

import Input from "components/Form/Element/Input";

import { addressStructure } from "utils/helper";
import styles from "./Address.module.css";

const ADDRESS_FIELD = [
  { key: "subDistrict", label: "ตำบล/แขวง" },
  { key: "district", label: "อำเภอ/เขต" },
  { key: "province", label: "จังหวัด" },
  { key: "postcode", label: "รหัสไปรษณีย์" },
];

const AutoCompleteAddress = ({
  name = "",
  placeholder = "โปรดระบุ",
  disabled = false,
}) => {
  const DEFAULT_STYLES = {
    menu: (provided, { selectProps: { width } }) => {
      return {
        ...provided,
        width: width,
      };
    },
    option: (provided) => ({
      ...provided,
    }),
    control: (provided, { selectProps: { width, error } }) => {
      return {
        // none of react-select's styles are passed to <Control />
        ...provided,
        width: width,
        height: 48,
        borderColor: error
          ? "var(--agt-error-color)"
          : "var(--agt-secondary-color-1)",
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
    valueContainer: (provided) => {
      return { ...provided, padding: "2px 16px" };
    },
    indicatorSeparator: () => {},
  };

  const [filterOptions, setFilterOptions] = useState([]);
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  return ADDRESS_FIELD.map((address) => {
    return (
      <div
        style={{ marginBottom: 24 }}
        className={styles[address.key]}
        key={address.key}
      >
        <label htmlFor={address.key}>{address.label}</label>
        <Select
          value={
            value && value[`${address.key}`]
              ? { label: value[`${address.key}`] }
              : false
          }
          styles={DEFAULT_STYLES}
          placeholder={placeholder}
          options={filterOptions}
          isDisabled={disabled}
          onChange={(option) => {
            if (isObjectLike(option)) {
              setValue({
                ...(value || {}),
                ...option.value,
              });
            }
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          noOptionsMessage={() => null}
          onInputChange={(valueChange) => {
            if (!isEmpty(valueChange)) {
              setTimeout(() => {
                setFilterOptions(
                  filter(addressStructure, (o) =>
                    o.value[address.key].startsWith(valueChange)
                  )
                );
              });
            }
          }}
        />
      </div>
    );
  });
};

const Address = ({ name, className = "" }) => {
  return (
    <div className={cx(styles.container, className)}>
      <Input
        label="เลขที่"
        className={styles.housingNo}
        name={`${name}.housingNo`}
        placeholder="โปรดระบุ"
      />
      <Input
        label="หมู่"
        className={styles.villageNo}
        name={`${name}.villageNo`}
        placeholder="โปรดระบุ"
      />
      <Input
        label="หมู่บ้าน/อาคาร"
        className={styles.building}
        name={`${name}.building`}
        placeholder="โปรดระบุ"
      />
      <Input
        label="ซอย/ตรอก"
        className={styles.lane}
        name={`${name}.lane`}
        placeholder="โปรดระบุ"
      />
      <Input
        label="ถนน"
        className={styles.road}
        name={`${name}.road`}
        placeholder="โปรดระบุ"
      />
      <AutoCompleteAddress name={name} />
    </div>
  );
};

export default Address;
