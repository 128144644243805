import { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { useField } from "formik";
import Select from "react-select";
import merge from "lodash/merge";

import inputStyles from "components/Form/Element/Input/Input.module.css";

export const DEFAULT_STYLES = {
  menu: (provided, { selectProps: { width } }) => {
    return {
      ...provided,
      width: width,
    };
  },
  option: (provided) => ({
    ...provided,
  }),
  control: (provided, { selectProps: { width, error } }) => {
    return {
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: width,
      height: 48,
      borderColor: error
        ? "var(--agt-error-color)"
        : "var(--agt-secondary-color-1)",
    };
  },
  singleValue: (provided, state) => {
    // const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "var(--agt-primary-text-color)";

    return { ...provided, color, transition };
  },
  valueContainer: (provided) => {
    return { ...provided, padding: "2px 16px" };
  },
  indicatorSeparator: () => {},
  placeholder: (provided) => {
    return { ...provided, color: "var(--agt-secondary-color-1)" };
  },
};

export function Selector(params) {
  const { usePortal = true } = params;
  const mergedStyles = merge({}, DEFAULT_STYLES, params.styles);
  return (
    <Select
      {...params}
      styles={mergedStyles}
      menuPortalTarget={usePortal ? document.body : ""}
    />
  );
}

function SelectorContainer({
  className = "",
  name = "",
  label = "",
  placeholder = "เลือก",
  options = [],
  onChange = () => {},
  styles = {},
  width = 220,
  required = false,
  isSearchable = false,
  disabled = false,
}) {
  const [field, meta, helpers] = useField(name);
  const { onBlur } = field;
  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;

  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = useCallback(
    (selectedOption) => {
      setSelectedValue(selectedOption);
      setValue(selectedOption.value);
      if (selectedOption) onChange(selectedOption.value);
    },
    [setSelectedValue, setValue, onChange]
  );

  useEffect(() => {
    setSelectedValue(options.find((select) => select.value === value) || null);
  }, [value, setSelectedValue, options]);

  return (
    <div className={cx(inputStyles.container, className)}>
      {label && (
        <label className={`label ${inputStyles.label}`} htmlFor={name}>
          {label}
          {required && <span className="error">*</span>}
        </label>
      )}
      <Selector
        id={name}
        styles={{ ...DEFAULT_STYLES, ...styles }}
        width={width}
        value={selectedValue}
        onChange={handleChange}
        onBlur={(e) => {
          onBlur(e);
          setTouched();
        }}
        options={options}
        placeholder={placeholder}
        error={touched && error}
        isSearchable={isSearchable}
        isDisabled={disabled}
        className={className}
        menuPortalTarget={document.body}
      />
      {touched && error && (
        <span className={`error small ${inputStyles.error}`}>{error}</span>
      )}
    </div>
  );
}

export default SelectorContainer;
