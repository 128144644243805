import { Field, Formik } from "formik";
import cx from "classnames";
import merge from "lodash/merge";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import DatePicker from "components/Form/Element/DatePicker";
import Permission from "./Permission";

import enhancer from "./AdminForm.compose";
import validation from "./AdminForm.validation";
import { DEFAULT_VALUES } from "./AdminForm.utils";
import styles from "./AdminForm.module.css";

function AdminForm({
  id = "",
  options = {},
  className = "",
  initialValues = {},
  onSubmit = () => {},
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={merge({}, DEFAULT_VALUES, initialValues)}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, values } = formikProps;
        return (
          <form
            id={id}
            onSubmit={handleSubmit}
            className={cx(styles.container, className)}
          >
            <div className={styles.fillContainer}>
              <Input
                className={styles.agentCode}
                name="adminCode"
                label="รหัสพนักงาน"
                placeholder="โปรดระบุ"
                required
              />
              <Selector
                className={styles.status}
                name="status"
                label="สถานะ"
                placeholder="เลือก"
                options={options.statusOptions || []}
                required
                width="100%"
              />
              <div className={styles.nameTh}>ชื่อภาษาไทย</div>
              <Selector
                className={styles.prefixNameTh}
                name="prefixName"
                label="คำนำหน้า"
                placeholder="เลือก"
                options={(options.prefixNameThOptions || []).map((pre) => ({
                  label: pre.th || pre.label,
                  value: pre.value,
                }))}
                required
                width="100%"
              />
              <Input
                className={styles.firstNameTh}
                name="firstName"
                label="ชื่อจริง"
                placeholder="โปรดระบุ"
                required
              />
              <Input
                className={styles.lastNameTh}
                name="lastName"
                label="นามสกุล"
                placeholder="โปรดระบุ"
                required
              />
              <div className={styles.nameEn}>ชื่อภาษาอังกฤษ</div>
              <Selector
                className={styles.prefixNameEn}
                name="prefixNameEn"
                label="คำนำหน้า"
                placeholder="เลือก"
                options={(options.prefixNameEnOptions || []).map((pre) => ({
                  label: pre.en || pre.label,
                  value: pre.value,
                }))}
                required
                width="100%"
              />
              <Input
                className={styles.firstNameEn}
                name="firstNameEn"
                label="ชื่อจริง"
                placeholder="โปรดระบุ"
                required
              />
              <Input
                className={styles.lastNameEn}
                name="lastNameEn"
                label="นามสกุล"
                placeholder="โปรดระบุ"
                required
              />
              <Input
                className={styles.tel}
                name="phoneNumber"
                label="เบอร์โทรศัพท์"
                placeholder="โปรดระบุ"
                required
              />
              <Input
                className={styles.email}
                name="email"
                label="อีเมล"
                placeholder="โปรดระบุ"
                required
              />
              <Selector
                className={styles.position}
                name="adminType"
                label="ตำแหน่ง"
                placeholder="เลือก"
                options={options.adminTypeOptions || []}
                required
                width="100%"
              />
              <DatePicker
                className={styles.startedDate}
                name="activatedAt"
                label="วันที่เริ่มงาน"
                placeholder="โปรดระบุ"
                required
              />
              {values.adminType === "area_admin" ? (
                <Selector
                  className={styles.areaCode}
                  name="areaCode"
                  label="พื้นที่"
                  placeholder="เลือก"
                  options={options.areaOptions || []}
                  required
                  width="100%"
                />
              ) : (
                values.adminType === "branch_admin" && (
                  <Selector
                    className={styles.areaCode}
                    name="branchCode"
                    label="สาขา"
                    placeholder="เลือก"
                    options={options.branchOptions || []}
                    required
                    width="100%"
                  />
                )
              )}
            </div>
            <section className={styles.notice}>
              <div className={styles.topic}>
                <b>ตั้งค่าการแจ้งเตือน</b>
              </div>
              <div className={styles.permission}>
                <div className={cx(styles.header, styles.checkbox)}>
                  สิทธิ์ใช้งาน
                </div>
                <div className={cx(styles.header, styles.text)}>
                  เมนูจัดการผู้สมัคร
                </div>
                <div className={cx(styles.checkbox, styles.radio)}>
                  <Field type="radio" name="picked" value="One" />
                  <span className={styles.checkmark}></span>
                </div>
                <div className={cx(styles.text)}>
                  ได้รับการแจ้งเตือนจากผู้สมัครทั้งหมด
                </div>
                <div
                  className={cx(styles.checkbox, styles.radio, styles.isOdd)}
                >
                  <Field type="radio" name="picked" value="Two" />
                  <span className={styles.checkmark}></span>
                </div>
                <div className={cx(styles.text, styles.isOdd)}>
                  ได้รับการแจ้งเตือนเฉพาะผู้สมัครที่เป็นผู้รับผิดชอบหรือผู้สัมภาษณ์เท่านั้น
                </div>
              </div>
            </section>
            <Permission options={options.adminPermissions || []} />
          </form>
        );
      }}
    </Formik>
  );
}

export default enhancer(AdminForm);
