import * as Yup from "yup";

const ConsentSchema = Yup.object().shape({
  name: Yup.string().required("กรุณากรอกข้อมูล"),
  status: Yup.string().required("กรุณาเลือก"),
  authorName: Yup.string().required("กรุณากรอกข้อมูล"),
  authorImageId: Yup.string().required("กรุณากรอกข้อมูล"),
  bannerImageId: Yup.string().required("กรุณากรอกข้อมูล"),
  article: Yup.string().required("กรุณากรอกข้อมูล"),
});

export default ConsentSchema;
