import Logo from "assets/images/friday_biz_backoffice.png";
import styles from "./Header.module.css";
import enhancer from "./Header.compose";
import DEFAULT_AVATAR from "assets/images/avatar_agent.png";
import { Fragment } from "react";
// import Icon from "components/Icon";

const Dropdown = ({
  data,
  personal,
  children,
  isNotice = false,
  setIsNotice,
}) => {
  return (
    <div className={styles.avatar}>
      {/* <div
        tabindex="0"
        className={styles.notification}
        onClick={() => {
          console.log("test... notice.");
          setIsNotice(!isNotice);
        }}
        // onBlur={() => {
        //   console.log("test... notice. 2");
        //   setIsNotice(false);
        // }}
      >
        <span />
        <Icon name="notice" className={styles.icon} />
        {isNotice && (
          <div className={styles.info}>
            <p>การแจ้งเตือน</p>
            <div>
              <p>ใหม่</p>
              <ul>
                <li
                  onClick={() => {
                    console.log("test... notice. 3");
                  }}
                >
                  <div className={styles.detail}>
                    <Icon name="info-noti" />
                    <div>
                      <p className={styles.superText}>
                        <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล
                      </p>
                      <p>
                        ได้ยื่นสมัคร <b>นายหน้าประกันชีวิต</b>
                        <br />
                        โปรดตรวจสอบข้อมูลของผู้สมัคร
                      </p>
                    </div>
                  </div>
                  <div className={styles.time}>
                    <small>5 นาทีก่อน</small>
                  </div>
                </li>
                <li
                  onClick={() => {
                    console.log("test... notice. 4");
                  }}
                >
                  <div className={styles.detail}>
                    <Icon name="info-noti" />
                    <div>
                      <p className={styles.superText}>
                        <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล ณ อยุธยา
                      </p>
                      <p>
                        ได้ยื่นสมัคร <b>นายหน้าประกันชีวิต</b>
                        <br />
                        โปรดตรวจสอบข้อมูลของผู้สมัคร
                      </p>
                    </div>
                  </div>
                  <div className={styles.time}>
                    <small>5 นาทีก่อน</small>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <p>ก่อนหน้านี้</p>
              <ul>
                <li>
                  <div className={styles.detail}>
                    <Icon name="info-noti" />
                    <div>
                      <p className={styles.superText}>
                        <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล
                      </p>
                      <p>
                        ได้ยื่นสมัคร <b>นายหน้าประกันชีวิต</b>
                        <br />
                        โปรดตรวจสอบข้อมูลของผู้สมัคร
                      </p>
                    </div>
                  </div>
                  <div className={styles.time}>
                    <small>5 นาทีก่อน</small>
                  </div>
                </li>
                <li>
                  <div className={styles.detail}>
                    <Icon name="info-noti" />
                    <div>
                      <p className={styles.superText}>
                        <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล
                      </p>
                      <p>
                        มีการอัปโหลดเอกสารเพิ่มเติม
                        <br />
                        โปรดตรวจสอบข้อมูลของผู้สมัคร
                      </p>
                    </div>
                  </div>
                  <div className={styles.time}>
                    <small>5 นาทีก่อน</small>
                  </div>
                </li>
              </ul>
            </div>
            <p>
              <a href="/notification">ดูทั้งหมด</a>
            </p>
          </div>
        )}
      </div> */}
      <div className={styles.adminName}>
        <img
          className={styles.user}
          src={data.authorImagePath || DEFAULT_AVATAR}
          alt="News avatar"
        />
        <label>{data.adminName}</label>
        <div className={styles.dropdown}>
          <div className={styles.layout}>
            <div className={styles.chevronTop} />
            <div className={styles.personal}>{personal}</div>
            <ul className={styles.content}>{children}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

function Header({ data, onLogout, isNotice, setIsNotice }) {
  return (
    <header id="header" className={styles.container}>
      <div className={styles.wrapper}>
        <img className={styles.logo} src={Logo} alt="TQM agent portal logo" />
        {data && data?.adminType && (
          <Dropdown
            data={data}
            personal={
              <Fragment>
                <small className={styles.potition}>
                  <div>ตำแหน่ง : {data.adminTypeTh}</div>
                  <span className={styles.adminCode}>{data.adminCode}</span>
                </small>
                <small>สถานะ : {data.statusTh}</small>
              </Fragment>
            }
            isNotice={isNotice}
            setIsNotice={setIsNotice}
          >
            {/* <li>ดูประวัติย้อนหลัง</li> */}
            <li onClick={onLogout}>
              <b>ออกจากระบบ</b>
            </li>
          </Dropdown>
        )}
      </div>
    </header>
  );
}

export default enhancer(Header);
