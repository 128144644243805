import cx from "classnames";
import ReactModal from "react-modal";

import Icon from "components/Icon";
import styles from "./Modal.module.css";

ReactModal.setAppElement("#root");

function Modal({
  id = "modal",
  isOpen = false,
  className = "",
  overlayClassName = "",
  children,
  submitText = "ยืนยัน",
  cancelText = "ยกเลิก",
  onClose = null,
  onSubmit = () => {},
  onCancel = () => {},
  contentLabel = "",
}) {
  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      className={cx(styles.modal, className)}
      overlayClassName={cx(styles.overlay, overlayClassName)}
      contentLabel={contentLabel}
      closeTimeoutMS={300}
    >
      <section className={styles.container}>
        <div onClick={onClose || onCancel}>
          <Icon name="close" className={styles.close} />
        </div>
        <div className={styles.children}>{children}</div>
      </section>
      <div className={styles.buttonContainer}>
        <button type="button" onClick={onSubmit}>
          {submitText}
        </button>
        <button
          type="button"
          className={cx("secondary", styles.cancelButton)}
          onClick={onCancel}
        >
          {cancelText}
        </button>
      </div>
    </ReactModal>
  );
}

export default Modal;
