const DEFAULT_VALUES = {
  adminCode: "",
  prefixName: "",
  firstName: "",
  lastName: "",
  prefixNameEn: "",
  firstNameEn: "",
  lastNameEn: "",
  status: "",
  phoneNumber: "",
  email: "",
  adminType: "",
  activatedAt: null,
  areaCode: "",
  branchCode: "",
  permission: {
    candidateManagement: [],
    affiliateTeamManagement: [],
    affiliateLeadManagement: [],
    announcement: [],
    adminManagement: [],
    consentPolicyManagement: [],
    dashboard: [],
    commissionReport: [],
  },
  picked: "One",
};

export { DEFAULT_VALUES };
