import axios from "axios";
import { camelizeKeys, decamelize, decamelizeKeys } from "humps";
import isDate from "date-fns/isDate";
import jsonToFormData from "lib/jsonToFormData";
import session from "utils/session";
import { DATE_VARIABLE } from "utils/constants";

const setAppError = (e, message) => {
  alert(message);
  return Promise.reject({ ...e, message });
};

const modifyValues = (input) => {
  if (input instanceof Object) {
    return Object.entries(input).reduce((prev, [key, value]) => {
      if (value instanceof Array) {
        return { ...prev, [key]: value.map((v) => modifyValues(v)) };
      } else if (value instanceof Object) {
        return { ...prev, [key]: modifyValues(value) };
      } else {
        if (DATE_VARIABLE.includes(key)) {
          const toDate = new Date(value);
          if (isDate(toDate) && !!toDate.valueOf())
            return { ...prev, [key]: toDate };
        }
      }
      return { ...prev, [key]: value };
    }, {});
  }

  return input;
};

class Api {
  constructor() {
    this.http = axios.create({
      baseURL: `${process.env.REACT_APP_AGENT_TOOLS_API_HOST}/api/backend/v1`,
      timeout: 30000,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.http.interceptors.request.use(
      async (config) => ({
        ...config,
        headers: this.prepareRequestHeaders(config.headers),
        params: this.prepareRequestParams(
          config.params,
          config.isFormData || false
        ),
        data: this.prepareRequestParams(
          config.data,
          config.isFormData || false
        ),
      }),
      null || undefined
    );

    this.http.interceptors.response.use(
      (response) => this.handleSuccess(response),
      (error) => this.handleError(error)
    );
  }

  prepareRequestHeaders(headers) {
    return {
      ...headers,
      "Agent-Tools-Auth-Token": localStorage.getItem("authToken"),
    };
  }
  prepareRequestParams = (params, isFormData) => {
    if (!params) return;
    if (isFormData)
      return jsonToFormData(params, {
        transfromPropName: (key) => decamelize(key),
        showLeafArrayIndexes: false,
      });

    return decamelizeKeys(params);
  };

  prepareResponseData(data) {
    return modifyValues(camelizeKeys(data));
  }

  handleSuccess(response) {
    if (response.headers["content-type"] === "application/octet-stream") {
      return response;
    } else {
      return { ...response, data: this.prepareResponseData(response.data) };
    }
  }

  onUnauthorized(e) {
    session.removeAuthToken();
    session.removeAuthTqmLifeToken();
    const { response } = e;
    return setAppError(e, response?.data.message);
  }

  onNotFound(e) {
    const { response } = e;
    return setAppError(e, response?.data.message);
  }

  onBadRequest(e) {
    const { response } = e;
    return setAppError(e, response?.data.message);
  }

  onValidateFailed(e) {
    const { response } = e;
    if (response?.data?.errors && Array.isArray(response?.data?.errors)) {
      (response?.data?.errors || []).forEach((error) => alert(error.message));
      let messages = JSON.stringify(response?.data.errors, null, 2);
      return Promise.reject({ ...e, messages });
    }
    return setAppError(
      e,
      JSON.stringify(response?.data.message || response?.data.errors, null, 2)
    );
  }

  onServerError(e) {
    return setAppError(e, "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
  }

  onRequestTimeout(e) {
    return setAppError(e, "INTERNET ล่าช้า กรุณาลองใหม่อีกครั้ง");
  }

  onNetworkError(e) {
    return setAppError(
      e,
      "การเชื่อมต่อกับเซิฟเวอร์มีปัญหา กรุณาลองใหม่อีกครั้งภายหลัง"
    );
  }

  handleError(e) {
    const { response } = e;
    if (response) {
      const { code } = response.data;
      if (code === "unauthorized") return this.onUnauthorized(e);
      if (code === "not_found") return this.onNotFound(e);
      if (code === "server_error") return this.onServerError(e);
      if (code === "bad_request") return this.onBadRequest(e);
      if (code === "validate_failed") return this.onValidateFailed(e);
      const error = { ...e, response: this.prepareResponseData(response) };

      return Promise.reject(error);
    } else {
      if (/timeout/.test(e.message)) return this.onRequestTimeout(e);
      if (/Network/.test(e.message)) return this.onNetworkError(e);

      return Promise.reject(e);
    }
  }

  get(path, params = {}, options = {}) {
    return this.http.get(path, { params, ...options });
  }

  post(path, params, options = {}) {
    return this.http.post(path, params, options);
  }

  put(path, params, options = {}) {
    return this.http.put(path, params, options);
  }

  patch(path, params, options = {}) {
    return this.http.patch(path, params, options);
  }

  delete(path, params) {
    return this.http.delete(path, { params });
  }
}

export default new Api();
