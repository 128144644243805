import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getAffiliateById,
  getAffiliateOptions,
  sendEmailAgain,
} from "store/affiliateSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const dispatch = useDispatch();
    const { affiliate, options } = useSelector((state) => state.affiliate);
    const sendEmail = (values) => {
      dispatch(sendEmailAgain(id, values));
    };

    useEffect(() => {
      dispatch(getAffiliateOptions());
    }, [dispatch]);

    useEffect(() => {
      dispatch(getAffiliateById(id));
    }, [dispatch, id]);
    return { id, data: affiliate, options, sendEmail };
  }),
  mapProps(({ id, data, options, sendEmail, disabled = false }) => ({
    id,
    data,
    options,
    sendEmail,
    disabled,
  }))
);

export default enhancer;
