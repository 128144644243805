import { createSlice } from "@reduxjs/toolkit";
import api from "api";

// const MOCKUP_HISTORY = [
//   {
//     id: parseInt(Math.random() * 100, 10),
//     name: "ข้อตกลงและเงื่อนไขในการลงทะเบียน",
//     agreementType: "ข้อตกลงและเงื่อนไข",
//     contentDisplay: "หน้าลงทะเบียน",
//     version: "1.2",
//     consentedAt: new Date(),
//   },
//   {
//     id: parseInt(Math.random() * 100, 10),
//     name: "การขอคำยินยอมในเก็บข้อมูลส่วนตัว",
//     agreementType: "การจัดการข้อมูลส่วนบุคคล",
//     contentDisplay: "หน้าสมัครผู้แนะนำ",
//     version: "1.2",
//     consentedAt: new Date(),
//   },
//   {
//     id: parseInt(Math.random() * 100, 10),
//     name: "ข้อตกลงและเงื่อนไขในการลงทะเบียน",
//     agreementType: "ข้อตกลงและเงื่อนไข",
//     contentDisplay: "หน้าลงทะเบียน",
//     version: "1.0",
//     consentedAt: new Date(),
//   },
//   {
//     id: parseInt(Math.random() * 100, 10),
//     name: "การขอคำยินยอมในเก็บข้อมูลส่วนตัว",
//     agreementType: "การจัดการข้อมูลส่วนบุคคล",
//     contentDisplay: "หน้าสมัครผู้แนะนำ",
//     version: "1.0",
//     consentedAt: new Date(),
//   },
// ];

const affiliateInitialState = {
  isLoading: false,
  affiliates: [],
  paging: {
    page: 1,
    perPage: 10,
  },
  affiliate: {},
  options: {
    affiliateStatusOptions: [],
    marketPlanOptions: [],
    uplineOptions: [],
  },
  histories: [],
};

const affiliateReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setAffiliates(state, action) {
    state.affiliates = action.payload.affiliates;
    state.paging = action.payload.paging;
  },
  setAffiliate(state, action) {
    state.affiliate = action.payload;
  },
  setOptions(state, action) {
    state.options.affiliateStatusOptions =
      action.payload.affiliateStatusOptions;
    state.options.marketPlanOptions = action.payload.marketPlanOptions;
    state.options.uplineOptions = action.payload.uplineOptions;
  },
  setHistories(state, action) {
    state.histories = action.payload.consentHistories;
  },
};

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState: affiliateInitialState,
  reducers: affiliateReducers,
});

export const {
  setLoading,
  setAffiliates,
  setAffiliate,
  setOptions,
  setHistories,
} = affiliateSlice.actions;

export default affiliateSlice.reducer;

export const getAffiliates = (values) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/affiliates", values);
    dispatch(setAffiliates(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAffiliateById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/affiliates/${id}`);
    let data = response.data.data.affiliate;
    let modifiedAffiliateCommissionPlans = data.marketPlan;
    modifiedAffiliateCommissionPlans.map(
      (modifiedAffiliateCommissionPlan, index) => {
        return (modifiedAffiliateCommissionPlans[index].beginDate = new Date(
          modifiedAffiliateCommissionPlan.beginDate
        ));
      }
    );
    let modifiedData = {
      ...data,
      affiliateCommissionPlans: modifiedAffiliateCommissionPlans,
    };
    dispatch(setAffiliate(modifiedData));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAffiliateOptions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/affiliates/options");
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getHistoriesById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response = await api.get(`/affiliates/${id}/consent_histories`);
    dispatch(setHistories(response.data.data));
    // dispatch(setHistories({ consentHistories: MOCKUP_HISTORY }));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const editAffiliateById = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/affiliates/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const sendEmailAgain = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.post(`/affiliates/${id}/send_email_notification`, values); // email_type? "notification" : "update_status"
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
