import React from "react";
import cx from "classnames";
import { Formik } from "formik";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";

import styles from "./FilterBar.module.css";

const initialValues = {
  firstName: "",
  lastName: "",
  agentStatus: "",
  responsiblePersonId: null,
  interviewerId: null,
  agentType: "",
  orderBy: "",
  order: "desc",
  page: 1,
  perPage: 10,
};

function FilterBar({
  isOpen,
  options = { affiliateStatusOptions: [] },
  onChange = () => {},
}) {
  return (
    <Formik initialValues={initialValues}>
      {(props) => {
        const { resetForm } = props;
        return (
          <form className={cx(styles.filterWrapper, !isOpen ? "hidden" : null)}>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="firstName">ชื่อ</label>
              <Input
                name="firstName"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ firstName: value })}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="lastName">นามสกุล</label>
              <Input
                name="lastName"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ lastName: value })}
              />
            </div>
            <div className={cx(styles.filterInputWrapper, styles.statusInput)}>
              <label htmlFor="agentStatus">สถานะ</label>
              <Selector
                width="100%"
                name="agentStatus"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ agentStatus: value })}
                options={options.agentStatusOptions}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="responsiblePersonId">ผู้รับผิดชอบ</label>
              <Selector
                width="100%"
                name="responsiblePersonId"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ responsiblePersonId: value })}
                options={options.responsiblePersonOptions}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="responsiblePersonId">ผู้สัมภาษณ์</label>
              <Selector
                width="100%"
                name="interviewerId"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ interviewerId: value })}
                options={options.interviewer}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="agentType">ตำแหน่งปัจจุบัน</label>
              <Selector
                width="100%"
                name="agentType"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ agentType: value })}
                options={
                  options.agentTypeOptions || [
                    { label: "aa", value: "aa" },
                    { label: "bb", value: "bb" },
                  ]
                }
              />
            </div>
            <div />
            <div />
            <div
              className={cx(styles.filterInputWrapper, styles.reset)}
              onClick={() => {
                resetForm();
                onChange(initialValues);
              }}
            >
              รีเซ็ตค่าตัวเลือก
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FilterBar;
