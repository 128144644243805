import { createSlice } from "@reduxjs/toolkit";
import api from "api";
import mime from "mime-types";
import downloadjs from "downloadjs";

const commissionInitialState = {
  isLoading: false,
  initialized: false,
  commission: {},
  paging: {},
  commissions: [],
  options: {},
};

const commissionReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setCommission(state, action) {
    state.commission = action.payload.salesCommission;
  },
  setCommissions(state, action) {
    state.commissions = action.payload.salesCommissions;
    state.paging = action.payload.paging;
  },
  setOptions(state, action) {
    state.options = action.payload;
  },
};

export const commissionSlice = createSlice({
  name: "commissionSlice",
  initialState: commissionInitialState,
  reducers: commissionReducers,
});

export const {
  setLoading,
  setCommissions,
  setCommission,
  setOptions,
} = commissionSlice.actions;

export default commissionSlice.reducer;

export const getCommissions = (params = {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/sales_commissions", params);
    dispatch(setCommissions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCommissionById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/sales_commissions/${id}`);
    dispatch(setCommission(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const exportCommission = (values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(
      "/sales_commissions/export_sales_commissions",
      values,
      {
        responseType: "blob",
      }
    );
    const { data, headers } = response;
    const filename = headers["content-disposition"]
      .match(/filename*="?(.*)"?/)[1]
      .split(";")[0];
    const fileType = mime.lookup(filename);
    downloadjs(data, decodeURI(filename), fileType);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCommissionOptions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/sales_commissions/options");
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
