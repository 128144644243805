import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getAnnouncementsById,
  editAnnouncements,
} from "store/announcementsSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { announcement } = useSelector((state) => state.announcements);
    const [isPreview, setIsPreview] = useState(false);

    useEffect(() => {
      dispatch(getAnnouncementsById(id));
    }, [dispatch, id]);

    const onSubmit = (values) => {
      delete values.bannerImagePath;
      delete values.authorImagePath;
      dispatch(
        editAnnouncements(id, values, () => {
          history.push(`/announcements/${id}`);
        })
      );
    };

    return { id, data: announcement, onSubmit, setIsPreview, isPreview };
  }),
  mapProps(({ id, data, onSubmit, setIsPreview, isPreview }) => ({
    id,
    data,
    onSubmit,
    setIsPreview,
    isPreview,
  }))
);

export default enhancer;
