import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import DisplayValue from "components/DisplayValue";
import Modal from "components/Modal";
import Plan from "./Plan";
import DEFAULT_AVATAR from "assets/images/avatar_agent.png";

import useToggle from "hooks/useToggle";
import enhancer from "./AffiliateId.compose";
import styles from "./AffiliateId.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import Icon from "components/Icon";
import authorize, { UnauthorizedAction } from "utils/authorize";

const ResendEmail = ({ sendEmail = () => {} }) => {
  const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

  return (
    <>
      <button
        className={cx("secondary", styles.btn, styles.resendBtn)}
        onClick={handleOpenModal}
      >
        <div>
          <Icon name="mail" className={styles.icon} />
          ส่งอีเมลอีกครั้ง
        </div>
      </button>
      <Modal
        isOpen={showModal}
        onSubmit={() => {
          handleCloseModal();
          let values = { emailType: "notification" };
          sendEmail(values);
        }}
        onCancel={handleCloseModal}
        submitText={"ส่งอีเมล"}
        cancelText={"ไม่ส่งอีเมล"}
        contentLabel="Resend email affiliate Modal"
      >
        <p>
          ต้องการส่งอีเมลแจ้ง<b>การเปลี่ยนแปลงสถานะ</b>
          <br />
          และ <b>แผนการตลาด</b> หรือไม่ ?
        </p>
      </Modal>
    </>
  );
};

function AffiliateId({
  id = "",
  data = {},
  options = {},
  sendEmail = () => {},
  disabled,
}) {
  return (
    <Container>
      <h4 className="topic">ผู้แนะนำ</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "จัดการผู้แนะนำ", link: "/affiliate" },
            {
              label: `${data.firstName} ${data.lastName}`,
              current: true,
            },
          ]}
        />
        <div>
          <ResendEmail sendEmail={(e) => sendEmail(e)} />
          <Link to={`/affiliate/${id}/history`} className={styles.historyLink}>
            <button className={cx("secondary", styles.btn)}>
              <div>
                <Icon name="history" className={styles.icon} />
                ประวัติการยอมรับคำยินยอม
              </div>
            </button>
          </Link>
          <Link to={`/affiliate/${id}/edit`}>
            <button className={cx(styles.editBtn)} disabled={disabled}>
              แก้ไข
            </button>
          </Link>
        </div>
      </div>
      <div className={styles.container}>
        <img
          className={styles.avatar}
          src={data.avatar || DEFAULT_AVATAR}
          alt="Affiliate avatar"
        />
        <div className={styles.info}>
          <DisplayValue
            className={styles.name}
            label="ชื่อ"
            value={`${data.firstName} ${data.lastName}`}
          />
          <DisplayValue
            className={styles.recommender}
            label="ทีมผู้แนะนำที่สังกัด"
            value={data.teamName}
          />
          <DisplayValue
            className={styles.team}
            label="ทีมหลัก"
            value={data.tqmTeamName}
          />
          <DisplayValue
            className={styles.state}
            label="สถานะ"
            value={data.statusTh}
          />
          <DisplayValue
            className={styles.note}
            label="หมายเหตุ"
            value={data.description}
          />
          <DisplayValue
            className={styles.updater}
            label="ผู้อัปเดตล่าสุด"
            value={data.lastUpdatedBy}
          />
        </div>
      </div>
      <Plan
        data={data.marketPlan}
        nameThInfo={{
          firstName: data?.firstName || "",
          lastName: data?.lastName || "",
        }}
        options={options}
      />
    </Container>
  );
}

export default authorize(enhancer(AffiliateId), {
  roles: "agent",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["edit_line_affiliate"],
});
