import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";

import enhancer from "./EditMarketing.compose";
import styles from "./EditMarketing.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import MarketingForm from "components/Form/MarketingForm";
import Modal from "components/Modal";

import useToggle from "hooks/useToggle";
import authorize, { UnauthorizedAction } from "utils/authorize";

const DeleteMarket = ({ onDelete }) => {
  const [isModalOpened, , openModal, closeModal] = useToggle();

  return (
    <>
      <button
        type="button"
        className={`secondary ${styles.btn} ${styles.editBtn}`}
        onClick={openModal}
      >
        ลบแผน
      </button>
      <Modal
        contentLabel="Delete market."
        isOpen={isModalOpened}
        onSubmit={onDelete}
        onCancel={closeModal}
      >
        ต้องการลบแผนการตลาดใช่หรือไม่
        <br />
        (ไม่สามารถกู้คืนข้อมูลได้)
      </Modal>
    </>
  );
};

function EditMarketing({
  data = {},
  isLoading,
  onDelete = () => {},
  onSubmit = () => {},
  lengthStructure,
  setLengthStructure,
}) {
  if (data.id) {
    return (
      <Container>
        <h4 className="topic">แผนการตลาด</h4>
        <div className={containerStyles.breadcrumbWithButton}>
          <Breadcrumb
            links={[
              { label: "จัดการแผนการตลาดทีมผู้แนะนำ", link: "/marketing_plan" },
              {
                label: data?.name || "แผนการตลาด",
                link: `/marketing_plan/${data.id}`,
                current: true,
              },
            ]}
          />
          <div>
            <DeleteMarket onDelete={onDelete} />
            <button
              className={styles.btn}
              form="editMarketing"
              type="submit"
              disabled={isLoading || !lengthStructure}
            >
              บันทึก
            </button>
          </div>
        </div>
        <MarketingForm
          id="editMarketing"
          initialValues={data}
          onSubmit={onSubmit}
          setLengthStructure={setLengthStructure}
        />
      </Container>
    );
  }

  return null;
}

export default authorize(enhancer(EditMarketing), {
  roles: "mkPlan",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["crud"],
});
