import React from "react";
import cx from "classnames";

import containerStyles from "./Container.module.css";

function Container({ className = "", children }) {
  return (
    <section className={cx(containerStyles.container, className)}>
      {children}
    </section>
  );
}

export default Container;
