import { useMemo } from "react";
import { Link } from "react-router-dom";
import Icon from "components/Icon";

import Table from "components/Table";
import { Selector as Select } from "components/Form/Element/Selector/Selector";

import { dateFormatterFromPattern } from "utils/formatter";
import styles from "./AgentsTable.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

const ResponsibleSelect = authorize(
  ({ options, changeResponsible, disabled, cell }) => (
    <Select
      value={options.responsiblePersonOptions.find(
        (select) => select.value === cell.row.original.responsiblePersonId
      )}
      width="220px"
      options={options.responsiblePersonOptions}
      onChange={(e) => {
        changeResponsible(cell.row.original.id, e.value);
      }}
      placeholder="เลือก"
      isDisabled={disabled}
    />
  ),
  {
    roles: "agent",
    params: { unauthorizedAction: UnauthorizedAction.DISABLED },
    actions: ["assign_responsible"],
  }
);

const InterviewerSelect = authorize(
  ({ options, changeInterviewer, disabled, cell }) => (
    <Select
      value={options.interviewerOptions.find(
        (select) => select.value === cell.row.original.interviewerId
      )}
      width="220px"
      options={options.interviewerOptions}
      onChange={(e) => {
        changeInterviewer(cell.row.original.id, e.value);
      }}
      placeholder="เลือก"
      isDisabled={disabled}
    />
  ),
  {
    roles: "agent",
    params: { unauthorizedAction: UnauthorizedAction.DISABLED },
    actions: ["assign_interviewer"],
  }
);

function AgentsTable({
  data = [],
  paging = {},
  options = {},
  searchValue = {},
  onChange = () => {},
  changeInterviewer = () => {},
  changeResponsible = () => {},
  displayStatusAgent = () => {},
}) {
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "icon",
        styles: {
          header: {
            width: "60px",
          },
        },
        Cell: (cell) => {
          return (
            <Link
              className={styles.icon_link}
              to={`/agents/${cell.row.original.id}`}
            >
              <Icon name="document" />
            </Link>
          );
        },
      },
      {
        Header: "ชื่อ",
        accessor: "firstName",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "นามสกุล",
        accessor: "lastName",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "ผู้รับผิดชอบ",
        accessor: "responsiblePersonId",
        styles: {
          header: {
            width: "240px",
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <ResponsibleSelect
              options={options}
              changeResponsible={changeResponsible}
              cell={cell}
            />
          );
        },
      },
      {
        Header: "ผู้สัมภาษณ์",
        accessor: "interviewerId",
        styles: {
          header: {
            width: "240px",
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <InterviewerSelect
              options={options}
              changeInterviewer={changeInterviewer}
              cell={cell}
            />
          );
        },
      },
      {
        Header: "สถานะการสมัคร",
        accessor: "agentStatus",
        styles: {
          header: {
            width: "240px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {displayStatusAgent(cell.row.original.agentStatus) || "-"}
            </div>
          );
        },
      },
      {
        Header: "ตำแหน่งที่สมัคร",
        accessor: "recruitment",
        styles: {
          header: {
            width: "240px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return <>{cell.row.original.recruitment || "-"}</>;
        },
      },
      {
        Header: "วันสมัคร",
        accessor: "recruitedAt",
        styles: {
          header: {
            width: "150px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {dateFormatterFromPattern(cell.row.original.recruitedAt) || "-"}
            </div>
          );
        },
      },
      {
        Header: "อัปเดตล่าสุด",
        accessor: "updatedAt",
        styles: {
          header: {
            width: "150px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {dateFormatterFromPattern(cell.row.original.updatedAt) || "-"}
            </div>
          );
        },
      },
      {
        Header: "พื้นที่",
        accessor: "area",
        styles: {
          header: {
            width: "150px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return <div>{cell.row.original.area || "-"}</div>;
        },
      },
      {
        Header: "สาขา",
        accessor: "branchName",
        styles: {
          header: {
            width: "150px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return <div>{cell.row.original.branchName || "-"}</div>;
        },
      },
      {
        Header: "หมายเหตุ",
        accessor: "registerNote",
        styles: {
          header: {
            width: "200px",
          },
        },
        Cell: (cell) => {
          return <div>{cell.row.original.registerNote || "-"}</div>;
        },
      },
      {
        Header: "ตำแหน่งปัจจุบัน",
        accessor: "agentType",
        styles: {
          header: {
            width: "200px",
            textAlign: "left",
          },
          cell: {
            textAlign: "left",
          },
        },
        Cell: (cell) => {
          return <div>{cell.row.original?.agentType || "-"}</div>;
        },
      },
      {
        Header: "สมัครใหม่ได้",
        accessor: "nextRecruitmentedAt",
        styles: {
          header: {
            width: "150px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {dateFormatterFromPattern(
                cell.row.original.nextRecruitmentedAt
              ) || "-"}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, options, changeInterviewer, changeResponsible, displayStatusAgent]
  );

  if (options?.agentStatusOptions.length > 0)
    return (
      <section className={styles.container}>
        <Table
          data={data}
          columns={columns}
          paging={paging}
          searchValue={searchValue}
          onChange={onChange}
        />
      </section>
    );
  return null;
}

export default AgentsTable;
