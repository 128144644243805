const initialValues = {
  agreementType: "",
  consentBy: "",
  contentDisplay: "",
  status: "",
  version: "",
  orderBy: "",
  order: "desc",
  page: 1,
  perPage: 10,
};

export default initialValues;
