import api from "api";

const createAttachment = async (file, cb = () => {}, clear = () => {}) => {
  try {
    const response = await api.post(
      "/attachments",
      { image: file },
      { isFormData: true }
    );
    cb(response.data.data.attachment);
  } catch (err) {
    if (err && err.response) {
      console.error(err.response);
    }
    alert("เกิดข้อผิดพลาดบางอย่าง");
    clear();
    throw err;
  }
};

const getFileType = (fileType) => {
  let type = "";
  switch (fileType) {
    case "image/jpeg":
    case "jpeg":
    case "jpg":
      type = "JPG";
      break;
    case "image/png":
    case "png":
    case "PNG":
      type = "PNG";
      break;
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      type = "DOC";
      break;
    case "application/pdf":
      type = "PDF";
      break;
    case "pdf":
      type = "PDF";
      break;
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      type = "PPT";
      break;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      type = "XLX";
      break;
    case "text/plain":
      type = "TXT";
      break;
    default:
      break;
  }
  return type;
};

export { createAttachment, getFileType };
