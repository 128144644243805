const DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  teamName: "",
  tqmTeamId: "",
  state: "",
  description: "",
  affiliateCommissionPlans: [],
  emailType: "",
};

export { DEFAULT_VALUES };
