import { configureStore } from "@reduxjs/toolkit";

import roorReducer from "./rootReducer";

export default configureStore({
  reducer: roorReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
