import LoginForm from "components/Form/LoginForm";
import cx from "classnames";

import enhancer from "./Login.compose";
import styles from "./Login.module.css";

function Login({ onSubmit, errorMessage }) {
  return (
    <section className={styles.container}>
      <div
        className={
          errorMessage ? cx(styles.errorBar, styles.active) : styles.errorBar
        }
      >
        {errorMessage}
      </div>
      <LoginForm onSubmit={onSubmit} />
    </section>
  );
}

export default enhancer(Login);
