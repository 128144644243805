import { forwardRef, useRef, useEffect } from "react";
import {
  faAngleLeft,
  faAngleRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTable, useRowSelect } from "react-table";
import TableStyles from "./Table.module.css";
import cx from "classnames";

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className={TableStyles.checkBoxColumn}>
      <input type="checkbox" ref={resolvedRef} {...rest} />
      <div className={TableStyles.customInput}>
        <FontAwesomeIcon icon={faCheck} className={TableStyles.checkIcon} />
      </div>
    </div>
  );
});

function Table(props) {
  const {
    data,
    columns,
    className = "",
    paging = { page: 1, totalPages: 1 },
    searchValue = {},
    pagination = true,
    onChange = () => {},
    setArrayExcel,
    arrayExcel,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: data.reduce(
          (prev, curr, currIndex) => ({ ...prev, [currIndex]: true }),
          {}
        ),
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          styles: {
            header: {
              width: "60px",
              textAlign: "center",
            },
            cell: {
              width: "60px",
              textAlign: "center",
            },
          },
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  const { page, totalPages } = paging;

  const generateSortingIndicator = (columnId, searchValue) => {
    if (columnId !== searchValue.orderBy) return null;
    return searchValue.order === "desc" ? "^" : "v";
  };

  useEffect(() => {
    if (selectedFlatRows) {
      setArrayExcel(selectedFlatRows.map((d) => d.original));
    }
  }, [setArrayExcel, selectedFlatRows]);

  return (
    <>
      <section className={cx(TableStyles.container, className)}>
        <div className={TableStyles.countArray}>
          <label>{arrayExcel.length} รายการที่เลือก</label>
        </div>
        <table className={TableStyles.table} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        style: column.styles?.header,
                      },
                    ])}
                  >
                    {column.render("Header")}
                    <span style={{ float: "right" }}>
                      {generateSortingIndicator(column.id, searchValue)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          { style: cell.column.styles?.cell },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      {pagination && (
        <section className={TableStyles.pagination}>
          <span className={TableStyles.input_wrapper}>
            หน้า
            <input
              type="number"
              value={page}
              onChange={(e) => {
                onChange({ page: e.target.value });
              }}
            />
            จาก {totalPages}
          </span>
          <button
            className={TableStyles.btn_pagination}
            onClick={() => {
              onChange({ page: page - 1 });
            }}
            disabled={page <= 1}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            className={TableStyles.btn_pagination}
            onClick={() => {
              onChange({ page: page + 1 });
            }}
            disabled={page >= totalPages}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </section>
      )}
    </>
  );
}

export default Table;
