import * as Yup from "yup";

const AdminSchema = Yup.object().shape({
  adminCode: Yup.string().required("กรุณากรอกข้อมูล"),
  status: Yup.string().required("กรุณาเลือก"),
  prefixName: Yup.string().required("กรุณากรอกข้อมูล"),
  firstName: Yup.string().required("กรุณากรอกข้อมูล"),
  lastName: Yup.string().required("กรุณากรอกข้อมูล"),
  prefixNameEn: Yup.string().required("กรุณากรอกข้อมูล"),
  firstNameEn: Yup.string().required("กรุณากรอกข้อมูล"),
  lastNameEn: Yup.string().required("กรุณากรอกข้อมูล"),
  phoneNumber: Yup.string().required("กรุณากรอกข้อมูล"),
  email: Yup.string().required("กรุณากรอกข้อมูล"),
  adminType: Yup.string().required("กรุณากรอกข้อมูล"),
  activatedAt: Yup.date().required("กรุณากรอกข้อมูล").nullable(),
  areaCode: Yup.string()
    .nullable()
    .when(["adminType"], {
      is: (adminType) => adminType === "area_admin",
      then: Yup.string().required("กรุณาเลือก").nullable(),
    }),
  branchCode: Yup.string()
    .nullable()
    .when(["adminType"], {
      is: (adminType) => adminType === "branch_admin",
      then: Yup.string().required("กรุณาเลือก").nullable(),
    }),
});

export default AdminSchema;
