import { useField } from "formik";
import SunEditor from "suneditor-react";
import merge from "lodash/merge";
import cx from "classnames";

import inputStyles from "components/Form/Element/Input/Input.module.css";
import "suneditor/dist/css/suneditor.min.css";

const DEFAULT_CONFIG = {
  width: "100%",
  height: "300",
  buttonList: [
    [
      "formatBlock",
      "bold",
      "italic",
      "underline",
      "link",
      "align",
      "list",
      "outdent",
      "indent",
    ],
  ],
  pasteTagsBlacklist:'span|ul|li',
};

function Editor({
  className = "",
  name = "",
  label = "",
  placeholder = "",
  disable = false,
  required = false,
  config: userConfig,
  onChange = () => {},
}) {
  const mergedConfig = merge({}, DEFAULT_CONFIG, userConfig);
  const [, meta, helpers] = useField(name);
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = (content) => {
    setValue(content);
    if (onChange) onChange(content);
  };

  return (
    <div className={cx(inputStyles.container, className)}>
      {label && (
        <label className={cx("label", inputStyles.label)} htmlFor={name}>
          {label}
          {required && <span className="error">*</span>}
        </label>
      )}
      <SunEditor
        name={name}
        placeholder={placeholder}
        setOptions={mergedConfig}
        disable={disable}
        onChange={handleChange}
        setContents={value}
        setDefaultStyle="font-family: NotoSansThai, NotoSans, sans-serif; font-size: 17px;"
      />
      {touched && error && (
        <span className={cx("error", "small", inputStyles.error)}>{error}</span>
      )}
    </div>
  );
}

export default Editor;
