import { useMemo } from "react";
import cx from "classnames";

import Table from "components/Table";

import { dateFormatterFromPattern } from "utils/formatter";
import styles from "./AdminTable.module.css";
import { Link } from "react-router-dom";

function AdminTable({
  className = "",
  data = [],
  paging = {},
  searchValue = {},
  onChange = () => {},
}) {
  const columns = useMemo(() => {
    return [
      {
        Header: "รหัสพนักงาน",
        accessor: "adminCode",
        styles: {
          header: {
            width: "150px",
          },
        },
        Cell: (cell) => {
          return (
            <Link className={styles.link} to={`/admin/${cell.row.original.id}`}>
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "ชื่อผู้ดูแล",
        accessor: "name",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "ตำแหน่ง",
        accessor: "adminType",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "พื้นที่ดูแล",
        accessor: "areaCode",
        styles: {
          header: {
            width: "160px",
          },
        },
        Cell: (cell) => cell.value || "-",
      },
      {
        Header: "สาขาดูแล",
        accessor: "branchCode",
        styles: {
          header: {
            width: "160px",
          },
        },
        Cell: (cell) => cell.value || "-",
      },
      {
        Header: "วันที่สร้าง",
        accessor: "createdAt",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => dateFormatterFromPattern(cell.value) || "-",
      },
      {
        Header: "สถานะ",
        accessor: "status",
        styles: {
          header: {
            width: "100px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
      },
      {
        Header: "วันที่อัปเดตข้อมูล",
        accessor: "updatedAt",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => dateFormatterFromPattern(cell.value) || "-",
      },
    ];
  }, []);
  return (
    <section className={cx(styles.container, className)}>
      <Table
        columns={columns}
        data={data}
        paging={paging}
        searchValue={searchValue}
        onChange={onChange}
      />
    </section>
  );
}

export default AdminTable;
