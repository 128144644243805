import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getMarketingById,
  editMarketing,
  deleteMarketing,
  deleteStructure,
} from "store/marketingSlice";

const enhancer = compose(
  withHooks((props) => {
    const { id = "" } = useParams();
    const history = useHistory();
    const [lengthStructure, setLengthStructure] = useState(false);
    const dispatch = useDispatch();
    const { marketPlan = {}, isLoading, options } = useSelector(
      (state) => state.marketing
    );

    useEffect(() => {
      dispatch(getMarketingById(id));
    }, [dispatch, id]);

    const onSubmit = (values) => {
      dispatch(
        editMarketing(id, values, () => {
          history.push(`/marketing_plan/${id}`);
        })
      );
    };

    const onDelete = () => {
      dispatch(
        deleteMarketing(id, () => {
          history.push("/marketing_plan");
        })
      );
    };

    const onDeleteStructure = (values) => {
      dispatch(
        deleteStructure(id, values, () => {
          history.push(`/marketing_plan/${id}`);
        })
      );
    };

    return {
      isLoading,
      data: marketPlan,
      onSubmit,
      onDelete,
      onDeleteStructure,
      options,
      lengthStructure,
      setLengthStructure,
    };
  }),
  mapProps(
    ({
      isLoading,
      data,
      onSubmit,
      onDelete,
      onDeleteStructure,
      options,
      lengthStructure,
      setLengthStructure,
    }) => ({
      isLoading,
      data,
      onSubmit,
      onDelete,
      onDeleteStructure,
      options,
      lengthStructure,
      setLengthStructure,
    })
  )
);

export default enhancer;
