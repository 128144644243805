import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useNavigationBar = () => {
  const [hasNavigation, setNavigation] = useState({
    hasHeader: true,
    hasFooter: true,
    hasMenu: true,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case "/login":
      case "/forget_password":
      case "/reset_password":
      case "/setup_password":
        setNavigation({
          hasHeader: false,
          hasFooter: false,
          hasMenu: false,
        });
        break;

      default:
        setNavigation({
          hasHeader: true,
          hasFooter: true,
          hasMenu: true,
        });
        break;
    }
  }, [pathname]);

  return {
    hasHeader: hasNavigation.hasHeader,
    hasFooter: hasNavigation.hasFooter,
    hasMenu: hasNavigation.hasMenu,
  };
};

export default useNavigationBar;
