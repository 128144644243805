import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import cx from "classnames";

import SetupPasswordForm from "components/Form/SetupPasswordForm";

import { setupPassword } from "store/authSlice";
import styles from "./SetupPassword.module.css";
import useQuery from "hooks/useQuery";

function SetupPassword() {
  const query = useQuery();
  const confirmationToken = query.get("confirmation_token");
  const [state, setState] = useState("setup");
  const dispatch = useDispatch();
  const onSubmit = (values, { setSubmitting }) => {
    let modifiedValues = { ...values, confirmationToken };
    dispatch(
      setupPassword(
        modifiedValues,
        () => setState("success"),
        () => setSubmitting(false)
      )
    );
  };

  if (state === "setup") {
    return (
      <section className={styles.container}>
        <SetupPasswordForm onSubmit={onSubmit} />
      </section>
    );
  }

  if (state === "success") {
    return (
      <section className={styles.container}>
        <div className={styles.form}>
          <h4 className={styles.title}>อีเมลของคุณได้รับการยืนยันแล้ว</h4>
          <div className={styles.success}>
            <b>เข้าสู่ระบบเพื่อใช้งาน</b>
          </div>
          <Link to="/login">
            <button className={cx("primary", styles.btn)} type="button">
              เข้าสู่ระบบ
            </button>
          </Link>
        </div>
      </section>
    );
  }

  return null;
}

export default SetupPassword;
