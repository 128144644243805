import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Pages from "pages";
import Layout from "components/Layout";
import { getInfo } from "store/authSlice";
import { go2Top } from "utils/helper";

function App() {
  let location = useLocation();
  const dispatch = useDispatch();
  const initialized = useSelector((state) => state.app.initialized);

  useEffect(() => {
    dispatch(getInfo());
  }, [dispatch]);

  useEffect(() => {
    go2Top();
  }, [location.pathname]);

  return (
    initialized && (
      <Layout>
        <Pages />
      </Layout>
    )
  );
}

export default App;
