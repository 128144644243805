import { createSlice } from "@reduxjs/toolkit";
import api from "api";
import mime from "mime-types";
import downloadjs from "downloadjs";

const agentInitialState = {
  isLoading: false,
  agents: [],
  paging: {
    page: 1,
    perPage: 10,
  },
  agent: {
    agentLicenses: [],
    agentDocuments: [],
    agentImportantDocuments: [],
  },
  options: {
    agentStatusOptions: [],
    interviewer: [],
    responsiblePersonOptions: [],
    insuranceCompanyNameOptions: [],
    licenseTypeOptions: [],
    documentStatusOptions: [],
  },
  histories: [],
  downloadDocument: [],
};

const agentReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setAgents(state, action) {
    state.agents = action.payload.agents;
    state.paging = action.payload.paging;
  },
  setAgent(state, action) {
    state.agent = action.payload.agent;
  },
  setOptions(state, action) {
    state.options = action.payload;
  },
  setHistories(state, action) {
    state.histories = action.payload.histories;
  },
  setRecruitHistories(state, action) {
    state.recruitHistories = action.payload.recruitHistories;
  },
  setDownloadDocument(state, action) {
    state.downloadDocument = action.payload.downloadDocument;
  },
};

export const agentSlice = createSlice({
  name: "agent",
  initialState: agentInitialState,
  reducers: agentReducers,
});

export const {
  setLoading,
  setAgents,
  setAgent,
  setOptions,
  setHistories,
  setRecruitHistories,
  setDownloadDocument,
} = agentSlice.actions;

export default agentSlice.reducer;

export const getAgents = (values) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/agents", values);
    dispatch(setAgents(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgentById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/agents/${id}`);
    dispatch(setAgent(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgentOptions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/agents/options");
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getHistoriesById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response = await api.get(`/agents/${id}/edit_histories`);
    dispatch(setHistories(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getRecruitHistoriesById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response = await api.get(`/agents/${id}/recruit_histories`);
    dispatch(setRecruitHistories(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const downloadDocument = (id = "", values = {}, name = "") => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/agents/${id}/download`, values, {
      responseType: "blob",
    });
    const { data, headers } = response;
    const filename = headers["content-disposition"].match(
      /filename="?(.*)"?/
    )[1];
    const fileType = mime.lookup(filename);
    const splitFile = decodeURI(filename).split(".");
    downloadjs(data, name + "." + splitFile[splitFile.length - 1], fileType);
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const changeInterviewerStatus = (
  id,
  interviewerId,
  cb = () => {}
) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/agents/${id}/interviewer`, {
      interviewerId: interviewerId,
    });
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const changeResponsibleStatus = (
  id,
  responsiblePersonId,
  cb = () => {}
) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/agents/${id}/responsible_person`, {
      responsiblePersonId: responsiblePersonId,
    });
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const sendEmailAgain = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.post(`/agents/${id}/send_email_notification`, values); // params? "updated" : "rejected"
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const editAgentById = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/agents/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateAgent = (id, values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/agents/${id}/update_agent_type`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
