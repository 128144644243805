import React, { useState } from "react";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import ConsentForm from "components/Form/ConsentForm";

import enhancer from "./EditConsent.compose";
import styles from "./EditConsent.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import useToggle from "hooks/useToggle";
import authorize, { UnauthorizedAction } from "utils/authorize";

function EditConsent({
  id = "",
  data = {},
  onSubmit = () => {},
  sendEmail = () => {},
}) {
  const [showModal, , , handleCloseModal] = useToggle();
  const [
    showEnforceModal,
    ,
    handleOpenEnforceModal,
    handleCloseEnforceModal,
  ] = useToggle();
  const [, setIsPassModal] = useState(false);

  return (
    <Container>
      <h4 className="topic">จัดการข้อตกลงและการยินยอม</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "จัดการข้อตกลงและการยินยอม", link: "/consent" },
            {
              label: data?.name || "สร้าง",
              link: `/consent/${id}`,
            },
            { label: `แก้ไข`, current: true },
          ]}
        />
        <button className={cx(styles.editBtn)} form="editConsent" type="submit">
          บันทึก
        </button>
      </div>
      <ConsentForm
        id="editConsent"
        initialValues={data}
        onSubmit={onSubmit}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        sendEmail={sendEmail}
        setIsPassModal={setIsPassModal}
        showEnforceModal={showEnforceModal}
        handleOpenEnforceModal={handleOpenEnforceModal}
        handleCloseEnforceModal={handleCloseEnforceModal}
      />
    </Container>
  );
}

export default authorize(enhancer(EditConsent), {
  roles: "agreement",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["modify", "add"],
});
