import { useState, useCallback } from "react";

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);
  const handleActive = useCallback(() => setState(true), []);
  const handleInActive = useCallback(() => setState(false), []);

  return [state, toggle, handleActive, handleInActive];
};

export default useToggle;
