import React from "react";
import cx from "classnames";
import { Formik } from "formik";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";

import styles from "./FilterBar.module.css";
import initialValues from "./FilterBar.utils";

function FilterBar({ isOpen, options = {}, onChange = () => {} }) {
  return (
    <Formik initialValues={initialValues}>
      {(props) => {
        const { resetForm } = props;
        return (
          <form className={cx(styles.filterWrapper, !isOpen ? "hidden" : null)}>
            <div className={styles.filterRows}>
              <div className={styles.filterInputWrapper}>
                <label htmlFor="name">ชื่อ</label>
                <Input
                  name="name"
                  className={styles.filterName}
                  placeholder="โปรดระบุ"
                  useTimeout={1500}
                  onChange={(value) => onChange({ name: value })}
                />
              </div>
              <div className={styles.filterInputWrapper}>
                <label htmlFor="status">สถานะ</label>
                <Selector
                  width="100%"
                  name="status"
                  className={styles.filterInput}
                  placeholder="โปรดระบุ"
                  onChange={(value) => onChange({ status: value })}
                  options={options.statusOptions}
                />
              </div>
            </div>
            <div className={styles.filterRows}>
              <div className={styles.filterInputWrapper}>
                <label htmlFor="updateById">ผู้อัปเดตล่าสุด</label>
                <Selector
                  width="100%"
                  name="updateById"
                  className={styles.filterUpdateBy}
                  placeholder="โปรดระบุ"
                  onChange={(value) => onChange({ updateById: value })}
                  options={options.updateByOptions}
                />
              </div>
              <div
                className={cx(styles.filterInput, styles.reset)}
                onClick={() => {
                  resetForm();
                  onChange(initialValues);
                }}
              >
                รีเซ็ตค่าตัวเลือก
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FilterBar;
