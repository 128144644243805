import { FieldArray, Formik } from "formik";
import cx from "classnames";
import merge from "lodash/merge";
import add from "date-fns/add";
import isFuture from "date-fns/isFuture";

import MarketPlansTable from "./MarketPlansTable";
import Input from "components/Form/Element/Input";
import DatePicker from "components/Form/Element/DatePicker";

import enhancer from "./MarketingForm.compose";
import validation from "./MarketingForm.validation";
import { DEFAULT_VALUES } from "./MarketingForm.utils";
import styles from "./MarketingForm.module.css";
const TOMORROW = add(new Date(), { days: 1 });

function MarketingForm({
  id = "",
  options = {},
  className = "",
  initialValues = {},
  onSubmit = () => {},
  setLengthStructure,
}) {
  return (
    <Formik
      initialValues={merge({}, DEFAULT_VALUES, initialValues)}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        const addCommissionStructures = (index) => {
          let mkCommissionRates = [];
          let member = {
            positionName: "",
            commissionPercent: 0,
            managementPercent: 0,
            reserveForLevel: null,
          };
          for (let i = 0; i < parseInt(values.numberOfLevel); i++) {
            mkCommissionRates.push({ ...member, positionLevel: i + 1 });
          }
          setFieldValue(`mkCommissionStructures.${index}.beginDate`, "");
          setFieldValue(
            `mkCommissionStructures.${index}.mkCommissionRates`,
            mkCommissionRates
          );
        };
        const disabled = (beginDate) =>
          initialValues?.id && beginDate && !isFuture(new Date(beginDate));

        if (values?.mkCommissionStructures?.length > 0)
          setLengthStructure(true);
        else setLengthStructure(false);

        return (
          <form
            id={id}
            onSubmit={handleSubmit}
            className={cx(styles.container, className)}
          >
            <div className={styles.fillContainer}>
              <Input
                label="ชื่อแผน"
                name="name"
                placeholder="กรุณากรอก"
                required
              />
              <Input
                label="จำนวนระดับ"
                name="numberOfLevel"
                placeholder="กรุณากรอก"
                required
                disabled={
                  values?.mkCommissionStructures[0]?.mkCommissionRates.length >
                  0
                }
              />
            </div>
            <div className={styles.memberContainer}>
              <button
                type="button"
                disabled={!values.numberOfLevel}
                onClick={() =>
                  addCommissionStructures(
                    values?.mkCommissionStructures?.length
                  )
                }
                className={cx("primary", styles.addMemberBtn)}
              >
                {values.mkCommissionStructures.length > 0
                  ? "เพิ่มโครงสร้าง"
                  : "สร้างตาราง"}
              </button>
            </div>
            <FieldArray
              name="mkCommissionStructures"
              render={(arrayHelpers) => {
                const onTrash = (index) => arrayHelpers.remove(index);
                const indexLastMkCommission =
                  values?.mkCommissionStructures.length - 1 || 0;
                let newMkCommissionStructures =
                  values?.mkCommissionStructures.reverse() || [];
                return newMkCommissionStructures.map(
                  (mkCommissionStructure, index) => {
                    if (
                      mkCommissionStructure.mkCommissionRates &&
                      mkCommissionStructure.mkCommissionRates.length > 0
                    )
                      return (
                        <>
                          <div className={styles.stuctureComission}>
                            <div className={styles.rows}>
                              <div className={styles.column}>
                                <b className={styles.title}>
                                  โครงสร้างค่าแนะนำ
                                </b>
                                <small>โครงสร้างสำหรับใช้ในการคำนวณ</small>
                              </div>
                            </div>
                            <div className={styles.rows}>
                              <div className={styles.column}>
                                <div className={styles.title}>ค่าแนะนำ</div>
                                <small>ระดับต่ำสุด คือ ระดับ 1</small>
                              </div>
                              <div className={styles.dateRow}>
                                <label>วันที่มีผลบังคับ</label>
                                <DatePicker
                                  name={`mkCommissionStructures[${
                                    indexLastMkCommission - index
                                  }].beginDate`}
                                  className={styles.date}
                                  minDate={TOMORROW}
                                  disabled={disabled(
                                    mkCommissionStructure.beginDate
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <MarketPlansTable
                            name={`mkCommissionStructures[${
                              indexLastMkCommission - index
                            }].mkCommissionRates`}
                            data={mkCommissionStructure.mkCommissionRates}
                            disabled={disabled(mkCommissionStructure.beginDate)}
                          />
                          <button
                            type="button"
                            className={cx(
                              styles.removeStructureBtn,
                              "secondary"
                            )}
                            onClick={() => {
                              onTrash(indexLastMkCommission - index);
                            }}
                            disabled={disabled(mkCommissionStructure.beginDate)}
                          >
                            ลบโครงสร้าง
                          </button>
                        </>
                      );
                    else return null;
                  }
                );
              }}
            />
          </form>
        );
      }}
    </Formik>
  );
}

export default enhancer(MarketingForm);
