const initialValues = {
  name: "",
  status: "",
  updateById: "",
  orderBy: "",
  order: "desc",
  page: 1,
  perPage: 10,
};

export default initialValues;
