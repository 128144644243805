import { useEffect } from "react";
import withHooks from "lib/withHooks";
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import { getConsentOptions } from "store/consentSlice";

const enhancer = compose(
  withHooks((props) => {
    const dispatch = useDispatch();
    const options = useSelector((state) => state.consent.options);

    useEffect(() => {
      dispatch(getConsentOptions());
    }, [dispatch]);

    return {
      options,
    };
  })
);

export default enhancer;
