import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getAgentById,
  getHistoriesById,
  getRecruitHistoriesById,
} from "store/agentSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const dispatch = useDispatch();
    const { histories, recruitHistories, agent } = useSelector(
      (state) => state.agent
    );
    const [page, setPage] = useState("edit");

    useEffect(() => {
      dispatch(getAgentById(id));
      dispatch(getHistoriesById(id));
      dispatch(getRecruitHistoriesById(id));
    }, [id, dispatch]);

    return { data: agent, id, histories, recruitHistories, setPage, page };
  }),
  mapProps(({ data, id, histories, recruitHistories, setPage, page }) => ({
    data,
    id,
    histories,
    recruitHistories,
    setPage,
    page,
  }))
);

export default enhancer;
