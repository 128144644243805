import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Selector as Select } from "components/Form/Element/Selector/Selector";
import Table from "components/Table";

import { dateFormatterFromPattern } from "utils/formatter";
import Icon from "components/Icon";
import { useState } from "react";
import { options as pinOptions } from "./Announcements.utils";
import styles from "./Announcements.module.css";

function AnnouncementsTable({
  data = [],
  options = [],
  changePriority = () => {},
  onChange,
  paging,
  pins = [],
  disabled,
}) {
  const columns = useMemo(
    () => [
      {
        Header: "ชื่อ",
        accessor: "name",
        styles: {
          header: {
            width: "280px",
          },
        },
        Cell: (cell) => {
          return (
            <Link to={`/announcements/${cell.row.original.id}`}>
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "สถานะ",
        accessor: "status",
        styles: {
          header: {
            width: "140px",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {(options?.statusOptions || []).find(
                (statusOption) =>
                  statusOption.value === cell.row.original.status
              )?.label || "-"}
            </div>
          );
        },
      },
      {
        Header: "ปักหมุด",
        accessor: "pin",
        styles: {
          header: {
            width: "180px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          const [changePin, setChangePin] = useState(false);
          let newPinOptions = pinOptions.filter(
            (item) => !pins.includes(item.value.toString())
          );
          return (
            <>
              {(cell.row.original.status === "publish" &&
                cell.row.original.pin) ||
              (cell.row.original.status === "publish" && changePin) ? (
                <div className={styles.selectPins}>
                  <div
                    className={styles.pins}
                    onClick={() => {
                      if (!disabled) {
                        changePriority(cell.row.original.id, null);
                      }
                    }}
                  >
                    <Icon name="pin" className={styles.iconPin} />
                  </div>
                  <Select
                    value={pinOptions.find(
                      (pinOption) => pinOption.value === cell.row.original.pin
                    )}
                    width="100px"
                    options={newPinOptions}
                    onChange={(e) => {
                      changePriority(cell.row.original.id, e.value);
                    }}
                    placeholder="เลือก"
                    isDisabled={newPinOptions.length === 0 || disabled}
                  />
                </div>
              ) : (
                cell.row.original.status === "publish" && (
                  <div
                    className={styles.unpins}
                    onClick={() => {
                      if (pins.length < 3 && !disabled) {
                        setChangePin(true);
                      }
                    }}
                  >
                    <Icon name="unpin" className={styles.iconUnpin} />
                  </div>
                )
              )}
            </>
          );
        },
      },
      {
        Header: "วันที่เผยแพร่",
        accessor: "publishDate",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          if (cell.row.original.status === "publish")
            return (
              dateFormatterFromPattern(cell.row.original.publishDate) || "-"
            );
          return null;
        },
      },
      {
        Header: "วันที่อัปเดตล่าสุด",
        accessor: "updatedAt",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return dateFormatterFromPattern(cell.row.original.updatedAt) || "-";
        },
      },
      {
        Header: "ผู้อัปเดตล่าสุด",
        accessor: "updateBy",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, pins]
  );

  return (
    <section className={styles.container}>
      <Table
        columns={columns}
        data={data}
        paging={paging}
        onChange={onChange}
      />
    </section>
  );
}

export default AnnouncementsTable;
