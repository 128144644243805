import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getConsentById,
  deleteConsent,
  getConsentOptions,
} from "store/consentSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const { agreement, options } = useSelector((state) => state.consent);

    useEffect(() => {
      dispatch(getConsentById(id));
      dispatch(getConsentOptions());
    }, [dispatch, id]);

    const onDelete = useCallback(() => {
      dispatch(
        deleteConsent(id, () => {
          history.push("/consent");
        })
      );
    }, [dispatch, id, history]);

    return { id, data: agreement, options: options, onDelete };
  }),
  mapProps(({ id, data, options, onDelete, disabled = false }) => ({
    id,
    data,
    options,
    onDelete,
    disabled,
  }))
);

export default enhancer;
