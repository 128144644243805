import { createSlice } from "@reduxjs/toolkit";
import api from "api";

const announcementsInitialState = {
  isLoading: false,
  initialized: false,
  announcement: {},
  paging: {},
  pins: [],
  announcements: [],
  options: {},
};

const announcementsReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setAnnouncement(state, action) {
    state.announcement = action.payload.announcement;
  },
  setAnnouncements(state, action) {
    state.announcements = action.payload.announcements;
    state.paging = action.payload.paging;
    state.pins = action.payload.pins;
  },
  setOptions(state, action) {
    state.options = action.payload;
  },
};

export const announcementsSlice = createSlice({
  name: "announcementsSlice",
  initialState: announcementsInitialState,
  reducers: announcementsReducers,
});

export const {
  setLoading,
  setAnnouncements,
  setAnnouncement,
  setOptions,
} = announcementsSlice.actions;

export default announcementsSlice.reducer;

export const getAnnouncements = (params = {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/announcements", params);
    dispatch(setAnnouncements(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAnnouncementsById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/announcements/${id}`);
    dispatch(setAnnouncement(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createAnnouncements = (values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.post("/announcements", values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAnnouncementsOptions = () => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const response = await api.get("/announcements/options");
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    // dispatch(setLoading(false));
  }
};

export const editAnnouncements = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/announcements/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const changePriorityStatus = (id, pin, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/announcements/${id}/set_pin`, {
      pin,
    });
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteAnnouncements = (id, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.delete(`/announcements/${id}`);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
