import { Route, Switch } from "react-router-dom";
import Announcements from "./root";
import AnnouncementsId from "./_id";
import NewAnnouncements from "./new";
import EditAnnouncements from "./edit";

const Pages = () => (
  <Switch>
    <Route exact path="/announcements/new" component={NewAnnouncements} />
    <Route exact path="/announcements/:id/edit" component={EditAnnouncements} />
    <Route exact path="/announcements/:id" component={AnnouncementsId} />
    <Route exact path="/announcements" component={Announcements} />
  </Switch>
);

export default Pages;
