import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";

import enhancer from "./CommissionId.compose";
import styles from "./CommissionId.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import { currency, dateFormat } from "utils/formatter";
import UplineComissions from "./UplineComissions";
import authorize, { UnauthorizedAction } from "utils/authorize";

const DisplayValue = ({ title = "", content = "" }) => {
  return (
    <div className={styles.displayValue}>
      <b>{title}</b>
      <label>{content}</label>
    </div>
  );
};

function CommissionId({ id = "", data = {}, options = {} }) {
  return (
    <Container>
      <h4 className="topic">รายการค่าตอบแทน</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "รายการค่าตอบแทน", link: "/commission" },
            {
              label: data?.opportunityId || "ออกรายงาน",
              current: true,
            },
          ]}
        />
        <div className={styles.groupBtn}>
          <button className={cx(styles.editBtn, "secondary")} type="button">
            แก้ไข
          </button>
        </div>
      </div>
      <div className={styles.container}>
        <section className={styles.orders}>
          <div className={cx(styles.title, styles.first)}>
            <label>ข้อมูลคำสั่งซื้อ</label>
          </div>
          <div className={styles.content}>
            <DisplayValue
              title="รหัสโอกาสในการขาย"
              content={data?.orderDetail?.opportunityKey || ""}
            />
            <DisplayValue
              title="รหัสคำสั่งซื้อ"
              content={data?.orderDetail?.orderNo || ""}
            />
            <DisplayValue
              title="แบบประกัน"
              content={data?.orderDetail?.orderName || ""}
            />
            <DisplayValue
              title="ชื่อลูกค้า"
              content={data?.orderDetail?.contactName || ""}
            />
            <DisplayValue
              title="บริษัทประกัน"
              content={data?.orderDetail?.companyName || ""}
            />
            <DisplayValue
              title="สถานะกรมธรรม์"
              content={data?.orderDetail?.policyState || ""}
            />
          </div>
        </section>
        <section className={styles.sales}>
          <div className={styles.title}>ข้อมูลการขาย</div>
          <div className={styles.content}>
            <DisplayValue
              title="รหัสผู้แนะนำ"
              content={data?.agentDetail?.affiliateCode || ""}
            />
            <DisplayValue
              title="ชื่อผู้แนะนำ"
              content={data?.agentDetail?.affiliateName || ""}
            />
            <DisplayValue
              title="วันที่แจ้งแนะนำ"
              content={
                data?.agentDetail?.affiliateSubmittedAt
                  ? dateFormat(data.agentDetail.affiliateSubmittedAt)
                  : ""
              }
            />
            <DisplayValue
              title="รหัสผู้ขาย"
              content={data?.agentDetail?.salesmanCode || ""}
            />
            <DisplayValue
              title="ชื่อผู้ขาย"
              content={data?.agentDetail?.salesmanName || ""}
            />
            <DisplayValue
              title="ทีมขาย"
              content={data?.agentDetail?.salesmanTeam || ""}
            />
            <DisplayValue
              title="วันที่แจ้งงานขาย"
              content={
                data?.agentDetail?.submittedAt
                  ? dateFormat(data.agentDetail.submittedAt)
                  : ""
              }
            />
          </div>
        </section>
        <section className={styles.commission}>
          <div className={styles.title}>ข้อมูลการจ่ายค่าแนะนำ</div>
          <div className={styles.content}>
            <DisplayValue
              title="ปี/งวด"
              content={data?.salseCommission?.installment || ""}
            />
            <DisplayValue
              title="วันที่ทีมได้รับค่าแนะนำ"
              content={
                data?.salseCommission?.receivedDate
                  ? dateFormat(data.salseCommission.receivedDate)
                  : ""
              }
            />
            <DisplayValue
              title="ทีมผู้แนะนำ"
              content={data?.salseCommission?.affiliateTeam || ""}
            />
            <DisplayValue
              title="ค่าแนะนำทั้งทีม"
              content={
                data?.salseCommission?.teamCommissionAmount ? (
                  <>{`${currency(
                    data.salseCommission.teamCommissionAmount,
                    2,
                    ""
                  )} บาท`}</>
                ) : (
                  <>{`${currency(0, 2, "")} บาท`}</>
                )
              }
            />
            <DisplayValue
              title="ค่าแนะนำที่จ่ายแล้ว"
              content={
                data?.salseCommission?.commissionAmount ? (
                  <>{`${currency(
                    data.salseCommission.commissionAmount,
                    2,
                    ""
                  )} บาท`}</>
                ) : (
                  <>{`${currency(0, 2, "")} บาท`}</>
                )
              }
            />
            <DisplayValue
              title="ค่าแนะนำเรียกคืน"
              content={
                data?.salseCommission?.reclaimAmount ? (
                  <>{`${currency(
                    data.salseCommission.reclaimAmount,
                    2,
                    ""
                  )} บาท`}</>
                ) : (
                  <>{`${currency(0, 2, "")} บาท`}</>
                )
              }
            />
          </div>
        </section>
      </div>
      <UplineComissions
        data={
          data?.uplineComissions || [
            {
              agentCode: "",
              agentName: "",
              positionName: "",
              commissionPercent: "",
              commissionAmount: "",
              reclaimAmount: "",
            },
          ]
        }
        options={options}
      />
    </Container>
  );
}

export default authorize(enhancer(CommissionId), {
  roles: "salesCommission",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["read"],
});
