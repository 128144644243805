import { Link } from "react-router-dom";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import DisplayValue from "components/DisplayValue";

import enhancer from "./AnnouncementsId.compose";
import styles from "./AnnouncementsId.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import Preview from "../preview";
import authorize, { UnauthorizedAction } from "utils/authorize";
import useToggle from "hooks/useToggle";
import Modal from "components/Modal";

const DeleteAnnouncement = authorize(
  ({ onDelete = () => {}, disabled }) => {
    const [showModal, , handleOpenModal, handleCloseModal] = useToggle();
    return (
      <>
        <button
          type="button"
          className={cx(styles.deleteBtn, "secondary")}
          onClick={handleOpenModal}
          disabled={disabled}
        >
          ลบ
        </button>
        <Modal
          isOpen={showModal}
          onSubmit={() => {
            onDelete();
            handleCloseModal();
          }}
          onCancel={handleCloseModal}
          submitText={"ยืนยัน"}
          cancelText={"ยกเลิก"}
          contentLabel="delete announcement modal"
        >
          <p>
            เมื่อลบแล้วจะไม่สามารถกู้คืนได้อีก
            <br />
            คุณต้องการยืนยันการลบใช่หรือไม่
          </p>
        </Modal>
      </>
    );
  },
  {
    roles: "announcement",
    params: { unauthorizedAction: UnauthorizedAction.DISABLED },
    actions: ["delete"],
  }
);

function AnnouncementsId({
  id = "",
  data = {},
  isPreview = false,
  setIsPreview = () => {},
  options = {},
  disabled,
  onDelete = () => {},
}) {
  return (
    <Container>
      <h4 className="topic">ประกาศและข่าวสาร</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "ประกาศและข่าวสาร", link: "/announcements" },
            {
              label: data?.name || "สร้าง",
              current: true,
            },
          ]}
        />
        <div className={styles.groupBtn}>
          {data?.status === "draft" && (
            <DeleteAnnouncement onDelete={onDelete} />
          )}
          <Link to={`/announcements/${id}/edit`}>
            <button
              className={cx(styles.editBtn, "secondary")}
              disabled={disabled}
            >
              แก้ไข
            </button>
          </Link>
          <button
            className={styles.previewBtn}
            onClick={() => setIsPreview(!isPreview)}
            type="button"
          >
            {isPreview ? "ย้อนกลับ" : "ดูตัวอย่าง"}
          </button>
        </div>
      </div>
      {isPreview ? (
        <Preview data={data} />
      ) : (
        <div className={styles.container}>
          <div className={styles.info}>
            <DisplayValue
              className={styles.name}
              label="ชื่อ"
              value={data.name}
            />
            <DisplayValue
              className={styles.type}
              label="ประเภท"
              value={
                (options?.statusOptions || []).find(
                  (statusOption) => statusOption.value === data.status
                )?.label || "-"
              }
            />
            <DisplayValue
              className={styles.authorName}
              label="ชื่อผู้เขียน"
              value={data.authorName}
            />
            <DisplayValue
              className={styles.authorPosition}
              label="ตำแหน่งผู้เขียน"
              value={data.authorPosition}
            />
          </div>
          <DisplayValue
            className={styles.article}
            label="เนื้อหา"
            value={data.article}
            useDangerously
          />
          <DisplayValue
            className={styles.reference}
            label="ที่มาของบทความ"
            value={data.reference}
          />
        </div>
      )}
    </Container>
  );
}

export default authorize(enhancer(AnnouncementsId), {
  roles: "announcement",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["modify"],
});
