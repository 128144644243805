// import { useState, useEffect, useCallback } from "react";
import withHooks from "lib/withHooks";
import { compose, mapProps } from "recompose";
// import { useDispatch, useSelector } from "react-redux";
// import { getMarketingOptions } from "store/marketingSlice";

const enhancer = compose(
  withHooks((props) => {
    // const dispatch = useDispatch();
    // const options = useSelector((state) => state.marketing.options);

    // useEffect(() => {
    //   dispatch(getMarketingOptions());
    // }, [dispatch]);

    // return {
    //   options,
    // };
    return {};
  }),
  mapProps((props) => ({ ...props }))
);

export default enhancer;
