import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { toggleMenu } from "store/appSlice";

const enhancer = compose(
  withHooks((props) => {
    const dispatch = useDispatch();
    const [pathname, setPathname] = useState();
    let location = useLocation();
    const isMenuOpen = useSelector((state) => state.app.isMenuOpen);

    const onToggleMenu = () => dispatch(toggleMenu());
    const onCloseMenu = () => isMenuOpen && dispatch(toggleMenu());

    useEffect(() => {
      const splited = location.pathname.split("/");
      setPathname(`/${splited[1] || ""}`);
    }, [location.pathname]);

    return {
      className: props.className,
      pathname,
      isMenuOpen,
      onToggleMenu,
      onCloseMenu,
    };
  }),
  mapProps(
    ({ className, pathname, isMenuOpen, onToggleMenu, onCloseMenu }) => ({
      className,
      pathname,
      isMenuOpen,
      onToggleMenu,
      onCloseMenu,
    })
  )
);

export default enhancer;
