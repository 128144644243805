import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import ConsentForm from "components/Form/ConsentForm";

import enhancer from "./NewConsent.compose";
import styles from "./NewConsent.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import useToggle from "hooks/useToggle";
import { useState } from "react";

function NewConsent({ onSubmit, sendEmail }) {
  const [showModal, , , handleCloseModal] = useToggle();
  const [
    showEnforceModal,
    ,
    handleOpenEnforceModal,
    handleCloseEnforceModal,
  ] = useToggle();
  const [, setIsPassModal] = useState(false);

  return (
    <Container>
      <h4 className="topic">จัดการข้อตกลงและการยินยอม</h4>
      <div className={containerStyles.breadcrumbWithButton}>
        <Breadcrumb
          links={[
            { label: "จัดการข้อตกลงและการยินยอม", link: "/consent" },
            { label: "สร้างข้อตกลงและการยินยอม", current: true },
          ]}
        />
        <button className={styles.btn} form="newConsent" type="submit">
          บันทึก
        </button>
      </div>
      <ConsentForm
        id="newConsent"
        onSubmit={onSubmit}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        sendEmail={sendEmail}
        setIsPassModal={setIsPassModal}
        showEnforceModal={showEnforceModal}
        handleOpenEnforceModal={handleOpenEnforceModal}
        handleCloseEnforceModal={handleCloseEnforceModal}
      />
    </Container>
  );
}

export default enhancer(NewConsent);
