import { useState, useEffect } from "react";
import { useField } from "formik";
import cx from "classnames";

import styles from "./Input.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Input({
  label = "",
  name = "",
  placeholder = "",
  type = "text",
  onChange = () => {},
  className = "",
  required = false,
  disabled = false,
  useTimeout = null,
  maxlength = null,
}) {
  const [timeoutId, setTimeoutId] = useState(null);
  const [togglePassword, setTogglePassword] = useState(false);
  const [field, meta, helpers] = useField(name);
  const { onBlur } = field;
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    if (useTimeout) {
      if (timeoutId) clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          onChange(value);
        }, useTimeout)
      );
    } else {
      onChange(value);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <label className={`label ${styles.label}`} htmlFor={name}>
          {label}
          {required && <span className="error">*</span>}
        </label>
      )}
      <input
        id={name}
        className={styles.input}
        name={name}
        placeholder={placeholder}
        type={togglePassword ? "text" : type}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        data-state={touched && error ? "error" : ""}
        disabled={disabled}
        data-type={type}
        maxlength={maxlength}
      />
      {type === "password" && (
        <div
          className={styles.toggle}
          onClick={() => setTogglePassword(!togglePassword)}
        >
          <FontAwesomeIcon
            icon={togglePassword ? faEyeSlash : faEye}
            color="var(--agt-secondary-text-color)"
            style={{ fontSize: "18px" }}
          />
        </div>
      )}
      {touched && error && (
        <span className={`error small ${styles.error}`}>{error}</span>
      )}
    </div>
  );
}

export default Input;
