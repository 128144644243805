import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";

import enhancer from "./AgentHistory.compose";
import historyStyles from "./AgentHistory.module.css";
import { toThaiDateString, toThaiTimeString } from "utils/helper";
import { dateFormatterFromPattern } from "utils/formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function AgentHistory({
  id = "",
  data = {},
  histories = [],
  recruitHistories = [],
  setPage = () => {},
  page = "",
}) {
  return (
    <Container>
      <h4 className="topic">ประวัติการแก้ไข</h4>
      <div className={historyStyles.breadcrumb}>
        <Breadcrumb
          links={[
            { link: "/agents", label: "จัดการผู้สมัคร" },
            {
              link: `/agents/${id}`,
              label: `${data.firstName} ${data.lastName}` || "รายชื่อผู้สมัคร",
            },
            { label: "ประวัติการแก้ไข", current: true },
          ]}
        />
        <div className={historyStyles.buttonContainer}>
          <button
            type="button"
            className={cx(
              "secondary",
              historyStyles.genderButton,
              historyStyles.male
            )}
            data-state={page === "edit" ? "selected" : ""}
            onClick={() => setPage("edit")}
          >
            ประวัติการแก้ไขข้อมูล
          </button>
          <button
            type="button"
            className={cx(
              "secondary",
              historyStyles.genderButton,
              historyStyles.female
            )}
            data-state={page === "recruitment" ? "selected" : ""}
            onClick={() => setPage("recruitment")}
          >
            ประวัติการสมัคร
          </button>
        </div>
      </div>
      {page === "edit" ? (
        <ul className={`${historyStyles.container}`}>
          <li className={historyStyles.item}>
            <div
              className={cx(`small`, historyStyles.cell, historyStyles.name)}
            >
              ผู้อัปเดต
            </div>
            <div
              className={cx(`small`, historyStyles.cell, historyStyles.status)}
              hidden
            >
              รายละเอียด
            </div>
            <div
              className={cx(`small`, historyStyles.cell, historyStyles.date)}
              hidden
            >
              วันที่อัปเดต
            </div>
            <div
              className={cx(`small`, historyStyles.cell, historyStyles.time)}
              hidden
            >
              เวลาที่อัปเดต
            </div>
          </li>
          {histories.map((data, index) => (
            <li
              key={index}
              className={cx(
                index === 0 ? historyStyles.active : null,
                historyStyles.item
              )}
            >
              {index === 0 && <span>อัปเดตล่าสุด</span>}
              <div className={cx(historyStyles.cell, historyStyles.name)}>
                {data.updateBy}
              </div>
              <div
                className={cx(
                  "small",
                  historyStyles.cell,
                  historyStyles.status
                )}
              >
                {data?.description
                  ? data.description.split(",").map((p) => <p>{p}</p>)
                  : "-"}
              </div>
              <div
                className={cx("small", historyStyles.cell, historyStyles.date)}
              >
                {toThaiDateString(data.updatedAt)}
              </div>
              <div
                className={cx("small", historyStyles.cell, historyStyles.time)}
              >
                {toThaiTimeString(data.updatedAt)}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className={historyStyles.recruitContainer}>
          {recruitHistories.map((recruitHistorie) => {
            return (
              <div className={historyStyles.recruitCard}>
                <div className={historyStyles.recruitDetail}>
                  <div className={historyStyles.recruitUpdate}>
                    <div>
                      วันที่{" "}
                      <b>
                        {dateFormatterFromPattern(
                          recruitHistorie?.approvedAt || new Date()
                        )}
                      </b>
                    </div>
                    <div
                      className={
                        recruitHistorie.recruitStatus === "success"
                          ? cx(historyStyles.recruitStatusSuccess)
                          : recruitHistorie.recruitStatus === "processing"
                          ? cx(historyStyles.recruitStatusProcessing)
                          : recruitHistorie.recruitStatus === "failed"
                          ? cx(historyStyles.recruitStatusFailed)
                          : {}
                      }
                    >
                      ผลการอนุมัติ :{" "}
                      <b>
                        {recruitHistorie.recruitStatus === "success"
                          ? "อนุมัติ"
                          : recruitHistorie.recruitStatus === "failed"
                          ? "ไม่อนุมัติ"
                          : recruitHistorie.recruitStatus === "processing"
                          ? "รออนุมัติ"
                          : "หมดอายุ"}
                      </b>
                    </div>
                  </div>
                  <div>
                    <p>
                      วันที่สมัคร :{" "}
                      <b>
                        {dateFormatterFromPattern(recruitHistorie.recruitedAt)}
                      </b>
                    </p>
                    {["ผู้แนะนำ"].includes(recruitHistorie.recruitTypeTh) ? (
                      <p>
                        ตำแหน่งที่สมัคร : <b>{recruitHistorie.recruitTypeTh}</b>
                      </p>
                    ) : (
                      <div className={historyStyles.additionLeader}>
                        <p>
                          ตำแหน่งที่สมัคร :{" "}
                          <b>{recruitHistorie.recruitTypeTh}</b>
                        </p>
                        <div>
                          <span>
                            {recruitHistorie.isLeader && (
                              <FontAwesomeIcon icon={faCheck} />
                            )}
                          </span>
                          หัวหน้านายหน้า
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    ผลการสัมภาษณ์ :{" "}
                    <b>
                      {recruitHistorie.interviewStatus === true
                        ? "ผ่าน"
                        : recruitHistorie.interviewStatus === false
                        ? "ไม่ผ่าน"
                        : "ยังไม่ระบุ"}
                    </b>
                  </div>
                  <div className={historyStyles.recruitExplain}>
                    <div>{recruitHistorie?.note || "-"}</div>
                  </div>
                </div>
                <div className={historyStyles.recruitComment}>
                  <div>หมายเหตุ</div>
                  <div>{recruitHistorie?.description || "-"}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
}

export default enhancer(AgentHistory);
