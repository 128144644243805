import { Link } from "react-router-dom";
import cx from "classnames";
import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AnnouncementsTable from "components/Table/AnnouncementsTable";

import enhancer from "./Announcements.compose";
import styles from "./Announcements.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import Icon from "components/Icon";
import FilterBar from "./FilterBar";
import authorize, { UnauthorizedAction } from "utils/authorize";

function Announcements({
  data,
  options,
  isShowFilterBar,
  toggle,
  onChange,
  searchValue,
  paging,
  pins,
  changePriority,
  disabled,
}) {
  return (
    <Container className={styles.container}>
      <section className={styles.header}>
        <h4 className="topic">ประกาศและข่าวสาร</h4>
        <div
          className={cx(
            containerStyles.breadcrumbWithButton,
            styles.breadcrumb
          )}
        >
          <Breadcrumb links={[{ label: "ประกาศและข่าวสาร", current: true }]} />
          <div className={styles.info}>
            <div className={styles.icon}>
              <Icon name="info" className={styles.iconInfo} />
            </div>
            <div className={styles.content}>
              สามารถปักหมุดได้สูงสุด 3 บทความ
            </div>
          </div>
          <div className={styles.groupBtn}>
            <button
              type="button"
              className={`${styles.selectBtn} secondary`}
              onClick={toggle}
            >
              ปรับตัวเลือก
            </button>
            <Link to="/announcements/new">
              <button className={styles.btn} disabled={disabled}>
                + สร้าง
              </button>
            </Link>
          </div>
        </div>
      </section>
      <FilterBar
        isOpen={isShowFilterBar}
        options={options}
        onChange={onChange}
        searchValue={searchValue}
      />
      <AnnouncementsTable
        data={data}
        options={options}
        changePriority={changePriority}
        onChange={onChange}
        paging={paging}
        pins={pins}
        disabled={disabled}
      />
    </Container>
  );
}

export default authorize(enhancer(Announcements), {
  roles: "announcement",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["modify"],
});
