const DEFAULT_VALUES = {
  name: "",
  agreementType: "",
  version: "",
  status: "draft",
  detail: "",
  contentDisplay: "",
  consentBy: "",
  consents: [],
  beginDate: "",
  enforcements: [],
  sendEmailAt: new Date(),
};

export { DEFAULT_VALUES };
