import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import useToggle from "hooks/useToggle";
import { getConsentOptions, getConsents } from "store/consentSlice";
import { filterObjByArray } from "utils/helper";

const enhancer = compose(
  withHooks((props) => {
    const [isShowFilterBar, toggle] = useToggle();
    const [searchValue, setSearchValue] = useState({
      agreementType: "",
      consentBy: "",
      contentDisplay: "",
      status: "",
      version: "",
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10,
    });
    const dispatch = useDispatch();
    const {
      agreements = [],
      paging = {},
      isLoading = false,
      options = [],
    } = useSelector((state) => state.consent);

    useEffect(() => {
      dispatch(getConsentOptions());
    }, [dispatch]);

    useEffect(() => {
      let searchKey = Object.values(
        filterObjByArray(searchValue, [
          "agreementType",
          "consentBy",
          "contentDisplay",
          "status",
          "version",
        ])
      );
      if (searchKey.every((search) => search === "")) {
        dispatch(getConsents(searchValue));
      } else {
        dispatch(getConsents({ ...searchValue, page: 1 }));
      }
    }, [dispatch, searchValue]);

    const onChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    return {
      data: agreements,
      isLoading,
      paging,
      options,
      isShowFilterBar,
      toggle,
      onChange,
      searchValue,
    };
  }),
  mapProps((props) => {
    return {
      data: props.data,
      isLoading: props.isLoading,
      paging: props.paging,
      options: props.options,
      isShowFilterBar: props.isShowFilterBar,
      toggle: props.toggle,
      onChange: props.onChange,
      searchValue: props.searchValue,
      disabled: props?.disabled || false,
    };
  })
);

export default enhancer;
