import * as Yup from "yup";

const ConsentSchema = Yup.object().shape({
  name: Yup.string().required("กรุณากรอกข้อมูล"),
  agreementType: Yup.string().required("กรุณาเลือก"),
  // version: Yup.number()
  //   .typeError("กรุณากรอกตัวเลขเท่านั้น")
  //   .moreThan(0, "กรุณากรอกข้อมูล")
  //   .required("กรุณากรอกข้อมูล"),
  // version: Yup.string().required("กรุณากรอกข้อมูล"),
  // beginDate: Yup.date().required("กรุณากรอกข้อมูล"),
  status: Yup.string().required("กรุณาเลือก"),
  detail: Yup.string().required("กรุณากรอกข้อมูล"),
  contentDisplay: Yup.string().required("กรุณาเลือก"),
  consentBy: Yup.string().required("กรุณากรอกข้อมูล"),
  consents: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("กรุณากรอกข้อมูล"),
    })
  ),
});

export default ConsentSchema;
