import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { compose, mapProps } from "recompose";
import { useHistory } from "react-router-dom";
import withHooks from "lib/withHooks";

import { createMarketing } from "store/marketingSlice";

const enhancer = compose(
  withHooks((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [lengthStructure, setLengthStructure] = useState(false);
    const { isLoading, options } = useSelector((state) => state.marketing);

    const onSubmit = (values) => {
      dispatch(
        createMarketing(values, () => {
          history.push("/marketing_plan");
        })
      );
    };

    return {
      onSubmit,
      isLoading,
      options,
      lengthStructure,
      setLengthStructure,
    };
  }),
  mapProps(
    ({
      onSubmit,
      isLoading,
      options,
      lengthStructure,
      setLengthStructure,
    }) => ({
      onSubmit,
      isLoading,
      options,
      lengthStructure,
      setLengthStructure,
    })
  )
);

export default enhancer;
