import cx from "classnames";

import styles from "./DisplayValue.module.css";

function DisplayValue({
  className = "",
  label = "",
  value = "",
  useDangerously = false,
}) {
  if (label || value) {
    return (
      <div className={cx(styles.container, className)}>
        <label className={styles.label}>{label}</label>
        {useDangerously ? (
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{ __html: value || "-" }}
          />
        ) : (
          <p className={styles.value}>{value || "-"}</p>
        )}
      </div>
    );
  }
  return null;
}

export default DisplayValue;
