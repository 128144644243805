import React from "react";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import MarketingForm from "components/Form/MarketingForm";

import newMarketingStyles from "./NewMarketing.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";

import enhancer from "./NewMarketing.compose";
import authorize, { UnauthorizedAction } from "utils/authorize";

function NewMarketing({
  onSubmit,
  options,
  lengthStructure,
  setLengthStructure,
}) {
  return (
    <Container>
      <h4 className="topic">แผนการตลาด</h4>
      <div className={containerStyles.breadcrumbWithButton}>
        <Breadcrumb
          links={[
            { label: "จัดการแผนการตลาดทีมผู้แนะนำ", link: "/marketing_plan" },
            { label: "แผนการตลาด", current: true },
          ]}
        />
        <button
          className={newMarketingStyles.btn}
          form="newMarket"
          type="submit"
          disabled={!lengthStructure}
        >
          บันทึก
        </button>
      </div>
      <MarketingForm
        id="newMarket"
        onSubmit={onSubmit}
        options={options}
        setLengthStructure={setLengthStructure}
      />
    </Container>
  );
}
export default authorize(enhancer(NewMarketing), {
  roles: "mkPlan",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["crud"],
});
