import React from "react";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AffiliateHistoryTable from "components/Table/AffiliateHistoryTable";

import enhancer from "./AffiliateHistory.compose";
import styles from "./AffiliateHistory.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";

function AffiliateHistory({ id = "", affiliate = {}, data = {} }) {
  return (
    <Container>
      <h4 className="topic">ประวัติการยอมรับคำยินยอม</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "จัดการผู้แนะนำ", link: "/affiliate" },
            {
              label: `${affiliate.firstName} ${affiliate.lastName}`,
              link: `/affiliate/${id}`,
            },
            { label: `ประวัติการยอมรับคำยินยอม`, current: true },
          ]}
        />
      </div>
      <AffiliateHistoryTable data={data} />
    </Container>
  );
}

export default enhancer(AffiliateHistory);
