import { Formik, FieldArray } from "formik";
import cx from "classnames";
import merge from "lodash/merge";
import Icon from "components/Icon";
import add from "date-fns/add";

import Preview from "./Preview";
import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import Editor from "components/Form/Element/Editor";
import CheckboxMockup from "components/Mockup/Checkbox";

import enhancer from "./ConsentForm.compose";
import validation from "./ConsentForm.validation";
import { DEFAULT_VALUES } from "./ConsentForm.utils";
import styles from "./ConsentForm.module.css";
import DatePicker from "../Element/DatePicker";
import { getYearsOptions } from "../Element/DatePicker/DatePicker.utils";
import Modal from "components/Modal";
// import EnforceModal from "./EnforceModal";

const TOMORROW = add(new Date(), { days: 1 });

function ConsentCheckbox(params) {
  const { name, push, form, remove } = params;
  const {
    values: { consents = [] },
  } = form;
  return (
    <ul className={styles.checkboxesContainer}>
      {consents.map((_, index) => {
        const onRemove = () => {
          remove(index);
        };
        return (
          <li key={index} className={styles.checkboxContainer}>
            <div className={styles.checkbox}>
              <CheckboxMockup />
            </div>
            <div className={styles.checkboxInput}>
              <Input
                className={styles.checkboxText}
                name={`${name}.${index}.name`}
                label=""
                placeholder="โปรดระบุข้อความของ Checkbox"
                required
              />
              <div className={styles.deleteBtn} onClick={onRemove}>
                <Icon name="close" />
              </div>
            </div>
            <Selector
              name={`${name}.${index}.forcedConsent`}
              className={styles.checkboxSelector}
              label=""
              width="140px"
              placeholder="กรุณาเลือก"
              options={[
                { label: "บังคับ", value: true },
                { label: "ไม่บังคับ", value: false },
              ]}
            />
          </li>
        );
      })}
      <button
        type="button"
        data-state={consents.length > 0 ? "expanded" : ""}
        className={cx(styles.addCheckboxBtn, "secondary")}
        onClick={() => {
          push({ name: "", forcedConsent: false });
        }}
      >
        + เพิ่ม Check Box
      </button>
    </ul>
  );
}

function ConsentForm({
  id = "",
  options = {
    agreementTypeOptions: [],
    statusOptions: [],
    contentDisplayOptions: [],
    consentByOptions: [],
    enforcementOptions: [],
  },
  className = "",
  initialValues = {},
  onSubmit = () => {},
  showModal = false,
  handleCloseModal = () => {},
  sendEmail = () => {},
  setIsPassModal = () => {},
  showEnforceModal = false,
  handleOpenEnforceModal = () => {},
  handleCloseEnforceModal = () => {},
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={merge({}, DEFAULT_VALUES, initialValues)}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const {
          values,
          handleSubmit,
          setFieldValue,
          initialValues: initValues,
        } = formikProps;
        return (
          <form
            id={id}
            onSubmit={handleSubmit}
            className={cx(styles.container, className)}
          >
            <Modal
              isOpen={showModal}
              className={styles.changeBegin}
              onSubmit={() => {
                handleCloseModal();
                setTimeout(() => {
                  setFieldValue("status", "activate");
                  setFieldValue("beginDate", new Date());
                  setIsPassModal(true);
                }, 1000);
                handleSubmit();
              }}
              onCancel={() => {
                handleSubmit();
                handleCloseModal();
              }}
              submitText={"ตกลง"}
              cancelText={"ไว้ทีหลัง"}
              contentLabel="Change beginDate Modal"
            >
              <p>
                ต้องการบังคับใช้ทันทีใช่หรือไม่
                <br />
                (คุณสามารถเลือกที่จะระบุวันที่บังคับใช้ในอนาคตได้)
              </p>
            </Modal>
            {/* <EnforceModal
              enforcementOptions={options.enforcementOptions}
              isOpen={showEnforceModal}
              className={styles.enforceModal}
              onSubmit={(e) => {
                setFieldValue("enforcements", e);
                handleCloseEnforceModal();
              }}
              onCancel={() => {
                handleCloseEnforceModal();
              }}
              submitText={"เลือก"}
              contentLabel="Change enforce Modal"
              values={values.enforcements}
              consentBy={values.consentBy}
            /> */}
            <div className={styles.fillContainer}>
              <Input
                className={styles.name}
                name="name"
                label="ชื่อ"
                placeholder="โปรดระบุ"
                required
              />
              {/* <div className={styles.enforcement}>
                <div className={styles.hiddenText}>การบังคับใช้</div>
                <button
                  type="button"
                  className={`secondary ${styles.enforceBtn}`}
                  onClick={handleOpenEnforceModal}
                  disabled={!values.consentBy}
                >
                  <Icon name="consent" color="red" />
                  <label>
                    การบังคับใช้
                    {values.enforcements.length > 0 &&
                      ` (${values.enforcements.length})`}
                  </label>
                </button>
              </div> */}
              <Selector
                className={styles.consentBy}
                name="consentBy"
                label="ผู้ให้การยินยอม"
                placeholder="เลือก"
                options={options.consentByOptions}
                required
                width="100%"
                onChange={(val) => {
                  if (val === "contact") {
                    setFieldValue("agreementType", "pdpa");
                    setFieldValue("contentDisplay", "email_web");
                  } else {
                    setFieldValue("agreementType", "");
                    setFieldValue("contentDisplay", "");
                  }
                }}
              />
              <Selector
                className={styles.agreementType}
                name="agreementType"
                label="ประเภท"
                placeholder="เลือก"
                options={
                  !["contact"].includes(values.consentBy)
                    ? (options.agreementTypeOptions || []).filter(
                        (option) => !["privacy_policy"].includes(option.value)
                      )
                    : options.agreementTypeOptions
                }
                required
                width="100%"
                disabled={["", "contact"].includes(values.consentBy)}
              />
              <Selector
                className={styles.contentDisplay}
                name="contentDisplay"
                label="ส่วนแสดงเนื้อหา"
                placeholder="เลือก"
                options={
                  !["contact"].includes(values.consentBy)
                    ? (options.contentDisplayOptions || []).filter(
                        (option) => option.value !== "email_web"
                      )
                    : options.contentDisplayOptions
                }
                required
                width="100%"
                disabled={
                  ["", "contact"].includes(values.consentBy) ||
                  [""].includes(values.agreementType)
                }
              />
              <Input
                className={styles.version}
                name="version"
                label="เวอร์ชัน"
                placeholder="โปรดระบุ"
              />
              <Selector
                className={styles.status}
                name="status"
                label="สถานะ"
                placeholder="เลือก"
                options={
                  initValues.status === "activate" &&
                  values.status !== "old_version"
                    ? (options?.statusOptions || []).filter(
                        (statusOption) =>
                          !["old_version", "draft", "cancel"].includes(
                            statusOption.value
                          )
                      )
                    : values.status !== "old_version"
                    ? (options?.statusOptions || []).filter(
                        (statusOption) =>
                          !["old_version", "cancel"].includes(
                            statusOption.value
                          )
                      )
                    : options.statusOptions
                }
                onChange={(val) => {
                  if (val === "activate") {
                    setIsPassModal(true);
                    if (initValues.status === "activate") {
                      setFieldValue("beginDate", initValues.beginDate);
                    } else {
                      setFieldValue("beginDate", new Date());
                    }
                  } else if (val === "cancel") {
                    setIsPassModal(true);
                  } else {
                    setIsPassModal(false);
                    setFieldValue("beginDate", null);
                  }
                }}
                required
                disabled={values.status === "old_version"}
                width="100%"
              />
              <DatePicker
                className={styles.beginDate}
                name="beginDate"
                label="วันที่มีผลบังคับใช้"
                showSelector
                yearOptions={getYearsOptions(null, "desc", 50)}
                minDate={TOMORROW}
                disabled={
                  values.status === "activate" || values.status === "cancel"
                }
              />
              <div className={styles.info}>
                <Icon name="info" className={styles.iconInfo} />
                <div className={styles.infoBeginDate}>
                  <small>สำหรับตั้งเวลาบังคับใช้ล่วงหน้า</small>
                </div>
              </div>
            </div>
            <Editor name="detail" label="เนื้อหา" placeholder="โปรดระบุ" />
            <FieldArray name="consents" component={ConsentCheckbox} />
            <Preview
              title={values.name}
              text={values.detail}
              consents={values.consents}
            />
          </form>
        );
      }}
    </Formik>
  );
}

export default enhancer(ConsentForm);
