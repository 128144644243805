import { Route, Switch } from "react-router-dom";
import Affiliate from "./root";
import AffiliateId from "./_id";
import EditAffiliate from "./edit";
import AffiliateHistory from "./history";

const Pages = (props) => (
  <Switch>
    <Route exact path="/affiliate/:id/history" component={AffiliateHistory} />
    <Route exact path="/affiliate/:id/edit" component={EditAffiliate} />
    <Route exact path="/affiliate/:id" component={AffiliateId} />
    <Route exact path="/affiliate" component={Affiliate} />
  </Switch>
);

export default Pages;
