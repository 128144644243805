import { useState, useEffect, useCallback } from "react";
import { useField } from "formik";
import cx from "classnames";
import { useDropzone } from "react-dropzone";
import Icon from "components/Icon";

import { createAttachment, getFileType } from "./Upload.utils";
import styles from "./Upload.module.css";

function Upload({
  label = "",
  name = "",
  onChange = () => {},
  className = "",
  required = false,
  disabled = false,
  info = "",
  selectType = ["PDF", "JPG", "JPEG", "PNG"],
  defaultValue = "",
  accept = "*",
}) {
  const [pathURL, setPathURL] = useState("");
  const [previewFile, setPreviewFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const { setValue } = helpers;
  useEffect(() => {
    if (defaultValue) {
      let pathParts = defaultValue.split("/");
      let file = pathParts[pathParts.length - 1].split(".");
      setPreviewFile({ name: defaultValue, type: file[file.length - 1] });
    }
  }, [defaultValue]);

  const clearFile = useCallback(() => {
    setPreviewFile(null);
    setValue("");
    setPathURL("");
  }, [setPreviewFile, setValue, setPathURL]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      createAttachment(
        file,
        (value) => {
          setPreviewFile(file);
          setValue(value.id);
          setPathURL(value.url);
        },
        () => clearFile()
      );
    },
    [clearFile, setValue, setPathURL]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  useEffect(() => {
    if (previewFile) {
      let fileType = getFileType(previewFile.type);
      if (selectType.find((type) => type === fileType)) {
        setFileType(fileType);
      } else {
        alert(`รองรับไฟล์ ${selectType.join(", ")} เท่านั้น`);
        clearFile();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewFile]);

  useEffect(() => {
    if (pathURL) {
      let pathParts = pathURL.split("/");
      let file = pathParts[pathParts.length - 1].split(".");
      let fileType = getFileType(file[file.length - 1]);
      if (selectType.find((type) => type === fileType)) {
        onChange(pathURL);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathURL]);

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <label className={`label ${styles.label}`} htmlFor={name}>
          {label}
          {required && <span className="error">*</span>}
        </label>
      )}
      <div className={styles.uploadBtn}>
        <button
          className={cx("", "primary")}
          type="button"
          disabled={!!previewFile || disabled}
          {...getRootProps({})}
        >
          อัปโหลด
          <input {...getInputProps()} />
        </button>
        {!!previewFile ? (
          <div className={cx(styles.file, styles[fileType])}>
            <div className={`${styles.type} ${styles.uploadType}`}>
              {fileType}
            </div>
            <small className={styles.text}>{previewFile.name}</small>
            <div onClick={clearFile} style={{ cursor: "pointer" }}>
              <Icon name="close" className={styles.iconClose} />
            </div>
          </div>
        ) : (
          <div className={styles.infoBtn}>
            <Icon name="info" className={styles.iconInfo} />
            <small>{info || "รองรับไฟล์ประเภท.jpg .png"}</small>
          </div>
        )}
      </div>
      {touched && error && (
        <span className={`error small ${styles.error}`}>{error}</span>
      )}
    </div>
  );
}

export default Upload;
