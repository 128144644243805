import { Route, Switch } from "react-router-dom";
import Admin from "./root";
import AdminId from "./_id";
import NewAdmin from "./new";
import EditAdmin from "./edit";

const Pages = (props) => (
  <Switch>
    <Route exact path="/admin/new" component={NewAdmin} />
    <Route exact path="/admin/:id/edit" component={EditAdmin} />
    <Route exact path="/admin/:id" component={AdminId} />
    <Route exact path="/admin" component={Admin} />
  </Switch>
);

export default Pages;
