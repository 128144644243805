import DisplayValue from "components/DisplayValue";
import styles from "./Preview.module.css";

function Preview({ data = {}, isEdit = false }) {
  return (
    <>
      <h2 className={styles.topic}>{data?.name || ""}</h2>
      <div className={styles.content}>
        <div className={styles.article}>
          {data?.bannerImagePath && (
            <img
              className={styles.banner}
              src={data?.bannerImagePath || ""}
              alt="News banner"
            />
          )}
          <DisplayValue
            className={styles.detail}
            label=""
            value={data?.article || "-"}
            useDangerously
          />
        </div>
        <div className={styles.ref}>
          <div className={styles.creater}>
            {data?.authorImagePath && (
              <img
                className={styles.avatar}
                src={data?.authorImagePath || ""}
                alt="News avatar"
              />
            )}
            <div className={styles.lastUpdatedBy}>
              <b>{data?.authorName || ""}</b>
              <b>{data?.authorPosition || ""}</b>
            </div>
          </div>
          {data?.reference && (
            <div className={styles.company}>
              <label>
                <b>ที่มาบทความ</b> {data?.reference || ""}
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Preview;
