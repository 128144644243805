import { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "components/Table";

import { dateFormatterFromPattern } from "utils/formatter";
import styles from "./AffiliateTable.module.css";

function AffiliateTable({
  data = [],
  paging = {},
  searchValue = {},
  onChange = () => {},
}) {
  const columns = useMemo(
    () => [
      {
        Header: "รหัสผู้แนะนำ",
        accessor: "agentKey",
        styles: {
          header: {
            width: "150px",
          },
        },
        Cell: (cell) => {
          return (
            <Link
              className={styles.link}
              to={`/affiliate/${cell.row.original.id}`}
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "ชื่อ",
        accessor: "firstName",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "นามสกุล",
        accessor: "lastName",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "ทีมผู้แนะนำ",
        accessor: "teamName",
        styles: {
          header: {
            width: "160px",
          },
        },
        Cell: (cell) => {
          if (cell?.value) {
            return cell.value;
          }
          return "-";
        },
      },
      {
        Header: "วันที่เริ่มงาน",
        accessor: "activatedAt",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
        },
        Cell: (cell) => dateFormatterFromPattern(cell.value) || "-",
      },
      {
        Header: "สถานะ",
        accessor: "status",
        styles: {
          header: {
            width: "100px",
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          if (cell?.value) {
            // if (cell.value instanceof String)
            return cell.value;
          }
          return "-";
        },
      },
      {
        Header: "หมายเหตุ",
        accessor: "description",
        styles: {
          header: {
            width: "260px",
          },
        },
        Cell: (cell) => cell?.value || "-",
      },
      {
        Header: "วันที่อัปเดตข้อมูล",
        accessor: "updatedAt",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
        },
        Cell: (cell) => dateFormatterFromPattern(cell.value) || "-",
      },
    ],
    []
  );

  return (
    <section className={styles.container}>
      <Table
        data={data}
        columns={columns}
        paging={paging}
        onChange={onChange}
        searchValue={searchValue}
      />
    </section>
  );
}

export default AffiliateTable;
