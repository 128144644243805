import { Formik } from "formik";
import cx from "classnames";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";

import styles from "./FilterBar.module.css";

function FilterBar({ isOpen, options = {}, onFilterChange = () => {} }) {
  return (
    <Formik
      initialValues={{
        adminCode: "",
        name: "",
        status: "",
        adminType: "",
        areaCode: "",
        branchCode: "",
      }}
    >
      {({ resetForm }) => (
        <form className={cx(styles.filterWrapper, !isOpen ? "hidden" : null)}>
          <div className={styles.filterInputWrapper}>
            <label htmlFor="adminCode">รหัสพนักงาน</label>
            <Input
              className={styles.filterInput}
              name="adminCode"
              placeholder="โปรดระบุ"
              useTimeout={1500}
              onChange={(value) => onFilterChange("adminCode", value)}
            />
          </div>
          <div className={styles.filterInputWrapper}>
            <label htmlFor="name">ชื่อ</label>
            <Input
              className={styles.filterInput}
              name="name"
              placeholder="โปรดระบุ"
              useTimeout={1500}
              onChange={(value) => onFilterChange("name", value)}
            />
          </div>
          <div className={styles.filterInputWrapper}>
            <label htmlFor="status">สถานะ</label>
            <Selector
              className={styles.filterInput}
              name="status"
              placeholder="เลือก"
              useTimeout={1500}
              onChange={(value) => onFilterChange("status", value)}
              width="100%"
              options={options.statusOptions || []}
            />
          </div>
          <div className={styles.filterInputWrapper}>
            <label htmlFor="adminType">ตำแหน่ง</label>
            <Selector
              className={styles.filterInput}
              name="adminType"
              placeholder="เลือก"
              useTimeout={1500}
              onChange={(value) => onFilterChange("adminType", value)}
              width="100%"
              options={options.adminTypeOptions || []}
            />
          </div>
          <div className={styles.filterInputWrapper}>
            <label htmlFor="areaCode">พื้นที่</label>
            <Selector
              className={styles.filterInput}
              name="areaCode"
              placeholder="เลือก"
              useTimeout={1500}
              onChange={(value) => onFilterChange("areaCode", value)}
              width="100%"
              options={options.areaOptions || []}
            />
          </div>
          <div className={styles.filterInputWrapper}>
            <label htmlFor="branchCode">สาขา</label>
            <Selector
              className={styles.filterInput}
              name="branchCode"
              placeholder="เลือก"
              useTimeout={1500}
              onChange={(value) => onFilterChange("branchCode", value)}
              width="100%"
              options={options.branchOptions || []}
            />
          </div>
          <div className={styles.filterInputWrapper} />
          <div className={styles.filterInputWrapper} />
          <div className={styles.filterInputWrapper}>
            <div
              className={cx(styles.filterInput, styles.reset)}
              onClick={resetForm}
            >
              รีเซ็ตค่าตัวเลือก
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default FilterBar;
