import cx from "classnames";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import styles from "./CommissionModal.module.css";
import DatePicker from "components/Form/Element/DatePicker";
import { Selector as Select } from "components/Form/Element/Selector/Selector";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import ReportTable from "./ReportTable";

ReactModal.setAppElement("#root");

const CustomModalForm = ({
  values,
  setFieldValue,
  handleSubmit,
  options = [],
}) => {
  const [affiliate, setAffiliate] = useState([]);
  const [isSelect, setIsSelect] = useState([]);

  useEffect(() => {
    let filteredOptions = options.filter(
      ({ value }) => !isSelect.some((e) => e.value === value)
    );
    let ids = isSelect.map((select) => select.value);
    setFieldValue("ids", ids);
    setAffiliate(filteredOptions);
    // eslint-disable-next-line
  }, [setAffiliate, isSelect]);

  return (
    <form id={"reportForm"} onSubmit={handleSubmit}>
      <div className={styles.rows}>
        <label>วันที่นำออก</label>
        <DatePicker
          className={styles.activatedSearch}
          name="exportDate"
          usePortalId={false}
        />
      </div>
      <div className={styles.rows}>
        <b>แยกตาม</b>
        <ul className={styles.excelType}>
          <li
            className={styles.customLi}
            onClick={() => {
              if (values.type === "order") setFieldValue("type", "");
              else setFieldValue("type", "order");
            }}
          >
            <input
              id="order"
              type="checkbox"
              checked={values.type === "order"}
            />
            <div className={styles.customChecked}>
              <div className={styles.dot} />
            </div>
            <div>คำสั่งซื้อ</div>
          </li>
          <li
            className={styles.customLi}
            onClick={() => {
              if (values.type === "affiliate") setFieldValue("type", "");
              else setFieldValue("type", "affiliate");
            }}
          >
            <input
              id="affiliate"
              type="checkbox"
              checked={values.type === "affiliate"}
            />
            <div className={styles.customChecked}>
              <div className={styles.dot} />
            </div>
            <div>ผู้แนะนำ</div>
          </li>
        </ul>
      </div>
      {values.type === "affiliate" && (
        <>
          <div className={styles.affiliateOptions}>
            <Select
              value={""}
              width="336px"
              options={affiliate || []}
              onChange={(e) => {
                setIsSelect([...isSelect, e]);
              }}
              placeholder="เลือกผู้แนะนำ"
              usePortal={false}
            />
            {isSelect.length > 0 && (
              <label>{isSelect.length} รายการที่เลือก</label>
            )}
          </div>
          {isSelect.length > 0 && (
            <ReportTable data={isSelect} setIsSelect={setIsSelect} />
          )}
        </>
      )}
    </form>
  );
};

function CustomModal({
  id = "customModal",
  isOpen = false,
  className = "",
  overlayClassName = "",
  submitText = "ยืนยัน",
  onSubmit = () => {},
  onCancel = () => {},
  contentLabel = "",
  options = [],
}) {
  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      className={cx(styles.modal, className)}
      overlayClassName={cx(styles.overlay, overlayClassName)}
      contentLabel={contentLabel}
      closeTimeoutMS={300}
    >
      <div className={styles.header}>
        <b>ออกรายงาน</b>
        <FontAwesomeIcon
          icon={faTimes}
          className={styles.close}
          onClick={onCancel}
        />
      </div>
      <section className={styles.container}>
        <Formik
          enableReinitialize
          initialValues={{ exportDate: new Date(), type: "order", ids: [] }}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            return <CustomModalForm {...formikProps} options={options} />;
          }}
        </Formik>
      </section>
      <div className={styles.buttonContainer}>
        <button type="submit" form="reportForm">
          {submitText}
        </button>
      </div>
    </ReactModal>
  );
}

export default CustomModal;
