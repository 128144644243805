import { Formik } from "formik";
import cx from "classnames";
import merge from "lodash/merge";
import Icon from "components/Icon";

import Preview from "../../../pages/Announcements/preview";
import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import Editor from "components/Form/Element/Editor";

import enhancer from "./AnnouncementsForm.compose";
import validation from "./AnnouncementsForm.validation";
import { DEFAULT_VALUES } from "./AnnouncementsForm.utils";
import styles from "./AnnouncementsForm.module.css";
import Upload from "../Element/Upload";

function AnnouncementsForm({
  id = "",
  options = {},
  className = "",
  initialValues = {},
  onSubmit = () => {},
  setIsRef = () => {},
  isRef = false,
  setIsPreview = () => {},
  isPreview = false,
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={merge({}, DEFAULT_VALUES, initialValues)}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { values, handleSubmit, setFieldValue } = formikProps;
        return (
          <form
            id={id}
            onSubmit={handleSubmit}
            className={
              isPreview ? cx(className) : cx(styles.container, className)
            }
          >
            {isPreview ? (
              <Preview data={values} isEdit />
            ) : (
              <>
                <div className={styles.fillContainer}>
                  <Input
                    className={styles.name}
                    name="name"
                    label="ชื่อ"
                    placeholder="โปรดระบุ"
                    required
                  />
                  <Selector
                    className={styles.status}
                    name="status"
                    label="สถานะ"
                    placeholder="เลือก"
                    options={options.statusOptions}
                    width="100%"
                  />
                  <Input
                    className={styles.author}
                    name="authorName"
                    label="ชื่อผู้เขียน"
                    placeholder="โปรดระบุ"
                    required
                  />
                  <Upload
                    className={styles.avatar}
                    name="authorImageId"
                    label="รูปผู้เขียน"
                    info="รองรับไฟล์ประเภท.jpg .png ภาพ 1:1, 80x80 px."
                    accept="image/jpeg, image/png"
                    defaultValue={values?.authorImagePath}
                    onChange={(e) => {
                      setFieldValue("authorImagePath", e);
                    }}
                  />
                  <Input
                    className={styles.author}
                    name="authorPosition"
                    label="ตำแหน่งผู้เขียน"
                    placeholder="โปรดระบุ"
                  />
                  <Upload
                    className={styles.banner}
                    name="bannerImageId"
                    label="รูปแบนเนอร์"
                    info="รองรับไฟล์ประเภท.jpg .png ภาพ 16:9, 544x306 px."
                    accept="image/jpeg, image/png"
                    defaultValue={values?.bannerImagePath}
                    onChange={(e) => {
                      setFieldValue("bannerImagePath", e);
                    }}
                  />
                </div>
                <Editor name="article" label="เนื้อหา" placeholder="โปรดระบุ" />
                <div className={`${styles.refDetail} secondary`}>
                  {isRef ? (
                    <div className={styles.detail}>
                      <label>ที่มาบทความ</label>
                      <Input
                        className={styles.company}
                        name="reference"
                        placeholder="โปรดระบุ"
                      />
                      <div
                        className={styles.closeRef}
                        onClick={() => {
                          setIsRef(false);
                        }}
                      >
                        <Icon name="close" className={styles.icon} />
                      </div>
                    </div>
                  ) : (
                    <button
                      className={`${styles.addRefBtn} secondary`}
                      type="button"
                      onClick={() => {
                        setIsRef(true);
                      }}
                    >
                      + เพิ่มที่มาบทความ
                    </button>
                  )}
                  <button
                    className={`${styles.previewBtn} primary`}
                    type="button"
                    onClick={() => setIsPreview(true)}
                  >
                    ดูตัวอย่าง
                  </button>
                </div>
              </>
            )}
          </form>
        );
      }}
    </Formik>
  );
}

export default enhancer(AnnouncementsForm);
