import cx from "classnames";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import Menu from "components/Layout/Menu";

import useNavigationBar from "hooks/useNavigationBar";

import styles from "./Layout.module.css";

export function LayoutRenderer({
  className = "",
  hasHeader,
  hasFooter,
  hasMenu,
  children,
}) {
  return (
    <section className={cx(styles.body, className)}>
      {hasHeader && <Header />}
      <main
        className={
          hasHeader ? cx(styles.container, styles.hasHeader) : styles.container
        }
      >
        {hasMenu && <Menu />}
        {children}
      </main>
      {hasFooter && <Footer />}
    </section>
  );
}

function Layout({ children }) {
  const { hasHeader, hasFooter, hasMenu } = useNavigationBar();
  return (
    <LayoutRenderer
      hasHeader={hasHeader}
      hasFooter={hasFooter}
      hasMenu={hasMenu}
      children={children}
    />
  );
}

export default Layout;
