// import { useEffect, useState } from "react";
import { FieldArray } from "formik";
import Icon from "components/Icon";
import add from "date-fns/add";
import sub from "date-fns/sub";

import Table from "components/Table";
import DatePicker from "components/Form/Element/DatePicker";

import { dateFormatterFromPattern } from "utils/formatter";
import styles from "./MemberTable.module.css";

// const TODAY = new Date();
// const TOMORROW = add(new Date(), { days: 1 });

function MemberTable({ data = [], onSelectedMember = () => {} }) {
  return (
    <FieldArray
      name="affiliateMembers"
      render={(arrayHelpers) => {
        return (
          <Table
            columns={[
              {
                Header: "รหัสผู้แนะนำ",
                accessor: "agentKey", // accessor is the "key" in the data
                styles: {
                  header: {
                    width: "150px",
                  },
                },
              },
              {
                Header: "รหัสพนักงาน",
                accessor: "agentCode",
                styles: {
                  header: {
                    width: "150px",
                  },
                },
              },
              {
                Header: "ชื่อสมาชิก",
                accessor: "name",
                styles: {
                  header: {
                    width: "220px",
                  },
                },
              },
              {
                Header: "วันที่เริ่มงาน",
                accessor: "activatedAt",
                styles: {
                  header: {
                    width: "160px",
                    textAlign: "center",
                  },
                  cell: {
                    textAlign: "center",
                  },
                },
                Cell: (cell) => {
                  return cell.value
                    ? dateFormatterFromPattern(cell.value)
                    : "-";
                },
              },
              {
                Header: "วันที่เข้าทีม",
                accessor: "joinTeamAt",
                styles: {
                  header: {
                    width: "200px",
                    textAlign: "center",
                  },
                  cell: {
                    textAlign: "left",
                  },
                },
                Cell: (cell) => {
                  const index = cell.row.index;
                  return (
                    <DatePicker
                      name={`affiliateMembers.${index}.joinTeamAt`}
                      className={styles.date}
                      minDate={new Date(cell.row.original.activatedAt)}
                      maxDate={
                        cell.row.original.exitTeamAt
                          ? sub(new Date(cell.row.original.exitTeamAt), {
                              days: 1,
                            })
                          : null
                      }
                    />
                  );
                },
              },
              {
                Header: "วันที่สิ้นสุด",
                accessor: "exitTeamAt",
                styles: {
                  header: {
                    width: "200px",
                    textAlign: "center",
                  },
                  cell: {
                    textAlign: "center",
                  },
                },
                Cell: (cell) => {
                  const index = cell.row.index;
                  return (
                    <DatePicker
                      name={`affiliateMembers.${index}.exitTeamAt`}
                      className={styles.date}
                      minDate={add(new Date(cell.row.original.joinTeamAt), {
                        days: 1,
                      })}
                    />
                  );
                },
              },
              {
                Header: "Action",
                accessor: "action",
                styles: {
                  header: {
                    width: "100px",
                    textAlign: "center",
                  },
                  cell: {
                    textAlign: "center",
                  },
                },
                Cell: (cell) => {
                  const { index } = cell.row;

                  const onDeleteMember = () => {
                    onSelectedMember({
                      ...cell.row,
                      remove: () => arrayHelpers.remove(index),
                    });
                  };

                  return (
                    <>
                      <button
                        type="button"
                        onClick={onDeleteMember}
                        className={`secondary ${styles.actionBtn}`}
                      >
                        <Icon name="bin" />
                      </button>
                    </>
                  );
                },
              },
            ]}
            data={data}
          />
        );
      }}
    />
  );
}

export default MemberTable;
