import { Route, Switch } from "react-router-dom";
import Commission from "./root";
import CommissionId from "./_id";
import CommissionReport from "./report";

const Pages = () => (
  <Switch>
    <Route exact path="/commission/report" component={CommissionReport} />
    <Route exact path="/commission/:id" component={CommissionId} />
    <Route exact path="/commission" component={Commission} />
  </Switch>
);

export default Pages;
