// import { useState } from "react";
// import Icon from "components/Icon";
// import cx from "classnames";

// import Modal from "components/Modal";
// import { Selector as Select } from "components/Form/Element/Selector/Selector";

// import useToggle from "hooks/useToggle";
import styles from "./AgentForm.module.css";
// import authorize, { UnauthorizedAction } from "utils/authorize";

// const NewInterviewComponent = ({
//   label = "",
//   role = "",
//   values = null,
//   onChange = () => {},
// }) => {
//   return (
//     <div className={styles.interviewContainer}>
//       <div style={{ marginBottom: 8 }}>
//         <label>
//           {label} : <b>{role}</b>
//         </label>
//       </div>
//       <div className={styles.interviewGroup}>
//         <div className={styles.interviewButton}>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               cursor: "pointer",
//             }}
//             onClick={() => onChange(true)}
//           >
//             <div
//               className={cx(
//                 styles.interviewCircle,
//                 values === true ? styles.interviewChecked : null
//               )}
//             >
//               <div className={values === true ? styles.dot : ""} />
//             </div>
//             <div style={{ paddingLeft: 16 }}>ผ่าน</div>
//           </div>
//         </div>
//         <div className={styles.interviewButton}>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               cursor: "pointer",
//             }}
//             onClick={() => onChange(false)}
//           >
//             <div
//               className={cx(
//                 styles.interviewCircle,
//                 values === false ? styles.interviewChecked : null
//               )}
//             >
//               <div className={values === false ? styles.dot : null} />
//             </div>
//             <div style={{ paddingLeft: 16 }}>ไม่ผ่าน</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const CheckboxAutorize = ({
//   name,
//   value,
//   label,
//   onClick = () => {},
//   disabled = false,
// }) => {
//   return (
//     <div className={styles.checkboxAutorize}>
//       <div
//         className={
//           disabled
//             ? styles.disabledCheckbox
//             : value
//             ? styles.checkedbox
//             : styles.checkbox
//         }
//       >
//         <Icon
//           name="check"
//           className={value ? styles.checked : styles.unChecked}
//         />
//         <input
//           type="checkbox"
//           name={name}
//           checked={value}
//           onClick={onClick}
//           className={styles.checkboxInput}
//           disabled={disabled}
//         />
//       </div>
//       <label htmlFor={name}>{label}</label>
//     </div>
//   );
// };

// const RejectEmail = authorize(
//   ({ sendEmail = () => {}, options, data = {}, name = "", disabled }) => {
//     const [showModal, , handleOpenModal, handleCloseModal] = useToggle();
//     const [param, setParam] = useState({
//       label: "ปฏิเสธถาวร (Blacklist)",
//       value: "ปฏิเสธถาวร (Blacklist)",
//     });

//     const onSubmit = () => {
//       handleCloseModal();
//       let values = {
//         ...data,
//         recruits: {
//           ...data.recruits,
//           [name]: {
//             ...data.recruits[name],
//             agentStatus: param.value,
//             recruitStatus: "failed",
//           },
//         },
//       };
//       sendEmail(
//         values,
//         name === "affiliate"
//           ? "affiliate"
//           : name === "lifeBroker"
//           ? "life_broker"
//           : "non_life_broker",
//         name,
//         "failed"
//       );
//     };
//     const onCancel = () => {
//       handleCloseModal();
//     };

//     return (
//       <>
//         <Modal
//           isOpen={showModal}
//           onSubmit={onSubmit}
//           onCancel={onCancel}
//           onClose={handleCloseModal}
//           contentLabel="SendEmail Modal"
//           submitText="ยืนยัน"
//           cancelText="ยกเลิก"
//         >
//           <div className={styles.rejectEmail}>
//             <p>
//               ต้องการ<b>ไม่อนุมัติ</b>ผู้สมัครท่านนี้ใช่หรือไม่ ?<br />
//               กรุณาระบุสถานะการปฏิเสธ
//               <br />
//               โดยระบบจะส่งอีเมลปฏิเสธไปยังผู้สมัคร
//             </p>
//             <Select
//               width="100%"
//               value={param}
//               options={
//                 options.filter((option) =>
//                   ["ปฏิเสธถาวร (Blacklist)", "ปฏิเสธชั่วคราว (1 ปี)"].includes(
//                     option.value
//                   )
//                 ) || []
//               }
//               onChange={(e) => {
//                 setParam(e);
//               }}
//               placeholder="เลือก"
//               usePortal={false}
//             />
//             <small>
//               เมื่อกดยืนยันแล้วจะไม่สามารถแก้ไขรายละเอียดในภายหลังได้
//             </small>
//           </div>
//         </Modal>
//         <button
//           type="button"
//           className="secondary"
//           onClick={handleOpenModal}
//           disabled={disabled}
//         >
//           ไม่อนุมัติ
//         </button>
//       </>
//     );
//   },
//   {
//     roles: "agent",
//     params: { unauthorizedAction: UnauthorizedAction.DISABLED },
//     actions: ["approve_non_life_broker"],
//   }
// );

// const ApprovedEmail = authorize(
//   ({
//     sendEmail = () => {},
//     role = "ผู้แนะนำ",
//     data = {},
//     name = "",
//     disabled,
//   }) => {
//     const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

//     const onSubmit = () => {
//       handleCloseModal();
//       let values = {
//         ...data,
//         recruits: {
//           ...data.recruits,
//           [name]: {
//             ...data.recruits[name],
//             recruitStatus: "success",
//           },
//         },
//       };
//       sendEmail(
//         values,
//         name === "affiliate"
//           ? "affiliate"
//           : name === "lifeBroker"
//           ? "life_broker"
//           : "non_life_broker",
//         "success"
//       );
//     };
//     const onCancel = () => {
//       handleCloseModal();
//     };

//     return (
//       <>
//         <Modal
//           isOpen={showModal}
//           onSubmit={onSubmit}
//           onCancel={onCancel}
//           onClose={handleCloseModal}
//           contentLabel="SendEmail Modal"
//           submitText="ยืนยัน"
//           cancelText="ยกเลิก"
//         >
//           <div className={styles.approvedEmail}>
//             <p>
//               ต้องการ<b>อนุมัติ</b>
//               ผู้สมัครท่านนี้เป็น
//               <br />
//               {role}ใช่หรือไม่ ?<br />
//               (กรุณาตรวจสอบข้อมูลทีม อัปไลน์ ให้ถูกต้อง)
//             </p>
//             <small>
//               เมื่อกดยืนยันแล้วจะไม่สามารถแก้ไขรายละเอียดในภายหลังได้
//             </small>
//           </div>
//         </Modal>
//         <button
//           type="button"
//           className="primary"
//           onClick={handleOpenModal}
//           disabled={disabled}
//         >
//           อนุมัติ
//         </button>
//       </>
//     );
//   },
//   {
//     roles: "agent",
//     params: { unauthorizedAction: UnauthorizedAction.DISABLED },
//     actions: ["approve_non_life_broker"],
//   }
// );

const NonLifeBrokerForm = ({ values }) => {
  if (
    values?.recruits?.nonLifeBroker &&
    values?.recruits?.nonLifeBroker?.recruitStatus !== "processing"
  ) {
    return null;
  } else if (values?.recruits?.nonLifeBroker) {
    return null;
  } else {
    return (
      <div className={styles.newInterview}>
        <div className={styles.rowInterview}>
          <div className={{}}>
            <div style={{ color: "var(--agt-secondary-color-1)" }}>
              เร็วๆนี้ : <b>นายหน้าประกันวินาศภัย</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default NonLifeBrokerForm;
