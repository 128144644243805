import { FieldArray } from "formik";
import cx from "classnames";

import Selector from "components/Form/Element/Selector";
import Input from "components/Form/Element/Input";
import styles from "./MarketPlansTable.module.css";

function MarketingTable({ name = "", data = [], disabled }) {
  return (
    <>
      <div className={cx(styles.table, styles.header)}>
        <div className={styles.title}>ระดับ</div>
        <div className={styles.title}>ตำแหน่ง</div>
        <div className={styles.title}>ค่าแนะนำ</div>
        <div className={styles.title}>ค่าบริหาร</div>
        <div className={styles.title}>ตำแหน่งว่างให้</div>
      </div>
      <FieldArray
        name="agentDocuments"
        render={(arrayHelpers) => (
          <>
            {data.map((mkCommissionRates, index) => {
              return (
                <div key={index} className={cx(styles.table, styles.body)}>
                  <div className={styles.positionLevel}>
                    {mkCommissionRates.positionLevel}
                  </div>
                  <div className={styles.positionName}>
                    <Input
                      name={`${name}.${index}.positionName`}
                      className={styles.input}
                      placeholder="โปรดระบุ"
                      disabled={disabled}
                    />
                  </div>
                  <div className={styles.commissionPercent}>
                    <Input
                      name={`${name}.${index}.commissionPercent`}
                      className={styles.input}
                      placeholder="โปรดระบุ"
                      disabled={disabled}
                    />
                    <label>%</label>
                  </div>
                  <div className={styles.managementPercent}>
                    <Input
                      name={`${name}.${index}.managementPercent`}
                      className={styles.input}
                      placeholder="โปรดระบุ"
                      disabled={disabled}
                    />
                    <label>%</label>
                  </div>
                  <div className={styles.reserveForLevel}>
                    <Selector
                      width="100%"
                      name={`${name}.${index}.reserveForLevel`}
                      className={styles.selector}
                      placeholder="โปรดระบุ"
                      options={[
                        { value: null, label: "ไม่มี" },
                        { value: 1, label: 1 },
                        { value: 2, label: 2 },
                        { value: 3, label: 3 },
                      ]}
                      disabled={disabled}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      />
    </>
  );
}

export default MarketingTable;
