import { useState, useEffect, useCallback } from "react";
import withHooks from "lib/withHooks";
import { compose, mapProps } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import { getTeamOptions } from "store/teamSlice";
import { useParams } from "react-router-dom";
import useToggle from "hooks/useToggle";

const enhancer = compose(
  withHooks((props) => {
    const { id = "" } = useParams();
    const [selectedRow, setRow] = useState({});
    const [isModalOpened, , openModal, closeModal] = useToggle();
    const dispatch = useDispatch();
    const options = useSelector((state) => state.team.options);

    useEffect(() => {
      if (id) {
        let values = { teamId: id };
        dispatch(getTeamOptions(values));
      }
    }, [dispatch, id]);

    const onDeleteMember = useCallback(() => {
      if (selectedRow.remove) selectedRow.remove();
      closeModal();
    }, [selectedRow, closeModal]);

    const onCloseModal = useCallback(() => {
      setRow({});
      closeModal();
    }, [setRow, closeModal]);

    const onSelectedMember = useCallback(
      (values) => {
        setRow(values);
        setTimeout(() => {
          openModal();
        }, 10);
      },
      [setRow, openModal]
    );

    const filterOptions = (totalOptions, leader, members) => {
      const resultFilterMember = totalOptions.filter(
        ({ value, label }) =>
          !members.some(
            (member) => member.id === value && member.name === label
          )
      );

      return resultFilterMember.filter((e) => e.value !== leader);
    };

    const filterSaleOptions = (tqmTeamOptions, salesGroup) => {
      if (salesGroup) {
        // const MyArrayFiltered = tqmTeamOptions.filter(
        //   (e) => e.salesGroup === salesGroup
        // );
        // return MyArrayFiltered
        return tqmTeamOptions.filter((e) =>
          [null, salesGroup].includes(e.salesGroup)
        );
      } else return tqmTeamOptions;
    };

    return {
      options,
      selectedRow,
      isModalOpened,
      onDeleteMember,
      onSelectedMember,
      onCloseModal,
      filterOptions,
      filterSaleOptions,
    };
  }),
  mapProps((props) => ({ ...props }))
);

export default enhancer;
