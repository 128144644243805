import React from "react";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import FilterBar from "./FilterBar";
import AffiliateTable from "components/Table/AffiliateTable";

import enhancer from "./Affiliate.compose";
import styles from "./Affiliate.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";

function Affiliate({
  data = [],
  options = {},
  isShowFilterBar,
  toggle,
  paging = {},
  searchValue = {},
  onChange = () => {},
}) {
  return (
    <Container className={styles.container}>
      <section className={styles.header}>
        <h4 className="topic">จัดการผู้แนะนำ</h4>
        <div
          className={cx(
            containerStyles.breadcrumbWithButton,
            styles.breadcrumb
          )}
        >
          <Breadcrumb links={[{ label: "จัดการผู้แนะนำ", current: true }]} />
          <button className="secondary" onClick={toggle}>
            ปรับตัวเลือก
          </button>
        </div>
      </section>
      <FilterBar
        isOpen={isShowFilterBar}
        options={options}
        onChange={onChange}
      />
      <AffiliateTable
        data={data}
        paging={paging}
        onChange={onChange}
        searchValue={searchValue}
      />
    </Container>
  );
}

export default enhancer(Affiliate);
