const DEFAULT_VALUES = {
  name: "",
  status: "draft",
  authorName: "",
  article: "",
  authorPosition: "",
  authorImageId: "",
  bannerImageId: "",
  reference: "",
  pin: null,
};

export { DEFAULT_VALUES };
