import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  getAgentById,
  sendEmailAgain,
  getAgentOptions,
  downloadDocument,
} from "store/agentSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const dispatch = useDispatch();
    const { agent, isLoading, options } = useSelector((state) => state.agent);
    const sendEmail = (values) => {
      dispatch(sendEmailAgain(id, values));
    };

    const getFileDocument = (values, name) => {
      dispatch(downloadDocument(id, values, name));
    };

    useEffect(() => {
      dispatch(getAgentById(id));
      dispatch(getAgentOptions());
    }, [id, dispatch]);

    return {
      id,
      isLoading,
      agent,
      options,
      sendEmail,
      getFileDocument,
    };
  }),
  mapProps(
    ({
      id,
      isLoading,
      agent,
      options,
      sendEmail,
      getFileDocument,
      disabled = false,
    }) => ({
      id,
      isLoading,
      data: agent,
      options,
      sendEmail,
      getFileDocument,
      disabled,
    })
  )
);

export default enhancer;
