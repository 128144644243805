import { createSlice } from "@reduxjs/toolkit";
import api from "api";

const marketingInitialState = {
  isLoading: false,
  initialized: false,
  marketing: {},
  marketPlans: [],
  options: {},
};

const marketingReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setMarketPlan(state, action) {
    state.marketPlan = action.payload.marketPlan;
  },
  setMarketPlans(state, action) {
    state.marketPlans = action.payload.marketPlans;
    state.paging = action.payload.paging;
  },
  setOptions(state, action) {
    state.options = action.payload;
  },
};

export const marketingSlice = createSlice({
  name: "marketing",
  initialState: marketingInitialState,
  reducers: marketingReducers,
});

export const {
  setLoading,
  setMarketPlan,
  setMarketPlans,
  setOptions,
} = marketingSlice.actions;

export default marketingSlice.reducer;

export const getMarketing = (params = {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/market_plans", params);
    dispatch(setMarketPlans(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMarketingById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/market_plans/${id}`);
    dispatch(setMarketPlan(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createMarketing = (values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.post("/market_plans/", values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMarketingOptions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/market_plans/options");
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const editMarketing = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/market_plans/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteMarketing = (id, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.delete(`/market_plans/${id}`);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteStructure = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/market_plans/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
