import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { getMarketing } from "store/marketingSlice";

const enhancer = compose(
  withHooks((props) => {
    const dispatch = useDispatch();
    const { marketPlans = [], paging = {}, isLoading = false } = useSelector(
      (state) => state.marketing
    );
    const [searchValue, setSearchValue] = useState({});
    useEffect(() => {
      dispatch(getMarketing(searchValue));
    }, [dispatch, searchValue]);
    const onPaginateChange = useCallback(
      (value) => {
        setSearchValue({ ...value });
      },
      [setSearchValue]
    );
    return { data: marketPlans, isLoading, paging, onChange: onPaginateChange };
  }),
  mapProps((props) => {
    return {
      data: props.data,
      isLoading: props.isLoading,
      paging: props.paging,
      onChange: props.onChange,
      disabled: props?.disabled || false,
    };
  })
);

export default enhancer;
