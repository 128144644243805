import React from "react";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AnnouncementsForm from "components/Form/AnnouncementsForm";

import enhancer from "./EditAnnouncements.compose";
import styles from "./EditAnnouncements.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

function EditAnnouncements({
  id = "",
  data = {},
  onSubmit = () => {},
  setIsPreview = () => {},
  isPreview = false,
}) {
  return (
    <Container>
      <h4 className="topic">ประกาศและข่าวสาร</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "ประกาศและข่าวสาร", link: "/announcements" },
            {
              label: data?.name || "สร้าง",
              link: `/announcements/${id}`,
            },
            { label: `แก้ไข`, current: true },
          ]}
        />
        <div className={styles.groupBtn}>
          <button
            className={
              isPreview ? cx(styles.editBtn) : cx(styles.editBtn, styles.hidden)
            }
            onClick={() => setIsPreview(false)}
            type="button"
          >
            แก้ไข
          </button>
          <button
            className={
              isPreview ? cx(styles.editBtn, styles.hidden) : cx(styles.editBtn)
            }
            form="editForm"
            type="submit"
          >
            บันทึก
          </button>
        </div>
      </div>
      <AnnouncementsForm
        id="editForm"
        onSubmit={onSubmit}
        initialValues={data}
        setIsPreview={setIsPreview}
        isPreview={isPreview}
      />
    </Container>
  );
}

export default authorize(enhancer(EditAnnouncements), {
  roles: "announcement",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["modify"],
});
