const DEFAULT_VALUES = {
  name: "",
  salesGroup: "",
  branchCode: "",
  tqmTeamId: "",
  affiliateLeaderId: "",
  selectedMember: "",
  affiliateMembers: [],
};

export { DEFAULT_VALUES };
