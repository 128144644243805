import { Route, Switch } from "react-router-dom";
import Team from "./root";
import TeamId from "./_id";
import NewTeam from "./new";
import EditTeam from "./edit";

const Pages = (props) => (
  <Switch>
    <Route exact path="/team/new" component={NewTeam} />
    <Route exact path="/team/:id/edit" component={EditTeam} />
    <Route exact path="/team/:id" component={TeamId} />
    <Route exact path="/team" component={Team} />
  </Switch>
);

export default Pages;
