import React from "react";
import { Link } from "react-router-dom";
import Icon from "components/Icon";

import breadcrumbStyles from "./Breadcrumb.module.css";

function Breadcrumb(props) {
  const { className = "", links = [] } = props;
  return (
    <nav aria-label="breadcrumb" className={className}>
      <ol className={breadcrumbStyles.container}>
        {links.map((item, index) => (
          <React.Fragment key={index}>
            <li
              className="small"
              {...(item.current && { "aria-current": "page" })}
            >
              {item.link ? (
                <Link
                  to={item.link}
                  className="ghost"
                  onClick={() => item.reload && window.location.reload()}
                >
                  {item.label}
                </Link>
              ) : (
                item.label
              )}
            </li>
            {index < links.length - 1 && (
              <Icon
                name="right-arrow"
                className={`${breadcrumbStyles.separator}`}
              />
            )}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
