import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import useToggle from "hooks/useToggle";

const enhancer = compose(
  withHooks((props) => {
    const { data = [], nameThInfo = {}, initialOpen = false } = props;
    const [isShow, toggle] = useToggle(initialOpen);

    return {
      isShow: data.length === 0 ? false : isShow,
      toggle,
      disabled: data.length === 0,
      data: props.data || {},
      nameThInfo: nameThInfo,
    };
  }),
  mapProps(({ data, nameThInfo, isShow, disabled, toggle }) => ({
    data,
    nameThInfo,
    isShow,
    disabled,
    toggle,
  }))
);

export default enhancer;
