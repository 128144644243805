import { useEffect, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import { filterObjByArray } from "utils/helper";
import { getAgentById, editAgentById, sendEmailAgain } from "store/agentSlice";
import { DEFAULT_ERROR } from "./EditAgent.utils";
import useToggle from "hooks/useToggle";

const enhancer = compose(
  withHooks((props) => {
    const { id = "" } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { agent, isLoading } = useSelector((state) => state.agent);
    const [errorRecruit, setErrorRecruit] = useState(DEFAULT_ERROR);
    const [intitialRecruit, setIntitialRecruit] = useState(false);

    const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

    useEffect(() => {
      dispatch(getAgentById(id));
    }, [id, dispatch]);

    useEffect(() => {
      return () => {
        setErrorRecruit(DEFAULT_ERROR);
      };
    }, []);

    const onSubmit = useCallback(
      (values) => {
        delete values.nextRecruitmentedAt;
        let Rejected = false;
        (values?.agentDocuments || []).forEach((document) => {
          if (document.status === "rejected") {
            Rejected = true;
          }
        });
        if (!Rejected) {
          (values?.agentImportantDocuments || []).forEach((document) => {
            if (document.status === "rejected") {
              Rejected = true;
            }
          });
        }
        if (!Rejected) {
          (values?.agentLicenses || []).forEach((document) => {
            if (document.status === "rejected") {
              Rejected = true;
            }
          });
        }
        dispatch(
          editAgentById(id, { ...values, type: "update" }, () => {
            if (Rejected) {
              handleOpenModal();
            } else {
              history.push(`/agents/${id}`);
            }
          })
        );
      },
      [dispatch, history, id, handleOpenModal]
    );

    const sendEmail = useCallback(
      (values, pass = false) => {
        if (pass) {
          history.push(`/agents/${id}`);
        } else {
          dispatch(
            sendEmailAgain(id, values, () => {
              history.push(`/agents/${id}`);
            })
          );
        }
      },
      [dispatch, history, id]
    );

    const handleChangeRecruit = (values = "", name = "") => {
      let error = {};
      if (values === "") {
        error[name] = "กรุณากรอกข้อมูล";
      } else {
        error[name] = null;
      }
      setErrorRecruit({ ...errorRecruit, ...error });
    };

    const updateAgentType = useCallback(
      (values, name, type) => {
        let errors = [];
        let vals = [];
        let keyName =
          name === "affiliate"
            ? "affiliate"
            : name === "life_broker"
            ? "lifeBroker"
            : "nonLifeBroker";
        if (type === "success") {
          let newErr = errorRecruit;
          delete newErr.uplineId;
          if (name === "affiliate") {
            delete newErr.staffCode;
            errors = Object.values(newErr);
            vals = Object.values(
              filterObjByArray(values.recruits[keyName], [
                "teamId",
                "mkPlanId",
                "positionLevel",
              ])
            );
          } else {
            errors = Object.values(newErr);
            vals = Object.values(
              filterObjByArray(values.recruits[keyName], [
                "staffCode",
                "teamId",
                "mkPlanId",
                "positionLevel",
              ])
            );
          }
          if (
            errors.every((error) => error === null) &&
            !vals.some((val) => val === "" || val === null)
          ) {
            delete values.nextRecruitmentedAt;
            dispatch(
              editAgentById(
                id,
                {
                  ...values,
                  type: "approve_recruitment",
                  recruitmentType: name,
                },
                () => {
                  history.push(`/agents/${id}`);
                }
              )
            );
          } else {
            let error = DEFAULT_ERROR;
            if (
              ["lifeBroker", "nonLifeBroker"].includes(keyName) &&
              ["", null].includes(values?.recruits[keyName]?.staffCode)
            ) {
              error.staffCode = "กรุณากรอกข้อมูล";
            }
            if (["", null].includes(values?.recruits[keyName]?.teamId)) {
              error.teamId = "กรุณากรอกข้อมูล";
            }
            if (["", null].includes(values?.recruits[keyName]?.mkPlanId)) {
              error.mkPlanId = "กรุณากรอกข้อมูล";
            }
            if (["", null].includes(values?.recruits[keyName]?.positionLevel)) {
              error.positionLevel = "กรุณากรอกข้อมูล";
            }
            // if (["", null].includes(values?.recruits[keyName]?.uplineId)) {
            //   error.uplineId = "กรุณากรอกข้อมูล";
            // }
            setErrorRecruit(error);
            setIntitialRecruit(!intitialRecruit);
          }
        } else {
          delete values.nextRecruitmentedAt;
          dispatch(
            editAgentById(
              id,
              {
                ...values,
                agentStatus: values.recruits[type]["agentStatus"],
                type: "approve_recruitment",
                recruitmentType: name,
              },
              () => {
                history.push(`/agents/${id}`);
              }
            )
          );
        }
      },
      [
        dispatch,
        history,
        id,
        errorRecruit,
        setErrorRecruit,
        intitialRecruit,
        setIntitialRecruit,
      ]
    );

    return {
      id,
      isLoading,
      data: agent,
      onSubmit,
      sendEmail,
      updateAgentType,
      errorRecruit,
      handleChangeRecruit,
      showModal,
      handleCloseModal,
    };
  }),
  mapProps((props) =>
    filterObjByArray(props, [
      "id",
      "isLoading",
      "data",
      "onSubmit",
      "sendEmail",
      "updateAgentType",
      "errorRecruit",
      "handleChangeRecruit",
      "showModal",
      "handleCloseModal",
    ])
  )
);

export default enhancer;
