import { useEffect, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import Icon from "components/Icon";
import { Formik, FieldArray, Field as FormikField } from "formik";
import cx from "classnames";
import merge from "lodash/merge";
import isEqual from "lodash/isEqual";

import Modal from "components/Modal";
import Input from "components/Form/Element/Input";
import Gender from "components/Form/Element/Gender";
import Selector from "components/Form/Element/Selector";
import DatePicker from "components/Form/Element/DatePicker";
import DEFAULT_AVATAR from "assets/images/avatar_agent.png";
import Address from "components/Form/Element/Address";

import { dateFormatterFromPattern } from "utils/formatter";
import validation from "./AgentForm.validation";
import { DEFAULT_VALUES } from "./AgentForm.utils";
import enhancer from "./AgentForm.compose";
import styles from "./AgentForm.module.css";
import { getYearsOptions } from "../Element/DatePicker/DatePicker.utils";
import AffiliateForm from "./AffiliateForm";
import NonLifeBrokerForm from "./NonLifeBrokerForm";
import LifeBrokerForm from "./LifeBrokerForm";

const HomeAddress = () => {
  return (
    <>
      <p style={{ marginBottom: 24 }}>ที่อยู่ตามทะเบียนบ้าน</p>
      <Address name="homeAddress" />
    </>
  );
};

const IdCardAddress = ({ hidden = false, onChange = () => {} }) => {
  return (
    <>
      <p style={{ marginBottom: 24 }}>ที่อยู่ตามบัตรประชาชน</p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <FormikField
          name="isSameHomeAddress"
          type="checkbox"
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <label style={{ marginLeft: "16px" }}>
          เหมือนที่อยู่ตามทะเบียนบ้าน
        </label>
      </div>
      {!hidden && <Address name="idCardAddress" />}
    </>
  );
};

const CurrentAddress = ({ hidden = false, onChange = () => {} }) => {
  return (
    <>
      <p style={{ marginBottom: 24 }}>ที่อยู่ในการส่งเอกสาร</p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <FormikField
          name="isSameIdCardAddress"
          type="checkbox"
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <label style={{ marginLeft: "16px" }}>
          เหมือนที่อยู่ตามบัตรประชาชน
        </label>
      </div>
      {!hidden && <Address name="currentAddress" />}
    </>
  );
};

function NewSendEmail({
  disabled,
  sendEmail = () => {},
  showModal,
  handleCloseModal,
}) {
  const onSubmit = () => {
    handleCloseModal();
    let values = { params: disabled ? "rejected" : "updated" };
    sendEmail(values);
  };
  const onCancel = () => {
    handleCloseModal();
    sendEmail({}, true);
  };

  const text = useMemo(() => {
    if (disabled) {
      return (
        <p>
          ต้องการส่งอีเมลแจ้ง <b>ไม่อนุมัติการสมัคร</b>
          <br />
          ไปยังผู้สมัครด้วยหรือไม่ ?
        </p>
      );
    }

    return (
      <p>
        ต้องการส่งอีเมลแจ้ง <b>แก้ไขเอกสาร</b>
        <br />
        ไปยังผู้สมัครด้วยหรือไม่ ?
      </p>
    );
  }, [disabled]);

  return (
    <Modal
      isOpen={showModal}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onClose={handleCloseModal}
      contentLabel="SendEmail Modal"
      submitText="ส่งอีเมล"
      cancelText="ไม่ส่งอีเมล"
    >
      {text}
    </Modal>
  );
}

const Form = ({
  id = "",
  options = [],
  sendEmail = () => {},
  updateAgentType = () => {},
  errorRecruit,
  handleChangeRecruit = () => {},
  showModal,
  handleCloseModal,
  formikProps,
}) => {
  const { values = {}, setFieldValue, handleSubmit } = formikProps;

  const disabled = useMemo(() => {
    return (
      values.agentStatus === "ปฏิเสธถาวร (Blacklist)" ||
      values.agentStatus === "ปฏิเสธชั่วคราว (1 ปี)"
    );
  }, [values.agentStatus]);

  useEffect(() => {
    if (
      isEqual(values.homeAddress, values.idCardAddress) &&
      !values.isSameHomeAddress
    ) {
      setFieldValue("isSameHomeAddress", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.homeAddress, values.idCardAddress]);

  useEffect(() => {
    if (values.isSameHomeAddress) {
      setFieldValue("idCardAddress", values.homeAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.isSameHomeAddress, values.homeAddress]);

  useEffect(() => {
    if (
      isEqual(values.idCardAddress, values.currentAddress) &&
      !values.isSameIdCardAddress
    ) {
      setFieldValue("isSameIdCardAddress", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.idCardAddress, values.currentAddress]);

  useEffect(() => {
    if (values.isSameIdCardAddress) {
      setFieldValue("currentAddress", values.idCardAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.isSameIdCardAddress, values.idCardAddress]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <section className={styles.portfolio}>
        <div className={styles.editbtn}>
          <Link to={`/agents/${id}/history`}>
            <button className="secondary" type="button" disabled>
              ประวัติการแก้ไข
            </button>
          </Link>
          <button type="submit" style={{ marginLeft: 24 }}>
            บันทึก
          </button>
          <NewSendEmail
            sendEmail={sendEmail}
            disabled={disabled}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
        </div>
        <img
          src={values.avatar || DEFAULT_AVATAR}
          alt="agent avatar"
          className={styles.logo}
        />
        <div className={styles.newInterview}>
          <div className={styles.rowInterview}>
            <div className={styles.columnInterview}>
              <Selector
                label="สถานะ"
                name="agentStatus"
                className={styles.agentStatus}
                options={options?.agentStatusOptions || []}
                width="100%"
              />
            </div>
            <div className={cx(styles.columnInterview, styles.lastUpdatedBy)}>
              <small>ผู้อัปเดตล่าสุด : {values?.lastUpdatedBy || "-"}</small>
              <small>
                วันที่อัปเดต :{" "}
                {values?.updatedAt
                  ? dateFormatterFromPattern(values.updatedAt)
                  : "-"}
              </small>
            </div>
          </div>
        </div>
        <AffiliateForm
          values={values}
          setFieldValue={setFieldValue}
          options={options}
          errorRecruit={errorRecruit}
          handleChangeRecruit={handleChangeRecruit}
          updateAgentType={updateAgentType}
        />
        <LifeBrokerForm
          values={values}
          setFieldValue={setFieldValue}
          options={options}
          errorRecruit={errorRecruit}
          handleChangeRecruit={handleChangeRecruit}
          updateAgentType={updateAgentType}
        />
        <NonLifeBrokerForm values={values} />
        <div className={styles.personal}>
          <div className={styles.row}>
            <Selector
              label="คำนำหน้าชื่อ"
              name="prefixName"
              className={styles.prefixName}
              options={[
                { label: "นาย", value: "นาย" },
                { label: "นางสาว", value: "นางสาว" },
                { label: "นาง", value: "นาง" },
                { label: "ด.ช.", value: "ด.ช." },
                { label: "ด.ญ.", value: "ด.ญ." },
              ]}
              width="100%"
            />
            <Input
              label="ชื่อจริง"
              className={styles.firstName}
              name="firstName"
              placeholder="โปรดระบุ"
            />
            <Input
              label="นามสกุล"
              className={styles.lastName}
              name="lastName"
              placeholder="โปรดระบุ"
            />
          </div>
          <div className={styles.row}>
            <Gender name="gender" label="เพศ" className={styles.gender} />
            <DatePicker
              className={styles.datepicker}
              label="วันเกิด"
              name="birthdate"
              showSelector
              maxDate={new Date()}
              yearOptions={getYearsOptions(null, "desc", 50)}
            />
          </div>
          <div className={styles.row}>
            <Input
              label="เลขที่ประจำตัวประชาชน"
              className={styles.inputHalf}
              name="idCard"
              placeholder="โปรดระบุ"
            />
          </div>
          <div className={styles.row}>
            <Input
              label="เบอร์โทรศัพท์"
              className={styles.inputHalf}
              name="phoneNumber"
              placeholder="โปรดระบุ"
              disabled
              required
            />
            <Input
              label="อีเมล"
              className={styles.inputHalf}
              name="email"
              placeholder="โปรดระบุ"
              disabled
              required
            />
          </div>
          <HomeAddress />
          <IdCardAddress
            subname="isSameHomeAddress"
            title="ที่อยู่ตามบัตรประชาชน"
            subtitle="เหมือนที่อยู่ตามทะเบียนบ้าน"
            onChange={(e) => {
              setFieldValue("isSameHomeAddress", e);
            }}
            hidden={values.isSameHomeAddress}
          />
          <CurrentAddress
            subname="isSameIdCardAddress"
            title="ที่อยู่ในการส่งเอกสาร"
            subtitle="เหมือนที่อยู่ตามบัตรประชาชน"
            onChange={(e) => {
              setFieldValue("isSameIdCardAddress", e);
            }}
            hidden={values.isSameIdCardAddress}
          />
          <Selector
            label="บริษัทประกันชีวิตต้นสังกัด"
            name="insuranceCompanyName"
            className={styles.insuranceCompanyName}
            options={options?.insuranceCompanyNameOptions || []}
            width="100%"
          />
        </div>
      </section>
      <section className={styles.documents}>
        <div className={styles.agentDocuments}>
          <div className={styles.notification}>
            <div className={`${styles.notificationLabel}`}>
              <label>ชี้แจงสำคัญ</label>
              <Icon name="info" className={styles.iconInfo} />
            </div>
            <Input
              className={`${styles.notificationInput}`}
              name="explain"
              placeholder="โปรดระบุ"
            />
          </div>
          <b style={{ paddingLeft: 16 }}>เอกสารการสมัคร</b>
          <div className={styles.header}>
            <div style={{ paddingLeft: 16 }}>เอกสาร</div>
            <div>สถานะ</div>
            <div>วันอัปเดต</div>
            <div>คำแนะนำ</div>
          </div>
          <FieldArray
            name="agentDocuments"
            render={(arrayHelpers) => {
              const addDocument = () =>
                arrayHelpers.push({
                  name: "",
                  description: "",
                  status: "new",
                });

              return (
                <>
                  {(values.agentDocuments || []).map((agentDocument, index) => {
                    const onRemove = () => arrayHelpers.remove(index);

                    return (
                      <Fragment key={index}>
                        {!agentDocument.isDefault &&
                        !agentDocument.documentImageId ? (
                          <div className={styles.newRow}>
                            <div className={styles.groupDescription}>
                              <div className={styles.bin} onClick={onRemove}>
                                <Icon name="close" />
                              </div>
                              <Input
                                className={styles.name}
                                name={`agentDocuments.${index}.name`}
                                placeholder="ระบุชื่อเอกสาร"
                              />
                            </div>
                            <Input
                              className={styles.description}
                              name={`agentDocuments.${index}.description`}
                              placeholder="ระบุคำแนะนำ"
                            />
                          </div>
                        ) : (
                          <div className={styles.row}>
                            <div
                              style={{ paddingLeft: 16, paddingRight: 8 }}
                              className={styles.name}
                            >
                              <Icon
                                name="upload"
                                className={
                                  agentDocument.documentImageId
                                    ? styles.iconUpload
                                    : styles.iconUploadDisabled
                                }
                              />
                              <label
                                style={
                                  agentDocument.documentImageId
                                    ? {
                                        color: "var(--agt-primary-color)",
                                      }
                                    : {
                                        color: "var(--agt-secondary-color-1)",
                                        cursor: "not-allowed",
                                      }
                                }
                              >
                                {agentDocument.name}
                              </label>
                            </div>
                            <Selector
                              name={`agentDocuments.${index}.status`}
                              className={styles.status}
                              options={(
                                options?.documentStatusOptions || []
                              ).filter(
                                (option) => !["expired"].includes(option.value)
                              )}
                              disabled={
                                !agentDocument.documentImageId || disabled
                              }
                              width="100%"
                            />
                            <div>
                              {dateFormatterFromPattern(
                                agentDocument.updatedAt
                              )}
                            </div>
                            <Input
                              className={styles.description}
                              name={`agentDocuments.${index}.description`}
                              placeholder="ระบุคำแนะนำ"
                              disabled={disabled}
                            />
                            {!agentDocument.isDefault && (
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className={`secondary ${styles.newBin}`}
                              >
                                <Icon name="bin" />
                              </button>
                            )}
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                  <button
                    type="button"
                    className={cx("secondary", styles.addDocuments)}
                    onClick={addDocument}
                    disabled={disabled}
                  >
                    + เพิ่มรายการเอกสาร
                  </button>
                </>
              );
            }}
          />
        </div>
        <div className={styles.agentImportant}>
          <b style={{ paddingLeft: 16 }}>สำเนาเอกสารสำคัญ</b>
          <FieldArray
            name="agentImportantDocuments"
            render={(arrayHelpers) => {
              const addDocument = () =>
                arrayHelpers.push({
                  name: "",
                  description: "",
                  status: "new",
                });

              return (
                <>
                  {(values.agentImportantDocuments || []).map(
                    (agentImportantDocument, index) => {
                      const onRemove = () => arrayHelpers.remove(index);

                      return (
                        <Fragment key={index}>
                          {!agentImportantDocument.isDefault &&
                          !agentImportantDocument.importantDocumentImageId ? (
                            <div className={styles.newRow}>
                              <div className={styles.groupDescription}>
                                <div className={styles.bin} onClick={onRemove}>
                                  <Icon name="close" />
                                </div>
                                <Input
                                  className={styles.name}
                                  name={`agentImportantDocuments.${index}.name`}
                                  placeholder="ระบุชื่อเอกสาร"
                                />
                              </div>
                              <Input
                                className={styles.description}
                                name={`agentImportantDocuments.${index}.description`}
                                placeholder="ระบุคำแนะนำ"
                              />
                            </div>
                          ) : (
                            <div className={styles.row}>
                              <div
                                style={{ paddingLeft: 16, paddingRight: 8 }}
                                className={styles.name}
                              >
                                <Icon
                                  name="upload"
                                  className={
                                    agentImportantDocument.importantDocumentImageId
                                      ? styles.iconUpload
                                      : styles.iconUploadDisabled
                                  }
                                />
                                <label
                                  style={
                                    agentImportantDocument.importantDocumentImageId
                                      ? {
                                          color: "var(--agt-primary-color)",
                                        }
                                      : {
                                          color: "var(--agt-secondary-color-1)",
                                          cursor: "not-allowed",
                                        }
                                  }
                                >
                                  {agentImportantDocument.name}
                                </label>
                              </div>
                              <Selector
                                name={`agentImportantDocuments.${index}.status`}
                                className={styles.status}
                                options={(
                                  options?.documentStatusOptions || []
                                ).filter(
                                  (option) =>
                                    !["expired"].includes(option.value)
                                )}
                                disabled={
                                  !agentImportantDocument.importantDocumentImageId ||
                                  disabled
                                }
                                width="100%"
                              />
                              <div>
                                {dateFormatterFromPattern(
                                  agentImportantDocument.updatedAt
                                )}
                              </div>
                              <Input
                                className={styles.description}
                                name={`agentImportantDocuments.${index}.description`}
                                placeholder="ระบุคำแนะนำ"
                                disabled={disabled}
                              />
                              {!agentImportantDocument.isDefault && (
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  className={`secondary ${styles.newBin}`}
                                >
                                  <Icon name="bin" />
                                </button>
                              )}
                            </div>
                          )}
                        </Fragment>
                      );
                    }
                  )}
                  <button
                    type="button"
                    className={cx("secondary", styles.addDocuments)}
                    onClick={addDocument}
                    disabled={disabled}
                  >
                    + เพิ่มรายการเอกสาร
                  </button>
                </>
              );
            }}
          />
        </div>
        <div className={styles.agentLicense}>
          <b style={{ paddingLeft: 16 }}>เลขที่ใบอนุญาต</b>
          <div className={styles.header}>
            <div style={{ paddingLeft: 16 }}>เลขที่</div>
            <div>ประเภทใบอนุญาต</div>
            <div>สถานะ</div>
            <div>วันหมดอายุ</div>
            <div>วันที่อัปเดต</div>
          </div>
          <FieldArray
            name="agentLicenses"
            render={(arrayHelpers) => {
              const addDocument = () =>
                arrayHelpers.push({
                  licenseCode: "",
                  licenseImageId: "",
                  status: "new",
                  licenseType: "ตัวแทนประกันชีวิต",
                });

              return (
                <>
                  {(values.agentLicenses || []).map((agentLicense, index) => {
                    return (
                      <Fragment key={index}>
                        {!agentLicense.licenseImageId ? (
                          <div className={styles.newSubrow}>
                            <Selector
                              name={`agentLicenses.${index}.licenseType`}
                              className={styles.licenseType}
                              options={options?.licenseTypeOptions}
                              width="100%"
                            />
                            <div className={styles.groupDescription}>
                              <div
                                className={styles.bin}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <Icon name="close" />
                              </div>
                              <Input
                                className={styles.description}
                                name={`agentLicenses.${index}.description`}
                                placeholder="ระบุคำแนะนำ"
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className={styles.row}>
                              <div
                                style={{
                                  paddingLeft: 16,
                                  paddingRight: 8,
                                }}
                                className={styles.name}
                              >
                                <Icon
                                  name="upload"
                                  className={styles.iconUpload}
                                />
                                <Input
                                  className={styles.licenseId}
                                  name={`agentLicenses.${index}.licenseCode`}
                                  placeholder="โปรดระบุ"
                                  disabled={
                                    disabled ||
                                    agentLicense.status === "expired" ||
                                    !agentLicense.licenseImageId
                                  }
                                />
                              </div>
                              <Selector
                                name={`agentLicenses.${index}.licenseType`}
                                className={styles.licenseType}
                                options={options?.licenseTypeOptions}
                                disabled={
                                  disabled || agentLicense.status === "expired"
                                }
                                width="100%"
                              />
                              <Selector
                                name={`agentLicenses.${index}.status`}
                                className={styles.status}
                                options={
                                  agentLicense.status === "expired"
                                    ? options?.documentStatusOptions
                                    : (
                                        options?.documentStatusOptions || []
                                      ).filter(
                                        (option) =>
                                          !["expired"].includes(option.value)
                                      )
                                }
                                disabled={
                                  disabled || agentLicense.status === "expired"
                                }
                                width="100%"
                              />
                              <DatePicker
                                className={styles.endDate}
                                name={`agentLicenses.${index}.licenseExpiredAt`}
                                disabled={
                                  disabled || agentLicense.status === "expired"
                                }
                                yearOptions={getYearsOptions(null, "desc", 50)}
                                showSelector
                              />
                              <small>
                                {dateFormatterFromPattern(
                                  agentLicense.updatedAt
                                )}
                              </small>
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className={`secondary ${styles.newBin}`}
                              >
                                <Icon name="bin" />
                              </button>
                            </div>
                            <div className={styles.subrow}>
                              <div />
                              <Input
                                className={styles.description}
                                name={`agentLicenses.${index}.description`}
                                placeholder="ระบุคำแนะนำ"
                                disabled={disabled}
                              />
                            </div>
                          </>
                        )}
                      </Fragment>
                    );
                  })}
                  {values.agentLicenses.length < 5 && (
                    <button
                      type="button"
                      className={cx("secondary", styles.addLicenses)}
                      onClick={addDocument}
                      disabled={disabled}
                    >
                      + เพิ่มรายการใบอนุญาต
                    </button>
                  )}
                </>
              );
            }}
          />
        </div>
      </section>
    </form>
  );
};

const AgentForm = (props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={merge({}, DEFAULT_VALUES, props.initialValues)}
      validationSchema={validation}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return <Form {...props} formikProps={formikProps} />;
      }}
    </Formik>
  );
};

export default enhancer(AgentForm);
