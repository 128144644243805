import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import { useHistory } from "react-router-dom";
import withHooks from "lib/withHooks";

import { createAdmin } from "store/adminSlice";

const enhancer = compose(
  withHooks((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.admin);

    const onSubmit = (values) => {
      let modifiedValues = { ...values, ...values.permission };
      delete modifiedValues.permission;
      dispatch(
        createAdmin(modifiedValues, () => {
          history.push("/admin");
        })
      );
    };

    return {
      onSubmit,
      isLoading,
    };
  }),
  mapProps(({ onSubmit, isLoading }) => ({
    onSubmit,
    isLoading,
  }))
);

export default enhancer;
