import React, { useState, useCallback } from "react";
import cx from "classnames";
import { useField } from "formik";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";

import { Selector } from "components/Form/Element/Selector/Selector";
import inputStyles from "components/Form/Element/Input/Input.module.css";
import datePickerStyles from "./DatePicker.module.css";
import Icon from "components/Icon";

import {
  MONTH_TH,
  YEAR_OPTIONS,
  MONTH_OPTIONS,
  dateFormatterFromPattern,
} from "./DatePicker.utils";

registerLocale("th", th);

function DatePicker({
  className = "",
  subClassName = "",
  label = "",
  name = "",
  required,
  onChange = () => {},
  showIcon = true,
  dateFormat = "dd/MM/yyyy",
  disabled,
  minDate = null,
  maxDate = null,
  showSelector = false,
  yearOptions = [],
  usePortalId = true,
}) {
  const [UUID] = useState(
    () => `date-picker_${Math.floor(Math.random() * 10000)}`
  );
  const [field, meta, helpers] = useField(name);
  const { onBlur } = field;
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = useCallback(
    (value) => {
      setValue(value);
      if (value) onChange(value);
    },
    [setValue, onChange]
  );

  const CustomInput = (props, ref) => {
    const { onClick } = props;
    let formattedDate = dateFormatterFromPattern(value, dateFormat);

    return (
      <div className={cx(datePickerStyles.inputContainer, subClassName)}>
        <input
          id={name}
          name={name}
          className={datePickerStyles.input}
          value={formattedDate}
          onClick={onClick}
          onBlur={onBlur}
          placeholder="โปรดระบุ"
          readOnly
          data-state={touched && error ? "error" : ""}
          disabled={disabled}
        />
        {showIcon && (
          <div
            onClick={() => {
              if (disabled) return;
              onClick();
            }}
          >
            <Icon name="calendar" className={datePickerStyles.calendar} />
          </div>
        )}
      </div>
    );
  };

  const CustomHeader = (headerProps) => {
    const {
      date,
      decreaseMonth,
      increaseMonth,
      changeMonth,
      changeYear,
    } = headerProps;
    const month = MONTH_TH[date.getMonth()];
    const useYearOptions = yearOptions.length > 0 ? yearOptions : YEAR_OPTIONS;

    return (
      <div className={datePickerStyles.header}>
        <div onClick={() => decreaseMonth()}>
          <Icon name="left-arrow" className={datePickerStyles.arrow} />
        </div>
        {showSelector ? (
          <div className={datePickerStyles.selector}>
            <Selector
              value={MONTH_OPTIONS.find((m) => m.label === month)}
              options={MONTH_OPTIONS}
              width={100}
              styles={{
                indicatorsContainer: () => ({ display: "none" }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "2px 8px",
                }),
              }}
              onChange={(e) => {
                changeMonth(e.value);
              }}
            />
            <Selector
              value={useYearOptions.find((m) => m.value === date.getFullYear())}
              options={useYearOptions}
              width={100}
              styles={{
                indicatorsContainer: () => ({ display: "none" }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "2px 8px",
                }),
              }}
              onChange={(e) => {
                changeYear(e.value);
              }}
            />
          </div>
        ) : (
          <div>{dateFormatterFromPattern(date, "MMMM yyyy")}</div>
        )}
        <div onClick={() => increaseMonth()}>
          <Icon name="right-arrow" className={datePickerStyles.arrow} />
        </div>
      </div>
    );
  };

  return (
    <div className={cx(inputStyles.container, className)}>
      {label && (
        <label className={`label ${inputStyles.label}`} htmlFor={name}>
          {label}
          {required && <span className="error">*</span>}
        </label>
      )}
      <ReactDatePicker
        id={UUID}
        locale="th"
        value={value}
        selected={value}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        renderCustomHeader={CustomHeader}
        customInput={React.createElement(React.forwardRef(CustomInput))}
        showPopperArrow={false}
        popperPlacement="bottom-start"
        popperModifiers={[
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom", "top"],
            },
          },
          {
            name: "preventOverflow",
            options: {
              enabled: false,
            },
          },
        ]}
        portalId={usePortalId ? UUID : null}
      />
      {touched && error && (
        <span className={`error small ${inputStyles.error}`}>{error}</span>
      )}
    </div>
  );
}

export default DatePicker;
