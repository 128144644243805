import React from "react";
import cx from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Input from "components/Form/Element/Input";
import Logo from "assets/images/friday_biz_backoffice.png";

import styles from "./LoginForm.module.css";

const LoginSchema = Yup.object().shape({
  adminCode: Yup.string().required("กรุณากรอกข้อมูล"),
  password: Yup.string()
    .min(8, "รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร")
    .required("กรุณากรอกข้อมูล"),
});

const DEFAULT_VALUES = { adminCode: "", password: "" };

function LoginForm(props) {
  const { className = "", initialValues = {}, onSubmit = () => {} } = props;
  return (
    <Formik
      initialValues={{ ...DEFAULT_VALUES, ...initialValues }}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={cx(styles.form, className)}>
          <img className={styles.logo} src={Logo} alt="TQM agent portal logo" />
          <Input
            label="บัญชีผู้ใช้งาน (รหัสพนักงาน)"
            name="adminCode"
            placeholder="โปรดระบุ"
          />
          <div className={styles.password}>
            <Input
              label="รหัสผ่าน"
              name="password"
              placeholder="โปรดระบุ"
              type="password"
            />
            <Link to="/forget_password">
              <small className={styles.forgetPass}>ลืมรหัสผ่าน?</small>
            </Link>
          </div>
          <button className={styles.btn} type="submit" disabled={isSubmitting}>
            เข้าสู่ระบบ
          </button>
        </form>
      )}
    </Formik>
  );
}

export default LoginForm;
