import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./Login";
import Agent from "./Agent";
import Team from "./Team";
import Affiliate from "./Affiliate";
import Marketing from "./Marketing";
import Admin from "./Admin";
import Consent from "./Consent";
import Announcements from "./Announcements";
import Commission from "./Commission";
import Example from "./Example";
import ForgetPassword from "./ForgetPassword";
import SetupPassword from "./SetupPassword";
import ResetPassword from "./ResetPassword";
import Notification from "./Notification";

function Pages() {
  const isLogin = useSelector((state) => state.auth.isLogin);

  let routes = null;

  if (isLogin) {
    routes = (
      <Switch>
        <Route path="/agents" component={Agent} />
        <Route path="/team" component={Team} />
        <Route path="/affiliate" component={Affiliate} />
        <Route path="/marketing_plan" component={Marketing} />
        <Route path="/admin" component={Admin} />
        <Route path="/consent" component={Consent} />
        <Route path="/announcements" component={Announcements} />
        <Route path="/commission" component={Commission} />
        <Route path="/notification" component={Notification} />
        <Route path="/example" component={Example} />
        <Redirect to="/agents" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forget_password" component={ForgetPassword} />
        <Route exact path="/setup_password" component={SetupPassword} />
        <Route exact path="/reset_password" component={ResetPassword} />
        <Redirect to="/login" />
      </Switch>
    );
  }

  return routes;
}

export default Pages;
