import * as Yup from "yup";

const MarketingSchema = Yup.object().shape({
  name: Yup.string().required("กรุณากรอกข้อมูล"),
  numberOfLevel: Yup.string().required("กรุณากรอกข้อมูล"),
  mkCommissionStructures: Yup.array().of(
    Yup.object().shape({
      beginDate: Yup.date().required("กรุณากรอกข้อมูล"),
      mkCommissionRates: Yup.array().of(
        Yup.object().shape({
          positionName: Yup.string().required("กรุณากรอกข้อมูล"),
          commissionPercent: Yup.number()
            .typeError("กรุณากรอกตัวเลขเท่านั้น")
            // .moreThan(0, "กรุณากรอกข้อมูล")
            .required("กรุณากรอกข้อมูล"),
          managementPercent: Yup.number()
            .typeError("กรุณากรอกตัวเลขเท่านั้น")
            // .moreThan(0, "กรุณากรอกข้อมูล")
            .required("กรุณากรอกข้อมูล"),
        })
      ),
    })
  ),
});

export default MarketingSchema;
