import { Link } from "react-router-dom";
import Icon from "./../../../components/Icon";
import cx from "classnames";

import enhancer from "./Menu.compose";
import { MENU } from "utils/constants";
import styles from "./Menu.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

function Menu({
  className = "",
  pathname = "",
  isMenuOpen = false,
  onToggleMenu = () => {},
  onCloseMenu = () => {},
}) {
  return (
    <div className={cx(styles.sidebar, className)}>
      {isMenuOpen && <div className={styles.mask} onClick={onCloseMenu} />}
      <nav className={cx(styles.container, !isMenuOpen ? styles.hidden : null)}>
        <div
          className={cx(
            styles.header,
            isMenuOpen ? styles.activatedHeaderMenu : null
          )}
          onClick={onToggleMenu}
        >
          {isMenuOpen ? (
            <>
              เมนู
              <Icon name="single-bar" />
            </>
          ) : (
            <Icon name="bar" />
          )}
        </div>
        <ul className={styles.menuContainer}>
          {MENU.map((menu, i) => {
            const MenuRenderer = ({ disabled = false }) => {
              if (disabled) {
                return (
                  <li
                    className={cx(
                      styles.menu,
                      menu.link === pathname ? styles.activatedMenu : null,
                      !isMenuOpen ? styles.center : null,
                      disabled ? styles.disabledMenu : null
                    )}
                  >
                    {isMenuOpen ? null : (
                      <div className={styles.labelHover}>{menu.label}</div>
                    )}
                    {/* {isMenuOpen ? menu.label : <Icon name={menu.shortLabel} />} */}
                    {isMenuOpen ? (
                      <>
                        <Icon name={menu.shortLabel} />
                        <label>{menu.label}</label>
                      </>
                    ) : (
                      <Icon name={menu.shortLabel} />
                    )}
                  </li>
                );
              }
              return (
                <li
                  className={cx(
                    styles.menu,
                    menu.link === pathname ? styles.activatedMenu : null,
                    !isMenuOpen ? styles.center : null
                  )}
                >
                  {isMenuOpen ? null : (
                    <div className={styles.labelHover}>{menu.label}</div>
                  )}
                  {/* {isMenuOpen ? menu.label : <Icon name={menu.shortLabel} />} */}
                  {isMenuOpen ? (
                    <>
                      <Icon name={menu.shortLabel} />
                      <label>{menu.label}</label>
                    </>
                  ) : (
                    <Icon name={menu.shortLabel} />
                  )}
                </li>
              );
            };
            const LinkMenu = authorize(
              ({ disabled }) => {
                if (disabled) {
                  return <MenuRenderer disabled />;
                }
                return (
                  <Link
                    key={i}
                    to={menu.link}
                    className="ghost"
                    // title={menu.label}
                    onClick={onCloseMenu}
                  >
                    <MenuRenderer />
                  </Link>
                );
              },
              {
                roles: menu.authorize,
                params: { unauthorizedAction: UnauthorizedAction.DISABLED },
                actions: ["read", "crud"],
              }
            );
            if (menu.link) {
              return <LinkMenu />;
            }
            return <MenuRenderer />;
          })}
        </ul>
      </nav>
    </div>
  );
}

export default enhancer(Menu);
