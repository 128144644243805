import cx from "classnames";
import isEmpty from "lodash/isEmpty";

import Icon from "components/Icon";
import useToggle from "hooks/useToggle";
import enhancer from "./Upline.compose";
import styles from "./Upline.module.css";

function UplineTree({ nameThInfo = {}, root = {} }) {
  const { childs = [], value = {} } = root;
  const { firstName = "", lastName = "" } = nameThInfo;
  const [expanded, toggle] = useToggle(true);

  if (isEmpty(nameThInfo)) {
    return null;
  }

  const nestedLeaf = (childs || []).map((leaf, index) => {
    return <UplineTree key={index} nameThInfo={nameThInfo} root={leaf} />;
  });
  const isMe =
    value.name.split(" ")[0] === firstName.trim() &&
    value.name.split(" ")[1] === lastName.trim();
  const step = !isEmpty(childs) ? (
    <div
      className={cx(styles.expander, isMe ? styles.myExpander : null)}
      onClick={toggle}
    >
      <Icon
        name={expanded ? "down-arrow" : "up-arrow"}
        className={styles.icon}
      />
    </div>
  ) : (
    <div className={cx(styles.child, isMe ? styles.isMe : null)} />
  );

  return (
    <div className={styles.plan}>
      <>
        {step}
        <div className={cx(styles.name, isMe ? styles.isMe : null)}>
          <b>{value.name}</b>
        </div>
        <div className={cx(styles.level, isMe ? styles.isMe : null)}>
          {value.levelDisplay}
        </div>
      </>
      {expanded && nestedLeaf}
    </div>
  );
}

function Upline({
  data = {},
  nameThInfo = {},
  isShow,
  disabled,
  toggle = () => {},
}) {
  return (
    <>
      <div
        className={cx(
          "topic",
          styles.header,
          disabled ? styles.disabled : null
        )}
        onClick={disabled ? () => {} : toggle}
      >
        ผังต้นไม้ของอัปไลน์{" "}
        <Icon
          name={isShow ? "down-arrow" : "up-arrow"}
          className={styles.icon}
        />
      </div>
      {isShow && (
        <div className={styles.container}>
          <UplineTree nameThInfo={nameThInfo} root={data || []} />
        </div>
      )}
    </>
  );
}

export default enhancer(Upline);
