import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { getAdminById, editAdminById } from "store/adminSlice";
// import { getInfo } from "store/authSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { admin } = useSelector((state) => state.admin);

    useEffect(() => {
      dispatch(getAdminById(id));
    }, [dispatch, id]);

    const onSubmit = (values) => {
      let modifiedValues = { ...values, ...values.permission };
      delete modifiedValues.permission;
      dispatch(
        editAdminById(id, modifiedValues, () => {
          // dispatch(getInfo());
          history.push(`/admin/${id}`);
          window.location.reload();
        })
      );
    };

    return { id, data: admin, onSubmit };
  }),
  mapProps(({ id, data, onSubmit }) => ({ id, data, onSubmit }))
);

export default enhancer;
