import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";

import enhancer from "./EditTeam.compose";
import styles from "./EditTeam.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import TeamForm from "components/Form/TeamForm";
// import Modal from "components/Modal";

// import useToggle from "hooks/useToggle";
import authorize, { UnauthorizedAction } from "utils/authorize";

// const DeleteTeam = authorize(
//   ({ onDelete }) => {
//     const [isModalOpened, , openModal, closeModal] = useToggle();
//     return (
//       <>
//         <button
//           className={`secondary ${styles.editBtn}`}
//           onClick={openModal}
//         >
//           ลบทีม
//         </button>
//         <Modal
//           contentLabel="Delete team."
//           isOpen={isModalOpened}
//           onSubmit={onDelete}
//           onCancel={closeModal}
//         >
//           ต้องการลบทีมใช่หรือไม่
//         </Modal>
//       </>
//     );
//   },
//   {
//     roles: "team",
//     params: { unauthorizedAction: UnauthorizedAction.HIDDEN },
//     actions: ["delete"],
//   }
// );

function EditTeam({
  data = {},
  isLoading,
  onDelete = () => {},
  onSubmit = () => {},
}) {
  if (data.id) {
    return (
      <Container>
        <h4 className="topic">แก้ไขทีม</h4>
        <div className={containerStyles.breadcrumbWithButton}>
          <Breadcrumb
            links={[
              { label: "จัดการทีมผู้แนะนำ", link: "/team" },
              { label: data.name, link: `/team/${data.id}` },
              { label: "แก้ไขทีม", current: true },
            ]}
          />
          <div>
            {/* <DeleteTeam onDelete={onDelete} /> */}
            <button
              className={styles.btn}
              form="editTeam"
              type="submit"
              disabled={isLoading}
            >
              บันทึก
            </button>
          </div>
        </div>
        <TeamForm id="editTeam" initialValues={data} onSubmit={onSubmit} />
      </Container>
    );
  }

  return null;
}

export default authorize(enhancer(EditTeam), {
  roles: "team",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["modify"],
});
