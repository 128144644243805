import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AnnouncementsForm from "components/Form/AnnouncementsForm";
import cx from "classnames";

import enhancer from "./NewAnnouncements.compose";
import styles from "./NewAnnouncements.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";

function NewAnnouncements({ onSubmit, isPreview = false, setIsPreview }) {
  return (
    <Container>
      <h4 className="topic">ประกาศและข่าวสาร</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "ประกาศและข่าวสาร", link: "/announcements" },
            { label: "สร้าง", current: true },
          ]}
        />
        <div className={styles.groupBtn}>
          <button
            className={
              isPreview ? cx(styles.editBtn) : cx(styles.editBtn, styles.hidden)
            }
            onClick={() => setIsPreview(false)}
            type="button"
          >
            แก้ไข
          </button>
          <button
            className={
              isPreview
                ? cx(styles.submitBtn, styles.hidden)
                : cx(styles.submitBtn)
            }
            form="announcementsForm"
            type="submit"
          >
            บันทึก
          </button>
        </div>
      </div>
      <AnnouncementsForm
        id="announcementsForm"
        onSubmit={onSubmit}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
      />
    </Container>
  );
}

export default enhancer(NewAnnouncements);
