import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import {
  exportCommission,
  getCommissionOptions,
  getCommissions,
} from "store/commissionSlice";
import useQuery from "hooks/useQuery";

const enhancer = compose(
  withHooks(() => {
    const [searchValue, setSearchValue] = useState({
      opportunityKey: "",
      orderNo: "",
      agentName: "",
      teamName: "",
      receivedDate: "",
      policyYear: "",
      installmentNo: "",
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10000,
    });
    const [arrayExcel, setArrayExcel] = useState([]);
    const dispatch = useDispatch();
    const {
      commissions = [],
      paging = {},
      isLoading = false,
      options = [],
    } = useSelector((state) => state.commission);

    const query = useQuery();
    const exportDate = query.get("exportDate");
    const type = query.get("type");

    useEffect(() => {
      dispatch(getCommissionOptions());
    }, [dispatch]);

    useEffect(() => {
      dispatch(getCommissions(searchValue));
    }, [dispatch, searchValue]);

    const onChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    const getReport = useCallback(() => {
      dispatch(
        exportCommission(
          {
            exportDate: exportDate,
            type: type,
            ids: arrayExcel.map((d) => d.id),
          },
          () => {}
        )
      );
    }, [dispatch, arrayExcel, exportDate, type]);

    return {
      data: commissions,
      isLoading,
      paging,
      options,
      onChange,
      searchValue,
      setArrayExcel,
      arrayExcel,
      getReport,
    };
  }),
  mapProps((props) => {
    return {
      data: props.data,
      isLoading: props.isLoading,
      paging: props.paging,
      options: props.options,
      onChange: props.onChange,
      searchValue: props.searchValue,
      setArrayExcel: props.setArrayExcel,
      arrayExcel: props.arrayExcel,
      getReport: props.getReport,
    };
  })
);

export default enhancer;
