import { createSlice } from "@reduxjs/toolkit";
import api from "api";

const consentInitialState = {
  isLoading: false,
  initialized: false,
  consent: {},
  consents: [],
  options: {},
};

const consentReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setConsent(state, action) {
    state.agreement = action.payload.agreement;
  },
  setConsents(state, action) {
    state.agreements = action.payload.agreements;
    state.paging = action.payload.paging;
  },
  setOptions(state, action) {
    state.options = action.payload;
  },
};

export const consentSlice = createSlice({
  name: "consent",
  initialState: consentInitialState,
  reducers: consentReducers,
});

export const {
  setLoading,
  setConsents,
  setConsent,
  setOptions,
} = consentSlice.actions;

export default consentSlice.reducer;

export const getConsents = (params = {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/agreements", params);
    dispatch(setConsents(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getConsentById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/agreements/${id}`);
    dispatch(setConsent(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createConsent = (values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.post("/agreements", values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getConsentOptions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/agreements/options");
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const editConsent = (id, values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/agreements/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteConsent = (id, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.delete(`/agreements/${id}`);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const sendEmailAgain = (id, values) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // await api.post(`/agreements/${id}`, values);
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
