import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const UnauthorizedAction = {
  REDIRECT: "redirect",
  HIDDEN: "hidden",
  DISABLED: "disabled",
};

const Authorize = (Component, options = {}) => {
  const { actions = [], roles = [], params = {} } = options;

  const defaultOptions = {
    unauthorizedAction: UnauthorizedAction.REDIRECT,
    unauthorizedRedirectPath: "/agents",
  };

  const modifiedOptions = { ...defaultOptions, ...params };

  const App = (props) => {
    let history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [passed, setPassed] = useState(false);
    const abilitiesHash = useSelector((state) => state.auth.abilities);

    const actionType = useMemo(() => {
      if (abilitiesHash && roles) {
        return abilitiesHash[roles] || [];
      }
      return null;
    }, [abilitiesHash]);

    useEffect(() => {
      if (actionType) {
        if (!!actionType.find((action) => actions.includes(action))) {
          setPassed(true);
        }
        setInitialized(true);
      }
    }, [actionType]);

    if (initialized && !passed) {
      if (modifiedOptions.unauthorizedAction === UnauthorizedAction.REDIRECT) {
        history.push(modifiedOptions.unauthorizedRedirectPath);
      }
      if (modifiedOptions.unauthorizedAction === UnauthorizedAction.HIDDEN) {
        return null;
      }
      if (modifiedOptions.unauthorizedAction === UnauthorizedAction.DISABLED) {
        return <Component {...props} disabled />;
      }
    }

    if (initialized && passed) return <Component {...props} />;

    return null;
  };

  return App;
};

export const HoC = (fn) => (ComponentOrOption, ...options) => {
  if (typeof ComponentOrOption === "object") {
    return (Component) => fn(Component, ComponentOrOption);
  }
  return fn(ComponentOrOption, ...options);
};

export default HoC(Authorize);
