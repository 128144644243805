import { useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "components/Table";
import styles from "./ConsentTable.module.css";
import { dateFormatterFromPattern } from "utils/formatter";

function ConsentTable({ data = [], options = [], onChange, paging }) {
  const columns = useMemo(
    () => [
      {
        Header: "ชื่อ",
        accessor: "name",
        styles: {
          header: {
            width: "280px",
          },
        },
        Cell: (cell) => {
          return (
            <Link to={`/consent/${cell.row.original.id}`}>{cell.value}</Link>
          );
        },
      },
      {
        Header: "ประเภท",
        accessor: "agreementType",
        styles: {
          header: {
            width: "200px",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {options?.agreementTypeOptions.find(
                (agreementTypeOption) =>
                  agreementTypeOption.value === cell.row.original.agreementType
              )?.label || "-"}
            </div>
          );
        },
      },
      {
        Header: "ส่วนแสดงเนื้อหา",
        accessor: "contentDisplay",
        styles: {
          header: {
            width: "160px",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {options?.contentDisplayOptions.find(
                (contentDisplayOption) =>
                  contentDisplayOption.value ===
                  cell.row.original.contentDisplay
              )?.label || "-"}
            </div>
          );
        },
      },
      {
        Header: "เวอร์ชัน",
        accessor: "version",
        styles: {
          header: {
            width: "110px",
          },
        },
      },
      {
        Header: "สถานะ",
        accessor: "status",
        styles: {
          header: {
            width: "140px",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {options?.statusOptions.find(
                (statusOption) =>
                  statusOption.value === cell.row.original.status
              )?.label || "-"}
            </div>
          );
        },
      },
      {
        Header: "ผู้ให้การยินยอม",
        accessor: "consentBy",
        styles: {
          header: {
            width: "160px",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {options?.consentByOptions.find(
                (consentByOption) =>
                  consentByOption.value === cell.row.original.consentBy
              )?.label || "-"}
            </div>
          );
        },
      },
      {
        Header: "วันที่บังคับใช้",
        accessor: "beginDate",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {dateFormatterFromPattern(cell.row.original.beginDate) || "-"}
            </div>
          );
        },
      },
      {
        Header: "วันที่อัปเดตล่าสุด",
        accessor: "updatedAt",
        styles: {
          header: {
            width: "160px",
            textAlign: "center",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {dateFormatterFromPattern(cell.row.original.updatedAt) || "-"}
            </div>
          );
        },
      },
      {
        Header: "ผู้อัปเดตล่าสุด",
        accessor: "updateBy",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <section className={styles.container}>
      <Table
        columns={columns}
        data={data}
        paging={paging}
        onChange={onChange}
      />
    </section>
  );
}

export default ConsentTable;
