const AUTH_TOKEN = "authToken";
const AUTH_TQM_LIFE_TOKEN = "tqmLifeToken";

// Auth token
const getAuthToken = () => window.localStorage.getItem(AUTH_TOKEN);
const setAuthToken = (authToken) =>
  window.localStorage.setItem(AUTH_TOKEN, authToken);
const removeAuthToken = () => window.localStorage.removeItem(AUTH_TOKEN);
const isAuthTokenPresent = () => getAuthToken() !== null;
const getAuthTqmLifeToken = () =>
  window.localStorage.getItem(AUTH_TQM_LIFE_TOKEN);
const setAuthTqmLifeToken = (authTqmLifeToken) =>
  window.localStorage.setItem(AUTH_TQM_LIFE_TOKEN, authTqmLifeToken);
const removeAuthTqmLifeToken = () =>
  window.localStorage.removeItem(AUTH_TQM_LIFE_TOKEN);
const isAuthTqmLifeTokenPresent = () => getAuthTqmLifeToken() !== null;

const session = {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  isAuthTokenPresent,
  getAuthTqmLifeToken,
  setAuthTqmLifeToken,
  removeAuthTqmLifeToken,
  isAuthTqmLifeTokenPresent,
};

export default session;
