import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { getTeamOptions, getTeams } from "store/teamSlice";
import useToggle from "hooks/useToggle";

const enhancer = compose(
  withHooks((props) => {
    const [isShowFilterBar, toggle] = useToggle();
    const [searchValue, setSearchValue] = useState({
      name: "",
      affiliateLeaderId: "",
      tqmTeamName: "",
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10,
    });
    const dispatch = useDispatch();
    const { teams = [], isLoading, paging = {}, options } = useSelector(
      (state) => state.team
    );

    useEffect(() => {
      dispatch(getTeamOptions());
    }, [dispatch]);

    useEffect(() => {
      dispatch(getTeams(searchValue));
    }, [dispatch, searchValue]);

    const onChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    return {
      data: teams,
      isLoading,
      paging,
      isShowFilterBar,
      toggle,
      onChange,
      searchValue,
      setSearchValue,
      options,
    };
  }),
  mapProps(
    ({
      data,
      isLoading,
      paging,
      isShowFilterBar,
      toggle,
      onChange,
      searchValue,
      setSearchValue,
      options,
      disabled = false,
    }) => ({
      data,
      isLoading,
      paging,
      isShowFilterBar,
      toggle,
      onChange,
      searchValue,
      setSearchValue,
      options,
      disabled,
    })
  )
);

export default enhancer;
