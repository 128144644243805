import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { getTeamById, editTeam, deleteTeam } from "store/teamSlice";

const enhancer = compose(
  withHooks((props) => {
    const { id = "" } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const { team = {}, isLoading, options } = useSelector(
      (state) => state.team
    );

    useEffect(() => {
      dispatch(getTeamById(id));
    }, [dispatch, id]);

    const onDelete = () => {
      dispatch(
        deleteTeam(id, () => {
          history.push("/team");
        })
      );
    };

    const onSubmit = (values) => {
      dispatch(
        editTeam(id, values, () => {
          history.push(`/team/${id}`);
        })
      );
    };

    return { isLoading, data: team, onSubmit, onDelete, options };
  }),
  mapProps(({ isLoading, data, onSubmit, onDelete, options }) => ({
    isLoading,
    data,
    onSubmit,
    onDelete,
    options,
  }))
);

export default enhancer;
