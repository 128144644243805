import { useState, useMemo, useCallback } from "react";

import Table from "components/Table";
import { Selector } from "components/Form/Element/Selector/Selector";
import Breadcrumb from "components/Breadcrumb";
import Modal from "components/Modal";

function Components({ data }) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = useCallback(() => setShowModal(true), [setShowModal]);
  const handleCloseModal = useCallback(() => setShowModal(false), [
    setShowModal,
  ]);

  function MyCell({ value }) {
    return (
      <Selector
        width="100%"
        options={[
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
        ]}
        onChange={(e) => console.log(e)}
      />
    );
  }

  const MOCKUP_TABLE_DATA = Array.from(Array(10)).map((_, i) => ({
    _id: i + 1,
    teamName: `ทีมแนะนำ BKK${i + 1}`,
    branchCode: "TQM",
    areaCode: "BKK",
    tqml: "BKK",
    chef: "หัวหน้าที่ผู้รับผิดชอบ",
    member: "112",
  }));
  const MOCKUP_TABLE_COLUMNS = useMemo(() => {
    if (data.adminCode === "123457") {
      return [
        {
          Header: "ชื่อทีม",
          accessor: "teamName", // accessor is the "key" in the data
        },
        {
          Header: "Branch Code",
          accessor: "branchCode",
        },
        {
          Header: "Area Code",
          accessor: "areaCode",
        },
        {
          Header: "ทีมหลัก TQML",
          accessor: "tqml",
          Cell: MyCell,
        },
        {
          Header: "หัวหน้าที่ผู้รับผิดชอบ",
          accessor: "chef",
        },
        {
          Header: "สมาชิก",
          accessor: "member",
        },
      ];
    } else {
      return [
        {
          Header: "ชื่อทีม",
          accessor: "teamName", // accessor is the "key" in the data
        },
        {
          Header: "Branch Code",
          accessor: "branchCode",
        },
        {
          Header: "Area Code",
          accessor: "areaCode",
        },
      ];
    }
  }, [data]);

  return (
    <>
      <h1>COMPONENTS 📦</h1>
      <Breadcrumb
        links={[
          { link: "/example", label: "ตัวอย่าง" },
          { label: "ตัวอย่าง", current: true },
        ]}
      />
      <a href="/example">tag a</a>
      <br />
      <button type="button" onClick={() => alert("onButtonClick default")}>
        button
      </button>
      <button
        type="button"
        onClick={() => alert(`onButtonClick classname="secondary"`)}
        className="secondary"
      >
        button
      </button>
      <button
        type="button"
        onClick={() => alert("onButtonClick disabled")}
        disabled
      >
        button
      </button>
      <button type="button" onClick={handleOpenModal}>
        open modal
      </button>
      <Modal
        isOpen={showModal}
        onSubmit={() => {
          alert(
            "I used to be the Adventure like you. Then I took an arrow to the knee!"
          );
          handleCloseModal();
        }}
        onCancel={handleCloseModal}
        contentLabel="Example Modal"
      >
        <p>
          ต้องการส่งอีเมลแจ้ง <b>แก้ไขเอกสาร</b>
          <br />
          ไปยังผู้สมัครด้วยหรือไม่ ?
        </p>
      </Modal>
      <Table data={MOCKUP_TABLE_DATA} columns={MOCKUP_TABLE_COLUMNS} />
    </>
  );
}

export default Components;
