import { useMemo } from "react";
import Table from "components/Table";
import styles from "./ReportTable.module.css";
import Icon from "components/Icon";

function ReportTable({
  data = [],
  onChange = () => {},
  paging = false,
  setIsSelect = () => {},
}) {
  const columns = useMemo(
    () => [
      {
        Header: "รหัสผู้แนะนำ",
        accessor: "agentCode",
        styles: {
          header: {
            width: "170px",
          },
        },
      },
      {
        Header: "ชื่อผู้แนะนำ",
        accessor: "agentName",
        styles: {
          header: {
            width: "220px",
          },
        },
      },
      {
        Header: "ชื่อทีมผู้แนะนำ",
        accessor: "affiliateTeam",
        styles: {
          header: {
            width: "150px",
          },
        },
      },
      {
        Header: "",
        accessor: "value",
        styles: {
          header: {
            width: "100px",
          },
        },
        Cell: (cell) => {
          return (
            <div
              className={styles.bin}
              onClick={() => {
                let id = cell.row.original.value;
                setIsSelect(data.filter((d) => d.value !== id));
              }}
            >
              <Icon name="bin" />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <section className={styles.container}>
      <Table
        columns={columns}
        data={data}
        paging={paging}
        onChange={onChange}
        pagination={false}
      />
    </section>
  );
}

export default ReportTable;
