// import { Link } from "react-router-dom";
import cx from "classnames";
import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import CommissionTable from "components/Table/CommissionTable";

import enhancer from "./Commission.compose";
import styles from "./Commission.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import FilterBar from "./FilterBar";
import CommissionModal from "./CommissionModal";
import useToggle from "hooks/useToggle";
import { obj2Url } from "utils/helper";
import { dateFormat } from "utils/formatter";
import authorize, { UnauthorizedAction } from "utils/authorize";

const ReportBtn = ({ options, goToReport, getReport, disabled }) => {
  const [showModal, , handleOpenModal, handleCloseModal] = useToggle();
  return (
    <>
      <button
        type="button"
        className={cx("primary", styles.btn)}
        onClick={handleOpenModal}
        disabled={disabled}
      >
        ออกรายงาน
      </button>
      <CommissionModal
        isOpen={showModal}
        onSubmit={(e) => {
          if (e.type === "order") {
            goToReport(
              obj2Url({ exportDate: dateFormat(e.exportDate), type: e.type })
            );
          } else {
            getReport(e);
          }
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
        submitText={"เลือก"}
        contentLabel="Change Report Modal"
        options={options}
      />
    </>
  );
};

function Commission({
  data,
  options,
  isShowFilterBar,
  toggle,
  onChange,
  searchValue,
  paging,
  goToReport,
  getReport,
  disabled,
}) {
  return (
    <Container className={styles.container}>
      <section className={styles.header}>
        <h4 className="topic">รายการค่าตอบแทน</h4>
        <div
          className={cx(
            containerStyles.breadcrumbWithButton,
            styles.breadcrumb
          )}
        >
          <Breadcrumb links={[{ label: "รายการค่าตอบแทน", current: true }]} />
          <div className={styles.groupBtn}>
            <button
              type="button"
              className={`${styles.selectBtn} secondary`}
              onClick={toggle}
              disabled={disabled}
            >
              ปรับตัวเลือก
            </button>
            <ReportBtn
              options={options?.affiliateOptions || []}
              goToReport={goToReport}
              getReport={getReport}
              disabled={disabled}
            />
          </div>
        </div>
      </section>
      <FilterBar
        isOpen={isShowFilterBar}
        options={options}
        onChange={onChange}
        searchValue={searchValue}
      />
      <CommissionTable
        data={data}
        options={options}
        onChange={onChange}
        paging={paging}
      />
    </Container>
  );
}

export default authorize(enhancer(Commission), {
  roles: "salesCommission",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["read"],
});
