import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { getMarketingById } from "store/marketingSlice";

const enhancer = compose(
  withHooks((props) => {
    const { id = "" } = useParams();
    const dispatch = useDispatch();
    const { marketPlan = [], isLoading = false } = useSelector(
      (state) => state.marketing
    );
    useEffect(() => {
      dispatch(getMarketingById(id));
    }, [dispatch, id]);

    return { data: marketPlan, isLoading };
  }),
  mapProps(({ data, isLoading, disabled = false }) => ({
    data,
    isLoading,
    disabled,
  }))
);

export default enhancer;
