import { Link } from "react-router-dom";
import cx from "classnames";
import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import ConsentTable from "components/Table/ConsentTable";

import enhancer from "./Consents.compose";
import styles from "./Consents.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import FilterBar from "./FilterBar";
import authorize, { UnauthorizedAction } from "utils/authorize";

function Consents({
  data,
  options,
  isShowFilterBar,
  toggle,
  onChange,
  searchValue,
  paging,
  disabled,
}) {
  return (
    <Container className={styles.container}>
      <section className={styles.header}>
        <h4 className="topic">จัดการข้อตกลงและการยินยอม</h4>
        <div
          className={cx(
            containerStyles.breadcrumbWithButton,
            styles.breadcrumb
          )}
        >
          <Breadcrumb
            links={[{ label: "จัดการข้อตกลงและการยินยอม", current: true }]}
          />
          <div className={styles.groupBtn}>
            <button
              type="button"
              className={`${styles.selectBtn} secondary`}
              onClick={toggle}
            >
              ปรับตัวเลือก
            </button>
            <Link to="/consent/new">
              <button className={styles.btn} disabled={disabled}>
                + สร้าง
              </button>
            </Link>
          </div>
        </div>
      </section>
      <FilterBar
        isOpen={isShowFilterBar}
        options={options}
        onChange={onChange}
        searchValue={searchValue}
      />
      <ConsentTable
        data={data}
        options={options}
        paging={paging}
        onChange={onChange}
      />
    </Container>
  );
}

export default authorize(enhancer(Consents), {
  roles: "agreement",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["modify", "add"],
});
