import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { logout } from "store/authSlice";

const enhancer = compose(
  withHooks((props) => {
    const dispatch = useDispatch();
    const { currentAdmin } = useSelector((state) => state.auth);

    const onLogout = useCallback(() => {
      if (window && window.confirm("ต้องการออกจากระบบหรือไม่ ?")) {
        dispatch(logout());
      }
    }, [dispatch]);

    useEffect(() => {
      if (currentAdmin) console.log(currentAdmin);
    }, [currentAdmin]);
    return {
      data: currentAdmin,
      onLogout,
    };
  }),
  mapProps(({ data, onLogout }) => ({ data, onLogout }))
);

export default enhancer;
