const initialValues = {
  opportunityKey: "",
  orderNo: "",
  agentName: "",
  teamName: "",
  receivedDate: "",
  policyYear: "",
  installmentNo: "",
};

export default initialValues;
