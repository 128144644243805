import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { getHistoriesById, getAffiliateById } from "store/affiliateSlice";

const enhancer = compose(
  withHooks((props) => {
    let { id = "" } = useParams();
    const dispatch = useDispatch();
    const { affiliate, histories } = useSelector((state) => state.affiliate);

    useEffect(() => {
      dispatch(getAffiliateById(id));
      dispatch(getHistoriesById(id));
    }, [dispatch, id]);

    return { id, affiliate, data: histories };
  }),
  mapProps(({ id, affiliate, data }) => ({ id, affiliate, data }))
);

export default enhancer;
