import { Route, Switch } from "react-router-dom";
import Agent from "./root";
import AgentId from "./_id";
import EditAgent from "./edit";
import AgentHistory from "./AgentHistory";

const Pages = (props) => (
  <Switch>
    <Route path="/agents/:id/edit" component={EditAgent} />
    <Route path="/agents/:id/history" component={AgentHistory} />
    <Route path="/agents/:id" component={AgentId} />
    <Route path="/agents" component={Agent} />
  </Switch>
);

export default Pages;
