import * as Yup from "yup";

const AgentSchema = Yup.object().shape({
  firstName: Yup.string().required("กรุณากรอกข้อมูล"),
  lastName: Yup.string().required("กรุณากรอกข้อมูล"),
  prefixName: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  agentDocuments: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("กรุณาระบุชื่อเอกสาร"),
    })
  ),
  agentImportantDocuments: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("กรุณาระบุชื่อเอกสาร"),
    })
  ),
  // recruits: Yup.object().shape({
  //   affiliate: Yup.object()
  //     .nullable()
  //     .shape({
  //       teamId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       mkPlanId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       positionLevel: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       uplineId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //     }),
  //   lifeBroker: Yup.object()
  //     .nullable()
  //     .shape({
  //       staffCode: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       teamId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       mkPlanId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       positionLevel: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       uplineId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //     }),
  //   nonLifeBroker: Yup.object()
  //     .nullable()
  //     .shape({
  //       staffCode: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       teamId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       mkPlanId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       positionLevel: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //       uplineId: Yup.string().required("กรุณากรอกข้อมูล").nullable(),
  //     }),
  // }),
});

export default AgentSchema;
