import { createSlice } from "@reduxjs/toolkit";
import api from "api";

const teamInitialState = {
  isLoading: false,
  initialized: false,
  teams: [],
  team: {},
  options: {
    affiliateOptions: [],
    branchOptions: [],
    salesGroupOptions: [],
    tqmTeamOptions: [],
    affiliateSearchOptions: [],
  },
  paging: {},
};

const teamReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setTeams(state, action) {
    state.teams = action.payload.teams;
    state.paging = action.payload.paging;
  },
  setTeam(state, action) {
    state.team = action.payload.team;
  },
  setOptions(state, action) {
    state.options = action.payload;
  },
};

export const teamSlice = createSlice({
  name: "team",
  initialState: teamInitialState,
  reducers: teamReducers,
});

export const { setLoading, setTeams, setTeam, setOptions } = teamSlice.actions;

export default teamSlice.reducer;

export const getTeams = (params = {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/teams", params);
    dispatch(setTeams(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getTeamById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/teams/${id}`);

    dispatch(setTeam(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getTeamOptions = (values) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/teams/options", values);
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  }
};

export const createTeam = (values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.post("/teams", values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const editTeam = (id, values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/teams/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteTeam = (id, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.delete(`/teams/${id}`);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
