import { useMemo } from "react";
import Table from "components/Table";

import { currency } from "utils/formatter";
import styles from "./UplineComissions.module.css";

function CommissionTable({ data = [], options = [], onChange, paging }) {
  const columns = useMemo(
    () => [
      {
        Header: "รหัสผู้แนะนำ",
        accessor: "agentCode",
        styles: {
          header: {
            width: "160px",
          },
        },
      },
      {
        Header: "ชื่อผู้แนะนำ",
        accessor: "agentName",
        styles: {
          header: {
            width: "280px",
          },
        },
      },
      {
        Header: "ตำแหน่ง",
        accessor: "positionName",
        styles: {
          header: {
            width: "150px",
          },
        },
      },
      {
        Header: "สัดส่วน(%)",
        accessor: "commissionPercent",
        styles: {
          header: {
            width: "150px",
          },
        },
      },
      {
        Header: "ค่าแนะนำที่จ่าย",
        accessor: "commissionAmount",
        styles: {
          header: {
            width: "160px",
          },
          cell: {
            textAlign: "right",
          },
        },
        Cell: (cell) => {
          let commissionAmount = parseFloat(cell.row.original.commissionAmount);
          if (commissionAmount > 0) {
            return <b>{currency(commissionAmount, 2, "")}</b>;
          } else {
            return currency(commissionAmount, 2, "");
          }
        },
      },
      {
        Header: "ค่าแนะนำเรียกคืน",
        accessor: "reclaimAmount",
        styles: {
          header: {
            width: "160px",
          },
          cell: {
            textAlign: "right",
          },
        },
        Cell: (cell) => {
          let reclaimAmount = parseFloat(cell.row.original.reclaimAmount);
          if (reclaimAmount > 0) {
            return <b>{currency(reclaimAmount, 2, "")}</b>;
          } else {
            return currency(reclaimAmount, 2, "");
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <section className={styles.container}>
      <Table
        columns={columns}
        data={data}
        paging={paging}
        onChange={onChange}
        pagination={false}
      />
    </section>
  );
}

export default CommissionTable;
