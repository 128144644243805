import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import Icon from "components/Icon";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import AffiliateForm from "components/Form/AffiliateForm";
import useToggle from "hooks/useToggle";

import enhancer from "./EditAffiliate.compose";
import styles from "./EditAffiliate.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

function EditAffiliate({
  id = "",
  data = {},
  onSubmit = () => {},
  sendEmail = () => {},
}) {
  const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

  return (
    <Container>
      <h4 className="topic">ผู้แนะนำ</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "จัดการผู้แนะนำ", link: "/affiliate" },
            {
              label: `${data.firstName} ${data.lastName}`,
              link: `/affiliate/${id}`,
            },
            { label: `แก้ไข`, current: true },
          ]}
        />
        <div>
          <Link to={`/affiliate/${id}/history`} className={styles.historyLink}>
            <button className={cx("secondary", styles.historyBtn)}>
              <div>
                <Icon name="history" className={styles.icon} />
                ประวัติการยอมรับคำยินยอม
              </div>
            </button>
          </Link>
          <button
            className={cx(styles.editBtn)}
            onClick={handleOpenModal}
            type="button"
          >
            บันทึก
          </button>
        </div>
      </div>
      <AffiliateForm
        id="editAffiliate"
        initialValues={data}
        onSubmit={onSubmit}
        sendEmail={sendEmail}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
      />
    </Container>
  );
}

export default authorize(enhancer(EditAffiliate), {
  roles: "agent",
  params: { unauthorizedAction: UnauthorizedAction.REDIRECT },
  actions: ["edit_line_affiliate"],
});
