import moment from "moment";
import querystring from "querystring";
import addressData from "assets/data/address.min.json";
import format from "date-fns/format";
import setYear from "date-fns/setYear";
import th from "date-fns/locale/th";

export const filterObjByArray = (object = {}, filter = []) =>
  Object.keys(object)
    .filter((key) => filter.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const removefilterObj = (object = {}, filter = []) =>
  Object.keys(object)
    .filter((key) => !filter.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const getAge = (birthdate) =>
  moment().diff(
    typeof birthdate === "string" && birthdate
      ? moment(birthdate, "DD/MM/BBBB")
      : birthdate,
    "years"
  );

export const go2Top = () => {
  window && window.scrollTo({ top: 0, behavior: "smooth" });
};

export const url2Obj = (params) => querystring.parse(params);
export const obj2Url = (params) => querystring.stringify(params);

export const generateAddressStructure = (addressData) => {
  var obj = [];
  addressData.map((province) =>
    province.district.map((district) =>
      district.sub_district.map((subDistrict) =>
        subDistrict.postal.map((postal) => {
          var newObj = {
            value: {
              province: province.province_name_th,
              district: district.district_name_th,
              subDistrict: subDistrict.sub_district_name_th,
              postcode: `${postal.postal_value}`,
            },
            label: `${province.province_name_th} >> ${district.district_name_th} >> ${subDistrict.sub_district_name_th} >> ${postal.postal_value}`,
          };
          obj.push(newObj);
          return null;
        })
      )
    )
  );
  return obj;
};

export const addressStructure = generateAddressStructure(addressData);

export const toThaiDateString = (date, pattern = "dd/MM/yyyy") => {
  if (!date) return null;
  const modifiedDate = new Date(date);
  const modifiedYear = setYear(modifiedDate, modifiedDate.getFullYear() + 543);
  return format(modifiedYear, pattern, { locale: th });
};

export const toThaiTimeString = (date, pattern = "p") => {
  if (!date) return null;
  const modifiedDate = new Date(date);
  const modifiedYear = setYear(modifiedDate, modifiedDate.getFullYear() + 543);
  return format(modifiedYear, pattern, { locale: th });
};

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
