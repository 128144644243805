import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { deleteTeam, getTeamById } from "store/teamSlice";
import { useHistory } from "react-router-dom";

const enhancer = compose(
  withHooks((props) => {
    const {
      match: {
        params: { id = "" },
      },
    } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { team = {}, isLoading } = useSelector((state) => state.team);

    useEffect(() => {
      dispatch(getTeamById(id));
    }, [dispatch, id]);

    const onDelete = () => {
      dispatch(
        deleteTeam(id, () => {
          history.push("/team");
        })
      );
    };

    return { data: team, isLoading, onDelete };
  }),
  mapProps(({ data, isLoading, disabled = false, onDelete }) => ({
    data,
    isLoading,
    disabled,
    onDelete,
  }))
);

export default enhancer;
