import * as Yup from "yup";

const TeamSchema = Yup.object().shape({
  name: Yup.string().required("กรุณากรอกข้อมูล"),
  branchCode: Yup.string().required("กรุณาเลือก"),
  affiliateLeaderId: Yup.string().required("กรุณาเลือก"),
  affiliateMembers: Yup.array().of(
    Yup.object().shape({
      joinTeamAt: Yup.date().required("กรุณาระบุวันเข้าทีม").nullable(),
    })
  ),
});

export default TeamSchema;
