import { useEffect, useState } from "react";
import withHooks from "lib/withHooks";
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncementsOptions } from "store/announcementsSlice";

const enhancer = compose(
  withHooks(() => {
    const dispatch = useDispatch();
    const options = useSelector((state) => state.announcements.options);
    const announcement = useSelector(
      (state) => state.announcements.announcement
    );

    const [isRef, setIsRef] = useState(false);

    useEffect(() => {
      dispatch(getAnnouncementsOptions());
    }, [dispatch]);

    useEffect(() => {
      if (!!announcement?.reference) {
        setIsRef(true);
      }
    }, [announcement]);

    return {
      options,
      isRef,
      setIsRef,
    };
  })
);

export default enhancer;
