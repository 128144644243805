import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "components/Form/Element/Input";
import Textarea from "components/Form/Element/Textarea";
import Selector from "components/Form/Element/Selector";
import DatePicker from "components/Form/Element/DatePicker";

import { AFFILIATE_STATUS } from "utils/constants";
import styles from "./Example.module.css";

const FormSchema = Yup.object().shape({
  agentCode: Yup.string().required("กรุณากรอกข้อมูล"),
  password: Yup.string().required("กรุณากรอกข้อมูล"),
  affiliateStatus: Yup.string().required("กรุณาเลือก"),
  birthdate: Yup.date().required("กรุณากรอกข้อมูล").nullable(),
  appointment: Yup.date().required("กรุณากรอกข้อมูล").nullable(),
});

const INITIAL_VALUES = {
  agentCode: "610805",
  password: "P@ssw0rd",
  timeout: "",
  affiliateStatus: "รออัปโหลดเอกสาร",
  // birthdate: new Date(1995, 2, 9),
  birthdate: null,
  appointment: null,
};

const DEFAULT_VALUES = {
  agentCode: "",
  password: "",
  timeout: "",
  affiliateStatus: "",
  birthdate: null,
  appointment: null,
};

function Form() {
  return (
    <React.Fragment>
      <h1>FORM EXAMPLE 📋</h1>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
            resetForm({
              values: DEFAULT_VALUES,
            });
          }, 400);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <pre>{JSON.stringify(values, null, 2)}</pre>
              <Input
                label="บัญชีผู้ใช้งาน (อีเมล/รหัสตัวแทน)"
                name="agentCode"
                placeholder="โปรดระบุ"
                onChange={(value) => console.log(value)}
                required
              />
              <Input
                label="รหัสผ่าน"
                name="password"
                placeholder="โปรดระบุ"
                type="password"
                onChange={(value) => console.log(value)}
                required
              />
              <Input
                label="ค้นหา (with useTimeout)"
                name="timeout"
                placeholder="โปรดระบุ"
                onChange={(value) => console.log(value)}
                useTimeout={1500}
              />
              <Selector
                label="สถานะการสมัคร"
                name="affiliateStatus"
                options={AFFILIATE_STATUS}
                onChange={(value) => console.log(value)}
                required
              />
              <DatePicker
                label="วันเดือนปีเกิด"
                name="birthdate"
                showSelector
                onChange={(value) => console.log(value)}
                required
                maxDate={new Date()}
              />
              <DatePicker
                label="นัดหมาย"
                name="appointment"
                onChange={(value) => console.log(value)}
                required
              />
              <Textarea
                label={`ที่อยู่`}
                name="address"
                placeholder="address"
                type="textarea"
                rows="5"
                onChange={(value) => console.log(value)}
              />
              <button
                className={styles.btn}
                type="submit"
                disabled={isSubmitting}
              >
                SUBMIT
              </button>
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}

export default Form;
