const initialValues = {
  opportunityKey: "",
  orderNo: "",
  affiliateName: "",
  salesmanName: "",
  teamAffiliateName: "",
  teamSalesmanName: "",
  paidAtDate: "",
  installment: "",
};

export default initialValues;
