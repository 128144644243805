import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import { filterObjByArray } from "utils/helper";

import useToggle from "hooks/useToggle";
import {
  getAgents,
  getAgentOptions,
  changeInterviewerStatus,
  changeResponsibleStatus,
} from "store/agentSlice";

const enhancer = compose(
  withHooks((props) => {
    const [isShowFilterBar, toggle] = useToggle();
    const [searchValue, setSearchValue] = useState({
      firstName: "",
      lastName: "",
      agentStatus: "",
      responsiblePersonId: null,
      interviewerId: null,
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10,
      agentType: "",
    });
    const dispatch = useDispatch();
    const { agents = [], paging = {}, isLoading, options = {} } = useSelector(
      (state) => state.agent
    );

    useEffect(() => {
      dispatch(getAgents(searchValue));
    }, [dispatch, searchValue]);

    useEffect(() => {
      dispatch(getAgentOptions());
    }, [dispatch]);

    const onChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    const displayStatusAgent = useCallback(
      (value) => {
        let status = options.agentStatusOptions.find(
          (agentStatus) => agentStatus.value === value
        );
        return status ? status.label : "ไม่ทราบสถานะ";
      },
      [options]
    );

    const changeInterviewer = useCallback(
      (id, interviewerId) => {
        dispatch(
          changeInterviewerStatus(id, interviewerId, () =>
            dispatch(getAgents(searchValue))
          )
        );
      },
      [dispatch, searchValue]
    );

    const changeResponsible = useCallback(
      (id, responsibleId) => {
        dispatch(
          changeResponsibleStatus(id, responsibleId, () =>
            dispatch(getAgents(searchValue))
          )
        );
      },
      [dispatch, searchValue]
    );

    return {
      data: agents,
      paging,
      options,
      isLoading,
      isShowFilterBar,
      toggle,
      onChange,
      displayStatusAgent,
      changeInterviewer,
      changeResponsible,
      searchValue,
    };
  }),
  mapProps((props) =>
    filterObjByArray(props, [
      "data",
      "paging",
      "options",
      "isLoading",
      "isShowFilterBar",
      "toggle",
      "onChange",
      "displayStatusAgent",
      "changeInterviewer",
      "changeResponsible",
      "searchValue",
      "disabled",
    ])
  )
);

export default enhancer;
