import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import { useHistory } from "react-router-dom";
import withHooks from "lib/withHooks";
import { useState } from "react";

import { createAnnouncements } from "store/announcementsSlice";

const enhancer = compose(
  withHooks(() => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.announcements);
    const [isPreview, setIsPreview] = useState(false);

    const onSubmit = (values) => {
      dispatch(
        createAnnouncements(values, () => {
          history.push("/announcements");
        })
      );
    };
    return {
      onSubmit,
      isLoading,
      isPreview,
      setIsPreview,
    };
  }),
  mapProps(({ onSubmit, isLoading, isPreview, setIsPreview }) => ({
    onSubmit,
    isLoading,
    isPreview,
    setIsPreview,
  }))
);

export default enhancer;
