const DEFAULT_VALUES = {
  recruits: {
    affiliate: null,
    lifeBroker: null,
    nonLifeBroker: null,
  },
  nextRecruitmentedAt: new Date(),
};

export { DEFAULT_VALUES };
