import React from "react";
import useQuery from "hooks/useQuery";
import useWindowSize from "hooks/useWindowSize";

import styles from "./Hooks.module.css";

function Hooks() {
  const query = useQuery();
  const token = query.get("token");
  const { width, height } = useWindowSize();
  return (
    <>
      <h1>HOOKS 🪝</h1>
      <div>
        <h3>useQuery</h3>
        <pre className={styles.code}>
          {`import useQuery from "hooks/useQuery"
        
function getToken() {
    const query = useQuery();
    const token = query.get("token");
    return token;
}`}
        </pre>
        token = {token};
        <br />
        <small>
          * <a href="/example?token=agt2021">click</a> or try to change token
          key by yourself in address bar and see what's happended.
        </small>
      </div>
      <div>
        <h3>useWindowSize</h3>
        <pre className={styles.code}>
          {`import useWindowSize from "hooks/useWindowSize"
        
function getScreen() {
    const { width ,height } = useWindowSize();
    return { width ,height };
}`}
        </pre>
        {`width: ${width}, height: ${height}`}
      </div>
    </>
  );
}

export default Hooks;
