import { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import cx from "classnames";

import Input from "components/Form/Element/Input";

import { forgetEmail } from "store/authSlice";
import { emailCensorer } from "utils/formatter";
import styles from "./ForgetPassword.module.css";

const ForgetSchema = Yup.object().shape({
  email: Yup.string()
    .required("กรุณากรอกข้อมูล")
    .email("รูปแบบอีเมบไม่ถูกต้อง"),
});

function ForgetPassword() {
  const [state, setState] = useState("forget");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const onSubmit = (values, { setSubmitting }) => {
    dispatch(
      forgetEmail(
        values,
        () => {
          setState("success");
          setEmail(values.email);
        },
        () => setSubmitting(false)
      )
    );
  };

  return (
    <section className={styles.container}>
      {state === "forget" ? (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ForgetSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <h4>ลืมรหัสผ่าน</h4>
              <Input
                className={styles.email}
                label="กรอกอีเมลของคุณ"
                name="email"
                placeholder="โปรดระบุ"
              />
              <button
                className={styles.btn}
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                ส่ง
              </button>
              <Link to="/login">
                <button
                  className={cx("secondary", styles.btn)}
                  type="button"
                  style={{ marginBottom: 0 }}
                >
                  กลับหน้าหลัก
                </button>
              </Link>
            </form>
          )}
        </Formik>
      ) : (
        <div className={styles.form}>
          <h4>ลืมรหัสผ่าน</h4>
          <div className={styles.success}>
            <div>กรุณาตรวจสอบอีเมลและดำเนินการตามคำแนะนำ</div>
            <label>{emailCensorer(email)}</label>
          </div>
          <Link to="/login">
            <button
              className={cx("secondary", styles.btn)}
              type="button"
              style={{ marginBottom: 0 }}
            >
              กลับหน้าหลัก
            </button>
          </Link>
        </div>
      )}
    </section>
  );
}

export default ForgetPassword;
