import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { loginUser } from "store/authSlice";

const enhancer = compose(
  withHooks((props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const { errorMessage } = useSelector((state) => state.auth);

    const onSubmit = useCallback(
      async (values, { setSubmitting }) => {
        dispatch(
          loginUser(values, () => {
            setSubmitting(false);
            history.push("/");
            window.location.reload();
          })
        );
      },
      [dispatch, history]
    );

    return {
      onSubmit,
      errorMessage,
    };
  }),
  mapProps(({ onSubmit, errorMessage }) => ({
    onSubmit,
    errorMessage,
  }))
);

export default enhancer;
