import { useEffect } from "react";
import withHooks from "lib/withHooks";
import { compose, mapProps } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import { getAdminOptions } from "store/adminSlice";

const enhancer = compose(
  withHooks((props) => {
    const dispatch = useDispatch();
    const options = useSelector((state) => state.admin.options);

    useEffect(() => {
      dispatch(getAdminOptions());
    }, [dispatch]);

    return {
      options,
    };
  }),
  mapProps((props) => ({ ...props }))
);

export default enhancer;
