import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import { filterObjByArray } from "utils/helper";

import useToggle from "hooks/useToggle";
import { getAffiliates, getAffiliateOptions } from "store/affiliateSlice";

const enhancer = compose(
  withHooks((props) => {
    const [isShowFilterBar, toggle] = useToggle();
    const [searchValue, setSearchValue] = useState({
      agentKey: "",
      firstName: "",
      lastName: "",
      status: "",
      activatedSearch: null,
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10,
    });
    const dispatch = useDispatch();
    const {
      affiliates = [],
      options = {},
      isLoading,
      paging = {},
    } = useSelector((state) => state.affiliate);

    useEffect(() => {
      dispatch(getAffiliates(searchValue));
    }, [dispatch, searchValue]);

    useEffect(() => {
      dispatch(getAffiliateOptions());
    }, [dispatch]);

    const onChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    return {
      data: affiliates,
      paging,
      options,
      isLoading,
      isShowFilterBar,
      toggle,
      onChange,
      searchValue,
    };
  }),
  mapProps((props) =>
    filterObjByArray(props, [
      "data",
      "paging",
      "options",
      "isLoading",
      "isShowFilterBar",
      "toggle",
      "onChange",
      "searchValue",
    ])
  )
);

export default enhancer;
