import { Route, Switch } from "react-router-dom";
import Consent from "./root";
import ConsentId from "./_id";
import NewConsent from "./new";
import EditConsent from "./edit";

const Pages = (props) => (
  <Switch>
    <Route exact path="/consent/new" component={NewConsent} />
    <Route exact path="/consent/:id/edit" component={EditConsent} />
    <Route exact path="/consent/:id" component={ConsentId} />
    <Route exact path="/consent" component={Consent} />
  </Switch>
);

export default Pages;
