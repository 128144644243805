import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer id="footer" className={styles.container}>
      <div className={`${styles.wrapper} small`}>
        <section className={styles.section}></section>
        <hr />
        <section className={styles.section}>
          <small className={styles.version}>Ver.1.0.0</small>
          <span className={styles.copyright}>
            Copyright © 2020 TQLD สงวนสิทธิ์ทุกประการ ข้อกำหนดการใช้งาน |
            นโยบายความเป็นส่วนตัว
          </span>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
