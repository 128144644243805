import { Link } from "react-router-dom";
import cx from "classnames";

import Breadcrumb from "components/Breadcrumb";
import Container from "components/Layout/Container";
import FilterBar from "./FilterBar";
import AdminTable from "components/Table/AdminTable";

import enhancer from "./Admin.compose";
import styles from "./Admin.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

function Admin({
  data,
  paging,
  options,
  isShowFilterBar,
  toggle,
  onFilterChange,
  onChange,
  searchValue,
  disabled,
}) {
  return (
    <Container className={styles.container}>
      <section className={styles.header}>
        <h4 className="topic">จัดการผู้ใช้แอดมิน</h4>
        <div
          className={cx(
            containerStyles.breadcrumbWithButton,
            styles.breadcrumb
          )}
        >
          <Breadcrumb
            links={[{ label: "จัดการผู้ใช้แอดมิน", current: true }]}
          />
          <div>
            <button
              className={cx("secondary", styles.filterBtn)}
              onClick={toggle}
            >
              ปรับตัวเลือก
            </button>
            <Link to="/admin/new">
              <button
                type="button"
                className={styles.newBtn}
                disabled={disabled}
              >
                + สร้าง
              </button>
            </Link>
          </div>
        </div>
      </section>
      <FilterBar
        isOpen={isShowFilterBar}
        options={options}
        onFilterChange={onFilterChange}
      />
      <AdminTable
        data={data}
        paging={paging}
        onChange={onChange}
        searchValue={searchValue}
      />
    </Container>
  );
}

export default authorize(enhancer(Admin), {
  roles: "admin",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["add"],
});
