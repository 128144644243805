import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import {
  deleteAnnouncements,
  getAnnouncementsById,
  getAnnouncementsOptions,
} from "store/announcementsSlice";

const enhancer = compose(
  withHooks(() => {
    let { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isPreview, setIsPreview] = useState(false);
    const { announcement, options, isLoading } = useSelector(
      (state) => state.announcements
    );

    useEffect(() => {
      dispatch(getAnnouncementsById(id));
      dispatch(getAnnouncementsOptions());
    }, [dispatch, id]);

    const onDelete = useCallback(() => {
      dispatch(
        deleteAnnouncements(id, () => {
          history.push("/announcements");
        })
      );
    }, [dispatch, id, history]);

    return {
      id,
      data: announcement,
      options: options,
      isLoading,
      isPreview,
      setIsPreview,
      onDelete,
    };
  }),
  mapProps(
    ({
      id,
      data,
      options,
      isLoading,
      isPreview,
      setIsPreview,
      disabled = false,
      onDelete,
    }) => ({
      id,
      data,
      options,
      isLoading,
      isPreview,
      setIsPreview,
      disabled,
      onDelete,
    })
  )
);

export default enhancer;
