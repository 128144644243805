import Container from "components/Layout/Container";

import enhancer from "./Notification.compose";
import styles from "./Notification.module.css";
import Icon from "components/Icon";

function Notification({ data, onLogout }) {
  return (
    <Container className={styles.container}>
      <h4>การแจ้งเตือน</h4>
      <div>
        <p>ใหม่</p>
        <ul>
          <li>
            <div className={styles.detail}>
              <div>
                <Icon name="info-noti" />
                <p>
                  <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล ได้ยื่นสมัคร{" "}
                  <b>นายหน้าประกันชีวิต</b> โปรดตรวจสอบข้อมูลของผู้สมัคร
                </p>
              </div>
              <p>5 นาทีก่อน</p>
            </div>
          </li>
          <li>
            <div className={styles.detail}>
              <div>
                <Icon name="info-noti" />
                <p>
                  <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล
                  มีการอัปโหลดเอกสารเพิ่มเติม โปรดตรวจสอบข้อมูลของผู้สมัคร
                </p>
              </div>
              <p>5 นาทีก่อน</p>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <p>ก่อนหน้านี้</p>
        <ul>
          <li>
            <div className={styles.detail}>
              <div>
                <Icon name="info-noti" />
                <p>
                  <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล ได้ยื่นสมัคร{" "}
                  <b>นายหน้าประกันชีวิต</b> โปรดตรวจสอบข้อมูลของผู้สมัคร
                </p>
              </div>
              <p>5 นาทีก่อน</p>
            </div>
          </li>
          <li>
            <div className={styles.detail}>
              <div>
                <Icon name="info-noti" />
                <p>
                  <b>จัดการผู้สมัคร</b> นาย บุลากร พิพัฒนกุล
                  มีการอัปโหลดเอกสารเพิ่มเติม โปรดตรวจสอบข้อมูลของผู้สมัคร
                </p>
              </div>
              <p>5 นาทีก่อน</p>
            </div>
          </li>
        </ul>
      </div>
    </Container>
  );
}

export default enhancer(Notification);
