import cx from "classnames";
import { Formik } from "formik";

import Input from "components/Form/Element/Input";

import { passwordValidator } from "utils/validator";
import validation from "./SetupPasswordForm.validation";
import styles from "./SetupPasswordForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function SetupPasswordForm(props) {
  const { id = "setup_form", className = "", onSubmit = () => {} } = props;

  return (
    <Formik
      initialValues={{ password: "", passwordConfirmation: "" }}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => {
        const { hasLowercase, hasUppercase, hasDigit } = passwordValidator(
          values.password
        );
        return (
          <form
            id={id}
            onSubmit={handleSubmit}
            className={cx(styles.form, className)}
          >
            <div className={styles.suggestion}>
              <small className={styles.head}>
                รหัสผ่านขั้นต่ำ 8 ตัวอักษร ต้องประกอบด้วย
              </small>
              <div className={styles.row}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color={
                    hasUppercase
                      ? "var(--agt-success-color)"
                      : "var(--agt-background-color-2)"
                  }
                />
                <small>A-Z ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร</small>
              </div>
              <div className={styles.row}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color={
                    hasLowercase
                      ? "var(--agt-success-color)"
                      : "var(--agt-background-color-2)"
                  }
                />
                <small>a-z ตัวพิมพ์เล็กอย่างน้อย 1</small>
              </div>
              <div className={styles.row}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color={
                    hasDigit
                      ? "var(--agt-success-color)"
                      : "var(--agt-background-color-2)"
                  }
                />
                <small>ตัวอักษร 0-9 อย่างน้อย 1 ตัวอักษร</small>
              </div>
            </div>
            <h4>ตั้งค่ารหัสผ่าน</h4>
            <Input
              className={styles.input}
              label="รหัสผ่าน"
              name="password"
              placeholder="โปรดระบุ"
              type="password"
            />
            <Input
              className={styles.input}
              label="ยืนยันรหัสผ่าน"
              name="passwordConfirmation"
              placeholder="โปรดระบุ"
              type="password"
            />
            <button
              className={styles.btn}
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              ยืนยันรหัสผ่าน
            </button>
          </form>
        );
      }}
    </Formik>
  );
}

export default SetupPasswordForm;
