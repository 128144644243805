import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import isFuture from "date-fns/isFuture";

import {
  getAffiliateById,
  editAffiliateById,
  sendEmailAgain,
} from "store/affiliateSlice";

const enhancer = compose(
  withHooks(() => {
    let { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { affiliate = {} } = useSelector((state) => state.affiliate);

    useEffect(() => {
      dispatch(getAffiliateById(id));
    }, [dispatch, id]);

    const onSubmit = (values) => {
      delete values.marketPlan;
      let newAffiliateCommissionPlans = [];
      values.affiliateCommissionPlans.forEach((affiliateCommissionPlan) => {
        if (isFuture(affiliateCommissionPlan.beginDate)) {
          newAffiliateCommissionPlans.push(affiliateCommissionPlan);
        }
      });
      let modifiedValues = {
        ...values,
        affiliateCommissionPlans: newAffiliateCommissionPlans,
      };
      if (values.emailType === "notification") {
        dispatch(
          editAffiliateById(id, modifiedValues, () => {
            sendEmail({ emailType: "notification" });
          })
        );
      } else {
        dispatch(
          editAffiliateById(id, modifiedValues, () => {
            history.push(`/affiliate/${id}`);
          })
        );
      }
    };

    const sendEmail = (values) => {
      dispatch(
        sendEmailAgain(id, values, () => {
          history.push(`/affiliate/${id}`);
        })
      );
    };

    return { id, data: affiliate, onSubmit, sendEmail };
  }),
  mapProps(({ id, data, onSubmit }) => ({
    id,
    data,
    onSubmit,
  }))
);

export default enhancer;
