import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import { useHistory } from "react-router-dom";
import withHooks from "lib/withHooks";

import { createTeam, getTeamOptions } from "store/teamSlice";
import { useEffect } from "react";

const enhancer = compose(
  withHooks((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isLoading, options } = useSelector((state) => state.team);

    useEffect(() => {
      dispatch(getTeamOptions());
    }, [dispatch]);

    const onSubmit = (values) => {
      dispatch(
        createTeam(values, () => {
          history.push("/team");
        })
      );
    };

    return {
      onSubmit,
      isLoading,
      options,
    };
  }),
  mapProps(({ onSubmit, isLoading, options }) => ({
    onSubmit,
    isLoading,
    options,
  }))
);

export default enhancer;
