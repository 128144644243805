import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import cx from "classnames";
import styles from "./Icon.module.css";

const Icon = (props) => {
  const { name, className = "" } = props;
  const svg = useMemo(() => {
    if (name) {
      return require(`assets/icons/${name}.svg`);
    } else {
      return null;
    }
  }, [name]);

  if (svg)
    return (
      <ReactSVG className={cx(styles.container, className)} src={svg.default} />
    );
  return null;
};
export default Icon;
