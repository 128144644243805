import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import { logout } from "store/authSlice";
import { useState } from "react";

const enhancer = compose(
  withHooks(() => {
    // const history = useHistory();
    const dispatch = useDispatch();
    const { currentAdmin } = useSelector((state) => state.auth);
    const [isNotice, setIsNotice] = useState(false);

    const onLogout = useCallback(() => {
      // if (window && window.confirm("ต้องการออกจากระบบหรือไม่ ?")) {
      //   dispatch(logout());
      // }
      dispatch(logout());
    }, [dispatch]);

    return { data: currentAdmin, onLogout, isNotice, setIsNotice };
  }),
  mapProps(({ data, onLogout, isNotice, setIsNotice }) => ({
    data,
    onLogout,
    isNotice,
    setIsNotice,
  }))
);

export default enhancer;
