export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_NUMBER_REGEX = /0[0-9]{2}[0-9]{3}[0-9]{4}/i;

export const isEmail = (value) => EMAIL_REGEX.test(value);

export function isPhoneNumber(phoneNumber) {
  if (!phoneNumber) return;
  return PHONE_NUMBER_REGEX.test(phoneNumber);
}

export function isValidId(id = "") {
  if (id.length !== 13) return false;
  var checksum = id.split("").reduce((mem, char, i) => {
    if (i !== 12) {
      return mem + parseInt(char) * (13 - i);
    } else return mem;
  }, 0);
  checksum = (11 - (checksum % 11)) % 10;
  return checksum === parseInt(id[12]);
}

export function passwordValidator(text) {
  const lower = /[a-z]/;
  const upper = /[A-Z]/;
  const digit = /[0-9]/;
  return {
    hasLowercase: lower.test(text),
    hasUppercase: upper.test(text),
    hasDigit: digit.test(text),
  };
}
