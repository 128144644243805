import * as Yup from "yup";

import { passwordValidator } from "utils/validator";

const SetupPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("กรุณากรอกข้อมูล")
    .min(8, "รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร")
    .test("is-password", "รูปแบบไม่ถูกต้อง", (value) => {
      const { hasLowercase, hasUppercase, hasDigit } = passwordValidator(value);
      return hasLowercase && hasUppercase && hasDigit;
    }),
  passwordConfirmation: Yup.string()
    .required("กรุณากรอกข้อมูล")
    .test("is-password", "รูปแบบไม่ถูกต้อง", (value) => {
      const { hasLowercase, hasUppercase, hasDigit } = passwordValidator(value);
      return hasLowercase && hasUppercase && hasDigit;
    })
    .test("is-confirmpassword", "รหัสผ่านไม่ตรงกัน", (value, rest) => {
      const { password } = rest.parent;
      return value === password;
    }),
});

export default SetupPasswordSchema;
