import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { getCommissionById, getCommissionOptions } from "store/commissionSlice";

const enhancer = compose(
  withHooks(() => {
    let { id = "" } = useParams();
    const dispatch = useDispatch();
    const { commission, options, isLoading } = useSelector(
      (state) => state.commission
    );

    useEffect(() => {
      dispatch(getCommissionById(id));
      dispatch(getCommissionOptions());
    }, [dispatch, id]);

    return {
      id,
      data: commission,
      options: options,
      isLoading,
    };
  }),
  mapProps(({ id, data, options, isLoading }) => ({
    id,
    data,
    options,
    isLoading,
  }))
);

export default enhancer;
