import React from "react";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";

import FilterBar from "./FilterBar";
import AgentsTable from "components/Table/AgentsTable";

import enhancer from "./Agent.compose";
import styles from "./Agent.module.css";

function Agent({
  data = [],
  options = {},
  isShowFilterBar,
  toggle,
  onChange = () => {},
  paging = {},
  displayStatusAgent = () => {},
  changeInterviewer = () => {},
  changeResponsible = () => {},
  searchValue = {},
}) {
  return (
    <Container className={styles.container}>
      <header>
        <h4 className="topic">จัดการผู้สมัคร</h4>
        <div className={styles.filterBtnWrapper}>
          <Breadcrumb links={[{ label: "จัดการผู้สมัคร", current: true }]} />
          <button className="secondary" onClick={toggle}>
            ปรับตัวเลือก
          </button>
        </div>
      </header>
      <section className={styles.filterContainer}>
        <FilterBar
          isOpen={isShowFilterBar}
          options={options}
          onChange={onChange}
        />
      </section>
      <AgentsTable
        data={data}
        paging={paging}
        options={options}
        searchValue={searchValue}
        onChange={onChange}
        changeInterviewer={changeInterviewer}
        changeResponsible={changeResponsible}
        displayStatusAgent={displayStatusAgent}
      />
    </Container>
  );
}

export default enhancer(Agent);
