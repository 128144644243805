import { createSlice } from "@reduxjs/toolkit";
import api from "api";
import session from "utils/session";

import { initializeApp } from "./appSlice";

const authInitialState = {
  isLogin: false,
  isLoading: false,
  currentAdmin: {},
  abilities: {},
  errorMessage: "",
};

const authReducers = {
  initializeLogin(state, action) {
    state.isLogin = action.payload;
  },
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setUser(state, action) {
    session.setAuthToken(action.payload.token);
    state.currentAdmin = action.payload.currentAdmin;
    state.abilities = action.payload.abilities;
  },
  setErrorMessage(state, action) {
    state.errorMessage = action.payload;
  },
};

export const appSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: authReducers,
});

export const {
  initializeLogin,
  setLoading,
  setUser,
  setErrorMessage,
} = appSlice.actions;

export default appSlice.reducer;

export const loginUser = (user, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.post("/auth/sign_in", user);
    session.setAuthToken(response.data.data.token);
    cb();
  } catch (error) {
    console.error(error.response || error);
    dispatch(setErrorMessage(error.response.data.message));
    setTimeout(() => {
      dispatch(setErrorMessage(""));
    }, 3000);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getInfo = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    if (session.isAuthTokenPresent()) {
      const response = await api.get("/auth/current_admin");
      dispatch(setUser(response.data.data));
      dispatch(initializeLogin(true));
    }
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
    dispatch(initializeApp());
  }
};

export const logout = () => async (dispatch) => {
  try {
    session.removeAuthToken();
    session.removeAuthTqmLifeToken();
    dispatch(initializeLogin(false));
  } catch (error) {
    console.error(error.response || error);
  }
};

export const forgetEmail = (
  values,
  cb = () => {},
  setIsSubmit = () => {}
) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.post("/auth/send_reset_password_instructions", values);
    cb();
  } catch (error) {
    console.error(error.response || error);
    setIsSubmit();
  } finally {
    dispatch(setLoading(false));
  }
};

export const setupPassword = (
  values,
  cb = () => {},
  setIsSubmit = () => {}
) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.post("/auth/confirm", values);
    cb();
  } catch (error) {
    console.error(error.response || error);
    setIsSubmit();
  } finally {
    dispatch(setLoading(false));
  }
};
