import React from "react";

function Typography() {
  return (
    <React.Fragment>
      <h1>Typography ✏️</h1>
      <div>
        <h1>H1</h1>
        <h2>H2</h2>
        <h3>H3</h3>
        <h4>H4</h4>
        <div>div</div>
        <p>p</p>
        <span>span</span>
        <br />
        <small>small</small>
      </div>
    </React.Fragment>
  );
}

export default Typography;
