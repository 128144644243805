import React from "react";
import cx from "classnames";
import { Formik } from "formik";

import Input from "components/Form/Element/Input";
import DatePicker from "components/Form/Element/DatePicker";
import styles from "./FilterBar.module.css";
import initialValues from "./FilterBar.utils";
import moment from "moment";

function FilterBar({ isOpen, options = {}, onChange = () => {} }) {
  return (
    <Formik initialValues={initialValues}>
      {(props) => {
        const { resetForm } = props;
        return (
          <form className={cx(styles.filterWrapper, !isOpen ? "hidden" : null)}>
            <div className={styles.filterRows}>
              <div className={styles.filterInputWrapper}>
                <label htmlFor="opportunityKey">รหัสโอกาสในการขาย</label>
                <Input
                  name="opportunityKey"
                  className={styles.filterOpportunityKey}
                  placeholder="โปรดระบุ"
                  useTimeout={1500}
                  onChange={(value) => onChange({ opportunityKey: value })}
                />
              </div>
              <div className={styles.filterInputWrapper}>
                <label htmlFor="orderNo">รหัสคำสั่งซื้อ</label>
                <Input
                  name="orderNo"
                  className={styles.filterOrderNo}
                  placeholder="โปรดระบุ"
                  useTimeout={1500}
                  onChange={(value) => onChange({ orderNo: value })}
                />
              </div>
              <div className={styles.filterInputWrapper}>
                <label htmlFor="agentName">ชื่อผู้แนะนำ</label>
                <Input
                  name="agentName"
                  className={styles.filterAgentName}
                  placeholder="โปรดระบุ"
                  useTimeout={1500}
                  onChange={(value) => onChange({ agentName: value })}
                />
              </div>
            </div>
            <div className={styles.filterRows}>
              <div className={styles.filterInputWrapper}>
                <label htmlFor="teamName">ชื่อทีมผู้แนะนำ</label>
                <Input
                  name="teamName"
                  className={styles.filterTeamName}
                  placeholder="โปรดระบุ"
                  useTimeout={1500}
                  onChange={(value) => onChange({ teamName: value })}
                />
              </div>
              <div className={cx(styles.filterInputWrapper)}>
                <label htmlFor="receivedDate">วันที่ได้รับเงิน</label>
                <DatePicker
                  name="receivedDate"
                  className={styles.filterReceivedDate}
                  onChange={(value) =>
                    onChange({
                      receivedDate: moment(value).format("DD/MM/YYYY"),
                    })
                  }
                />
              </div>
              <div className={styles.filterYearInputWrapper}>
                <div className={styles.filterInputWrapper}>
                  <label htmlFor="policyYear">ปี</label>
                  <Input
                    name="policyYear"
                    className={styles.filterPolicyYear}
                    placeholder="โปรดระบุ"
                    useTimeout={1500}
                    onChange={(value) => onChange({ policyYear: value })}
                  />
                </div>
                <div className={styles.filterInputWrapper}>
                  <label htmlFor="installmentNo">งวด</label>
                  <Input
                    name="installmentNo"
                    className={styles.filterInstallmentNo}
                    placeholder="โปรดระบุ"
                    useTimeout={1500}
                    onChange={(value) => onChange({ installmentNo: value })}
                  />
                </div>
              </div>
            </div>
            <div
              className={cx(styles.filterInput, styles.reset)}
              onClick={() => {
                resetForm();
                onChange(initialValues);
              }}
            >
              รีเซ็ตค่าตัวเลือก
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FilterBar;
