import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import { useHistory } from "react-router-dom";
import withHooks from "lib/withHooks";

import {
  createConsent,
  // sendEmailAgain
} from "store/consentSlice";

const enhancer = compose(
  withHooks((props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.consent);

    const onSubmit = (values) => {
      dispatch(
        createConsent(values, () => {
          history.push("/consent");
        })
      );
    };

    const sendEmail = (values) => {
      console.log(values);
      // dispatch(sendEmailAgain(id, values));
    };

    return {
      onSubmit,
      sendEmail,
      isLoading,
    };
  }),
  mapProps(({ onSubmit, sendEmail, isLoading }) => ({
    onSubmit,
    sendEmail,
    isLoading,
  }))
);

export default enhancer;
